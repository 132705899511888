import {Avatar, Input} from '@/components/common'
import {useLazyGetHomeSerachQuery} from '@/features/home'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useDebounce} from 'use-debounce'
import {Props} from './types'
import {getImageURL} from '@/utils/get-image'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export const Serach = ({isSearch, setIsSearch}: Props) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedValue] = useDebounce(searchValue.trim(), 500)
  const [homeSerach, {data: searchResult, isLoading}] = useLazyGetHomeSerachQuery()
  const handleRequest = useHandleRequest()
  const location = useLocation()
  const {i18n, t} = useTranslation()

  const onSearchHandle = async () => {
    handleRequest({
      request: async () => {
        const result = await homeSerach({
          q: debouncedValue,
        })
        return result
      },
    })
  }

  useEffect(() => {
    if (debouncedValue && searchValue !== '') {
      onSearchHandle()
    }
  }, [debouncedValue])

  useEffect(() => {
    setOpen(false)
  }, [location])

  return (
    <div className="px-0 lg:px-[45px] relative w-full">
      <div className="w-full !z-[100] relative" onBlur={() => setIsSearch(false)}>
        <Input
          type="search"
          isSearching={isLoading}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onFocus={() => setOpen(true)}
          baseClassName="w-full z-30 text-xl font-medium rounded-t-[6px] focus:border-blue focus:bg-blue-7 bg-blue-7 border border-blue px-4 py-[7px] w-full  rounded-t-[6px]"
        />

        {open && searchResult?.success && (
          <div className="absolute z-[100] max-h-[336px] w-full py-3 bg-white top-[41.8px] border border-blue rounded-b-[6px]">
            {!Object.keys(searchResult?.data || {}).length && (
              <div className="flex justify-center items-center h-20 text-blue-2">
                <p>{t('common.no_result')}</p>
              </div>
            )}
            {searchResult.data?.program && (
              <Link
                to={'/programs/' + searchResult?.data?.program?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex overflow-hidden items-center gap-x-4 ">
                  <Avatar
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full"
                    type="program"
                    imageClassName="w-full h-full overflow-hidden"
                    className="w-[36px] overflow-hidden h-[36px] text-blue-4 rounded-full"
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">{searchResult?.data?.program?.program}</h2>
                </div>
              </Link>
            )}
            {searchResult?.data?.company && (
              <Link
                to={'/company/' + searchResult?.data?.company?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex items-center gap-x-4 ">
                  <Avatar
                    type="company"
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full "
                    imageClassName="h-full"
                    className="w-[36px] bg-white text-blue-4 overflow-hidden h-[35px] rounded-full "
                    src={searchResult?.data?.company.logo}
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">{searchResult?.data?.company?.title}</h2>
                </div>
              </Link>
            )}
            {searchResult?.data?.skill && (
              <Link
                to={'/skills/' + searchResult?.data?.skill?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex items-center gap-x-4 ">
                  <Avatar
                    type="skill"
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full"
                    imageClassName="h-full"
                    className="w-[36px] bg-white text-blue-4 overflow-hidden h-[35px] rounded-full "
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">{searchResult?.data?.skill?.title}</h2>
                </div>
              </Link>
            )}
            {searchResult?.data?.university && (
              <Link
                to={'/universities/' + searchResult?.data?.university?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex items-center gap-x-4 ">
                  <Avatar
                    type="university"
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full"
                    imageClassName="h-full"
                    src={getImageURL(searchResult?.data?.university.logo)}
                    className="w-[36px] bg-white text-blue-4 overflow-hidden h-[35px] rounded-full "
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">{searchResult?.data?.university?.title}</h2>
                </div>
              </Link>
            )}
            {searchResult?.data.vacancy && (
              <Link
                to={'/jobs/' + searchResult?.data?.vacancy?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex items-center gap-x-4 ">
                  <Avatar
                    type="vacancy"
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full"
                    className="w-[36px] bg-white text-blue-4 overflow-hidden h-[35px] rounded-full "
                    imageClassName="h-full"
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">
                    {searchResult?.data?.vacancy?.job_title}
                  </h2>
                </div>
              </Link>
            )}

            {searchResult?.data.blog && (
              <Link
                to={'/blogs/' + searchResult?.data?.blog?._id}
                className="block px-[30px] py-2 hover:bg-blue-7 cursor-pointer "
              >
                <div className="flex items-center gap-x-4 ">
                  <Avatar
                    avatarClassName="w-[36px] bg-white overflow-hidden h-[35px] rounded-full"
                    className="w-[36px] bg-white text-blue-4 overflow-hidden h-[35px] rounded-full "
                    imageClassName="h-full"
                    type="blog"
                  />
                  <h2 className="text-blue text-sm font-semibold leading-5">
                    {searchResult?.data?.blog[`title_${i18n.resolvedLanguage}`]}
                  </h2>
                </div>
              </Link>
            )}
          </div>
        )}
      </div>

      {!isSearch ? (
        <div
          onClick={() => setIsSearch(true)}
          className=" bg-transparent fixed top-0 left-0 h-screen w-full z-[99]"
        ></div>
      ) : (
        ''
      )}
    </div>
  )
}
