import {Dialog, DialogContent, DialogHeader} from '@/components/ui/dialog'
import {Props} from './types'
import {CloseIcon} from '@/icons'
import {Button} from '..'
import {cn} from '@/lib/utils'
import {useTranslation} from 'react-i18next'

export const FormModal = ({open, close, children, className, wrapForm, loading}: Props) => {
  const {t} = useTranslation()

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent
        className={cn(
          'bg-white p-0 !rounded-none gap-0 max-2 z-[150]  mr-4 lg:mx-0 max-h-full lg:min-w-[700px]',
          className,
        )}
      >
        <DialogHeader className="flex items-end justify-center bg-blue-5 h-10 px-4">
          <button type="button" onClick={close} className="p-0.5 hover:bg-blue-6 duration-default rounded">
            <CloseIcon className="w-6 h-6" />
          </button>
        </DialogHeader>
        <div className="max-w-full min-w-full">
          {wrapForm ? (
            wrapForm(
              <>
                <div className="overflow-y-auto overflow-x-hidden w-full">
                  <div className={cn('pt-10 mx-auto max-h-[65vh] max-w-[90%]')}>{children}</div>
                </div>
                <div className="px-2 lg:px-16 pt-9 pb-6 flex gap-2 items-center justify-between">
                  <Button
                    onClick={close}
                    theme="orange"
                    className="w-full lg:w-auto  px-8 py-2 rounded text-base font-bold leading-5 min-w-[116px]"
                  >
                    {t('common.buttons.cancel')}
                  </Button>
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="w-full lg:w-auto px-8 py-2 rounded bg-sky-1 border-sky-1 hover:bg-sky-1/90 text-base font-bold leading-5 min-w-[116px]"
                  >
                    {t('common.buttons.save')}
                  </Button>
                </div>
              </>,
            )
          ) : (
            <div className="overflow-y-auto overflow-x-hidden w-full">
              <div className={cn('pt-10 mx-auto max-h-[65vh] max-w-[90%]')}>{children}</div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
