/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import {useEffect, useState} from 'react'
import {Props} from './types'
import {cn} from '@/lib/utils'
import {useSearchParams} from 'react-router-dom'

export const BoldTabs = ({
  tabs,
  defaultTabIndex,
  contentClassName,
  tabsClassName,
  leftSideContent,
  tabClassName,
  tabName,
}: Props) => {
  const [activeTabIdx, setActiveTabIdx] = useState(defaultTabIndex || 0)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (tabName) {
      const newTabIdx = tabs.findIndex(tab => tab.value === searchParams.get(tabName))
      if (newTabIdx !== -1) {
        setActiveTabIdx(newTabIdx)
      } else {
        if (tabs[0]) {
          searchParams.set(tabName, tabs[0].value || '0')
          setSearchParams(searchParams)
        }
      }
    }
  }, [searchParams])

  if (!tabs?.length) {
    return ''
  }

  return (
    <div className="max-w-full">
      <div className={tabsClassName}>
        <div className="w-full overflow-x-auto">
          <div className={cn('flex w-max', tabClassName)}>
            {tabs.map((tab, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setActiveTabIdx(index)
                  if (tabName) {
                    searchParams.set(tabName, tab.value || index.toString())
                    searchParams.set('page', '1')
                    setSearchParams(searchParams)
                  }
                }}
                className={cn(
                  'text-sm font-bold leading-6 py-2 px-7 md:px-14 first:rounded-tl first:rounded-bl first:border-l-2 last:rounded-tr last:rounded-br last:border-r-2 border-x border-y-2 duration-default',
                  activeTabIdx === index ? 'bg-blue border-blue text-white' : 'border-blue-2 text-blue-1',
                )}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
        {leftSideContent}
      </div>
      {tabs[activeTabIdx]?.children ? (
        <div className={cn('mt-7', contentClassName)}>{tabs[activeTabIdx].children}</div>
      ) : (
        ''
      )}
    </div>
  )
}
