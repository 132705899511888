import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ChevronLeft2Icon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      d="M9.02497 10.0006L13.1498 14.1253L11.9713 15.3038L6.66797 10.0006L11.9713 4.69727L13.1498 5.87577L9.02497 10.0006Z"
      fill="currentColor"
    />
  </Icon>
)
