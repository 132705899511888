import {Avatar, Dropdown} from '@/components/common'
import {Role} from '@/constants/roles'
import {useUserData} from '@/hooks/use-user-data'
import {useUserRole} from '@/hooks/use-user-role'
import {ArrowUpIcon, DashboardIcon, ExitIcon} from '@/icons'
import {getImageURL} from '@/utils/get-image'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export const Account = () => {
  const navagate = useNavigate()
  const {t} = useTranslation()
  const user = useUserData()
  const userRole = useUserRole()

  const avatarImage = useMemo(() => {
    switch (userRole) {
      case Role.ADMIN:
        return user?.image
      case Role.UNIVERSITY:
        return user?.university?.logo
      case Role.APPLICANT:
        return user?.applicant?.image
      case Role.SUPER_ADMIN:
        return user?.image
      case Role.COMPANY:
        return user.company?.logo
      case Role.CONSULTING:
        return user.consulting?.logo
    }
  }, [user, userRole])

  const avatarType = useMemo(() => {
    switch (userRole) {
      case Role.ADMIN:
        return 'program'
      case Role.COMPANY:
        return 'company'
      case Role.UNIVERSITY:
        return 'university'
      case Role.APPLICANT:
        return 'program'
      case Role.SUPER_ADMIN:
        return 'program'
      default:
        return 'program'
    }
  }, [userRole])

  return (
    <div className="z-20">
      <Dropdown
        className="py-4 w-max"
        buttons={[
          {
            icon: <DashboardIcon className="mt-px w-4 h-4" />,
            label: <span className="text-blue text-base leading-[26px]">{t('home.navbar.my_dashboard')}</span>,
            onClick: () => navagate('/dashboard'),
          },
          {
            icon: <ExitIcon className="w-[17px] h-[17px]" />,
            label: <span className="text-blue text-base leading-[26px]">{t('home.navbar.logout')}</span>,
            onClick: () => navagate('/auth/logout'),
          },
        ]}
      >
        <button className="flex items-center gap-x-2">
          <Avatar
            className="w-10 h-10 text-blue-4"
            src={getImageURL(avatarImage)}
            avatarClassName="w-10 h-10"
            baseClassName="w-10 h-10"
            type={avatarType}
          />
          <ArrowUpIcon />
        </button>
      </Dropdown>
    </div>
  )
}
