import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const AppliedUsersIcon = ({...props}: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6.25C10 7.63071 8.88071 8.75 7.5 8.75C6.11929 8.75 5 7.63071 5 6.25C5 4.86929 6.11929 3.75 7.5 3.75C8.88071 3.75 10 4.86929 10 6.25ZM7.5 10C9.57107 10 11.25 8.32107 11.25 6.25C11.25 4.17893 9.57107 2.5 7.5 2.5C5.42893 2.5 3.75 4.17893 3.75 6.25C3.75 8.32107 5.42893 10 7.5 10ZM15 16.25C15 17.5 13.75 17.5 13.75 17.5H1.25C1.25 17.5 0 17.5 0 16.25C0 15 1.25 11.25 7.5 11.25C13.75 11.25 15 15 15 16.25ZM13.75 16.2456C13.7482 15.9372 13.5578 15.0131 12.7099 14.1651C11.8945 13.3498 10.3614 12.5 7.49999 12.5C4.6386 12.5 3.10544 13.3498 2.29012 14.1651C1.44219 15.0131 1.25178 15.9372 1.25 16.2456H13.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.875 6.25C17.2202 6.25 17.5 6.52982 17.5 6.875V8.75H19.375C19.7202 8.75 20 9.02982 20 9.375C20 9.72018 19.7202 10 19.375 10H17.5V11.875C17.5 12.2202 17.2202 12.5 16.875 12.5C16.5298 12.5 16.25 12.2202 16.25 11.875V10H14.375C14.0298 10 13.75 9.72018 13.75 9.375C13.75 9.02982 14.0298 8.75 14.375 8.75H16.25V6.875C16.25 6.52982 16.5298 6.25 16.875 6.25Z"
      fill="currentColor"
    />
  </Icon>
)
