export const PER_PAGE = 10

export const PER_PAGE_OPTIONS = [
  {label: PER_PAGE.toString(), value: PER_PAGE},
  {label: '20', value: 20},
  {label: '50', value: 50},
  {
    label: '100',
    value: 100,
  },
]
