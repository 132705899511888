import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const SocialScienceIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M79.9287 30.4951V35.0711C78.9247 35.0711 50.9122 35.0711 49.574 35.0711C47.1177 35.0711 44.998 33.0621 44.998 30.4951C44.998 28.04 47.0059 25.9192 49.574 25.9192H75.3527C77.8089 26.031 79.9287 28.04 79.9287 30.4951Z"
      fill="transparent"
    />
    <path
      d="M78.1436 19C78.6993 19 79.1476 19.4494 79.1476 20.0051C79.1476 20.5596 78.6993 21.009 78.1436 21.009C77.589 21.009 77.1396 20.5596 77.1396 20.0051C77.1396 19.4494 77.589 19 78.1436 19Z"
      fill="currentColor"
    />
    <path
      d="M77.4746 47.6821C78.0292 47.6821 78.4787 48.1315 78.4787 48.6861C78.4787 49.2406 78.0292 49.6901 77.4746 49.6901C76.9201 49.6901 76.4707 49.2406 76.4707 48.6861C76.4696 48.1315 76.9201 47.6821 77.4746 47.6821Z"
      fill="currentColor"
    />
    <path
      d="M76.6924 63.6405C76.6924 65.5378 76.0238 67.435 75.0187 68.8851C73.344 66.5418 70.6653 65.0906 67.6534 65.0906C69.5506 65.0906 71.1125 63.5276 71.1125 61.6315C71.1125 59.7343 69.5506 58.0595 67.6534 58.0595C65.7561 58.0595 64.1932 59.6225 64.1932 61.5186C64.1932 63.4159 65.7561 64.9788 67.6534 64.9788C64.6393 64.9788 61.8499 66.5418 60.288 68.7733C59.2841 67.3221 58.6133 65.5367 58.6133 63.5276C58.6133 58.5056 62.6313 54.4875 67.6534 54.4875C72.6754 54.4898 76.6924 58.6185 76.6924 63.6405Z"
      fill="transparent"
    />
    <path
      d="M75.0198 68.886C73.3451 71.2293 70.6664 72.6805 67.6545 72.6805C64.6404 72.6805 61.8509 71.1175 60.2891 68.886C61.9627 66.5427 64.6415 65.0916 67.6545 65.0916C70.6664 65.0916 73.3451 66.5427 75.0198 68.886Z"
      fill="transparent"
    />
    <path
      d="M75.6882 40.3167C75.6882 43.2179 73.3449 45.5612 70.4437 45.5612H33.504L26.1387 50.4725V45.5612V40.3167C26.1387 37.4144 28.482 35.071 31.3832 35.071C31.495 35.071 69.1043 35.071 70.4437 35.071C73.3449 35.071 75.6882 37.4144 75.6882 40.3167Z"
      fill="transparent"
    />
    <path
      d="M71.2237 76.9211C71.7793 76.9211 72.2288 77.3705 72.2288 77.9251C72.2288 78.4797 71.7793 78.9302 71.2237 78.9302C70.6691 78.9302 70.2197 78.4808 70.2197 77.9251C70.2197 77.3705 70.6691 76.9211 71.2237 76.9211Z"
      fill="currentColor"
    />
    <path
      d="M67.6535 58.0605C69.5508 58.0605 71.1126 59.6235 71.1126 61.5196C71.1126 63.4169 69.5508 64.9798 67.6535 64.9798C65.7563 64.9798 64.1934 63.418 64.1934 61.5196C64.1945 59.6224 65.6445 58.0605 67.6535 58.0605Z"
      fill="transparent"
    />
    <path
      d="M55.4878 74.1306C57.0507 74.1306 58.2783 75.3582 58.2783 76.9211C58.2783 78.4841 57.0518 79.7116 55.4878 79.7116C53.9259 79.7116 52.6973 78.4841 52.6973 76.9211C52.6984 75.3582 53.9259 74.1306 55.4878 74.1306Z"
      fill="transparent"
    />
    <path
      d="M58.9482 59.5117C58.9482 61.4089 58.2785 63.3061 57.2734 64.7562C55.5998 62.4128 52.9211 60.9617 49.9081 60.9617C51.8053 60.9617 53.3683 59.3987 53.3683 57.5015C53.3683 55.6043 51.8053 54.0413 49.9081 54.0413C48.0108 54.0413 46.449 55.6043 46.449 57.5015C46.449 59.3987 48.0108 60.9617 49.9081 60.9617C46.8951 60.9617 44.1057 62.5246 42.5427 64.7562C41.5388 63.305 40.8691 61.5196 40.8691 59.5117C40.8691 54.4896 44.8861 50.4727 49.9081 50.4727C54.9301 50.4715 58.9482 54.4896 58.9482 59.5117Z"
      fill="transparent"
    />
    <path
      d="M57.2746 64.6456C55.601 66.9889 52.9223 68.44 49.9093 68.44C46.8963 68.44 44.1069 66.9889 42.5439 64.6456C44.2187 62.3022 46.8974 60.8511 49.9093 60.8511C52.9234 60.8511 55.6021 62.4129 57.2746 64.6456Z"
      fill="transparent"
    />
    <path
      d="M49.9083 53.9316C51.8055 53.9316 53.3685 55.4935 53.3685 57.3918C53.3685 59.2891 51.8055 60.852 49.9083 60.852C48.0111 60.852 46.4492 59.2891 46.4492 57.3918C46.4492 55.4935 48.0111 53.9316 49.9083 53.9316Z"
      fill="transparent"
    />
    <path
      d="M45.333 74.1306C45.8876 74.1306 46.3381 74.58 46.3381 75.1346C46.3381 75.6891 45.8876 76.1397 45.333 76.1397C44.7785 76.1397 44.3291 75.6902 44.3291 75.1346C44.3291 74.5811 44.7785 74.1306 45.333 74.1306Z"
      fill="currentColor"
    />
    <path
      d="M42.544 20.4512C43.0985 20.4512 43.5479 20.9006 43.5479 21.4551C43.5479 22.0097 43.0985 22.4591 42.544 22.4591C41.9894 22.4591 41.54 22.0097 41.54 21.4551C41.54 20.9017 41.9894 20.4512 42.544 20.4512Z"
      fill="currentColor"
    />
    <path
      d="M41.2041 63.6405C41.2041 65.5378 40.5344 67.435 39.5304 68.8851C37.8557 66.5418 35.177 65.0906 32.1651 65.0906C34.0623 65.0906 35.6242 63.5276 35.6242 61.6315C35.6242 59.7343 34.0623 58.0595 32.1651 58.0595C30.2679 58.0595 28.7049 59.6225 28.7049 61.5186C28.7049 63.4159 30.2679 64.9788 32.1651 64.9788C29.151 64.9788 26.3616 66.5418 24.7997 68.7733C23.7958 67.3221 23.125 65.5367 23.125 63.5276C23.125 58.5056 27.1431 54.4875 32.1651 54.4875C37.1871 54.6016 41.2041 58.6185 41.2041 63.6405Z"
      fill="transparent"
    />
    <path
      d="M39.5305 68.886C37.8557 71.2293 35.177 72.6805 32.1651 72.6805C29.151 72.6805 26.3616 71.1175 24.7998 68.886C26.4734 66.5427 29.1521 65.0916 32.1651 65.0916C35.1781 65.0916 37.9675 66.5427 39.5305 68.886Z"
      fill="transparent"
    />
    <path
      d="M32.1653 58.0605C34.0625 58.0605 35.6244 59.6235 35.6244 61.5196C35.6244 63.4169 34.0625 64.9798 32.1653 64.9798C30.2681 64.9798 28.7051 63.418 28.7051 61.5196C28.7051 59.6224 30.2681 58.0605 32.1653 58.0605Z"
      fill="transparent"
    />
    <path
      d="M28.9285 21.1206C31.2099 21.1206 33.0584 22.9691 33.0584 25.2494C33.0584 27.5307 31.2099 29.3781 28.9285 29.3781C26.6483 29.3781 24.7998 27.5296 24.7998 25.2494C24.7998 22.9691 26.6483 21.1206 28.9285 21.1206Z"
      fill="transparent"
    />
    <path
      d="M21.005 49.0205C21.5596 49.0205 22.009 49.4699 22.009 50.0256C22.009 50.5801 21.5596 51.0295 21.005 51.0295C20.4494 51.0295 20 50.5801 20 50.0256C20 49.471 20.4494 49.0205 21.005 49.0205Z"
      fill="currentColor"
    />
    <path
      d="M39.5304 69.5557C39.3068 69.5557 39.0842 69.4439 38.9724 69.2214C37.4095 66.9888 34.8426 65.6494 32.165 65.6494C29.4863 65.6494 26.9205 66.9888 25.3575 69.2214C25.135 69.5557 24.689 69.5557 24.3536 69.3332C24.0182 69.2214 24.0182 68.7753 24.2418 68.441C26.0272 65.8741 29.0402 64.3123 32.165 64.3123C35.2898 64.3123 38.3028 65.8741 40.0882 68.441C40.3107 68.7753 40.2001 69.2225 39.9765 69.445C39.8658 69.5557 39.7528 69.5557 39.5304 69.5557Z"
      fill="currentColor"
    />
    <path
      d="M32.1654 65.761C29.822 65.761 27.9248 63.8638 27.9248 61.5194C27.9248 59.176 29.822 57.2788 32.1654 57.2788C34.5087 57.2788 36.4059 59.176 36.4059 61.5194C36.4059 63.8638 34.5087 65.761 32.1654 65.761ZM32.1654 58.73C30.6024 58.73 29.3748 59.9575 29.3748 61.5194C29.3748 63.0823 30.6013 64.3099 32.1654 64.3099C33.7272 64.3099 34.9547 63.0823 34.9547 61.5194C34.9547 59.9575 33.7272 58.73 32.1654 58.73Z"
      fill="currentColor"
    />
    <path
      d="M57.2727 65.4255C57.0502 65.4255 56.8266 65.3137 56.7148 65.0912C55.153 62.8586 52.586 61.5192 49.9073 61.5192C47.2286 61.5192 44.6628 62.8586 43.0999 65.0912C42.8774 65.4255 42.4313 65.4255 42.0959 65.203C41.7616 64.9794 41.7616 64.5345 41.9841 64.1991C43.7696 61.6322 46.7825 60.0703 49.9073 60.0703C53.0321 60.0703 56.0462 61.6333 57.8306 64.1991C58.0542 64.5345 57.9435 64.9805 57.7199 65.203C57.6081 65.3148 57.3845 65.4255 57.2727 65.4255Z"
      fill="currentColor"
    />
    <path
      d="M49.9085 61.5192C47.5652 61.5192 45.668 59.622 45.668 57.2787C45.668 54.9353 47.5652 53.0381 49.9085 53.0381C52.2518 53.0381 54.1491 54.9353 54.1491 57.2787C54.1491 59.622 52.2518 61.5192 49.9085 61.5192ZM49.9085 54.601C48.3467 54.601 47.118 55.8286 47.118 57.3904C47.118 58.9523 48.3456 60.181 49.9085 60.181C51.4715 60.181 52.699 58.9534 52.699 57.3904C52.699 55.8286 51.4715 54.601 49.9085 54.601Z"
      fill="currentColor"
    />
    <path
      d="M75.0208 69.5557C74.7972 69.5557 74.5747 69.4439 74.4618 69.2214C72.8999 66.9887 70.3319 65.6494 67.6554 65.6494C64.9767 65.6494 62.4098 66.9887 60.8479 69.2214C60.6243 69.5557 60.1772 69.5557 59.844 69.3332C59.5086 69.1096 59.5086 68.6635 59.7311 68.3292C61.5165 65.7623 64.5306 64.2004 67.6554 64.2004C70.7791 64.2004 73.7932 65.7634 75.5786 68.3292C75.8011 68.6635 75.6904 69.1107 75.4668 69.3332C75.2432 69.5557 75.1315 69.5557 75.0208 69.5557Z"
      fill="currentColor"
    />
    <path
      d="M67.6537 65.761C65.3104 65.761 63.4121 63.8638 63.4121 61.5194C63.4121 59.176 65.3104 57.2788 67.6537 57.2788C69.9971 57.2788 71.8943 59.176 71.8943 61.5194C71.8943 63.8638 69.8864 65.761 67.6537 65.761ZM67.6537 58.73C66.0908 58.73 64.8644 59.9575 64.8644 61.5194C64.8644 63.0823 66.0908 64.3099 67.6537 64.3099C69.2156 64.3099 70.4442 63.0823 70.4442 61.5194C70.4442 59.9575 69.1049 58.73 67.6537 58.73Z"
      fill="currentColor"
    />
    <path
      d="M32.1647 73.4608C29.0399 73.4608 26.0269 71.8989 24.2415 69.332C23.1246 67.6584 22.4561 65.7611 22.4561 63.7521C22.4561 58.3958 26.8084 53.9316 32.2765 53.9316C37.7446 53.9316 42.0969 58.284 42.0969 63.7521C42.0969 65.7611 41.4273 67.7691 40.3115 69.332C38.3025 71.8989 35.2895 73.4608 32.1647 73.4608ZM25.3572 68.4398C26.9202 70.6714 29.486 72.0107 32.1647 72.0107C34.8434 72.0107 37.4092 70.6714 38.9721 68.4398C39.9761 66.9887 40.534 65.3151 40.534 63.6414C40.534 59.0655 36.7396 55.271 32.1647 55.271C27.5888 55.271 23.7943 59.0655 23.7943 63.6414C23.7943 65.4258 24.3522 67.1005 25.3572 68.4398Z"
      fill="currentColor"
    />
    <path
      d="M49.9089 69.2201C46.7841 69.2201 43.7711 67.6571 41.9857 65.0913C40.8699 63.4177 40.2002 61.5193 40.2002 59.5114C40.2002 54.1551 44.5537 49.6909 50.0207 49.6909C55.4888 49.6909 59.8422 54.0433 59.8422 59.5114C59.8422 61.5193 59.1726 63.5284 58.0568 65.0913C56.0478 67.6582 53.0337 69.2201 49.9089 69.2201ZM43.1014 64.3098C44.6644 66.5413 47.2301 67.8807 49.9089 67.8807C52.5876 67.8807 55.1545 66.5413 56.7164 64.3098C57.7203 62.8587 58.2782 61.185 58.2782 59.5103C58.2782 54.9343 54.4837 51.141 49.9078 51.141C45.3318 51.141 41.5384 54.9354 41.5384 59.5103C41.5395 61.185 42.0975 62.8587 43.1014 64.3098Z"
      fill="currentColor"
    />
    <path
      d="M67.654 73.4608C64.5292 73.4608 61.5151 71.8989 59.7296 69.332C58.6139 67.6584 57.9453 65.7611 57.9453 63.7521C57.9453 58.3958 62.2977 53.9316 67.654 53.9316C73.0103 53.9316 77.4745 58.284 77.4745 63.7521C77.4745 65.7611 76.8037 67.7691 75.689 69.332C73.7918 71.8989 70.7788 73.4608 67.654 73.4608ZM60.7347 68.4398C62.2966 70.6714 64.8646 72.0107 67.5411 72.0107C70.2198 72.0107 72.7867 70.6714 74.3486 68.4398C75.3525 66.9887 75.9115 65.3151 75.9115 63.6414C75.9115 59.0655 72.1159 55.271 67.5411 55.271C62.9651 55.271 59.1707 59.0655 59.1707 63.6414C59.2847 65.4258 59.7297 67.1005 60.7347 68.4398Z"
      fill="currentColor"
    />
    <path
      d="M79.9284 35.7408H70.554C70.2186 35.7408 69.8855 35.4054 69.8855 35.0711C69.8855 34.7368 68.1657 34.5 68.5 34.5L78.5 34V30.5C78.5 28.3792 77.4722 27.3196 75.3524 27.3196L49.5 27.5C48.4949 27.5 47.6697 27.8303 47 28.5C46.3292 29.1697 46 29.3742 46 30.5C46 32.6208 48.8792 34.5 51 34.5C51.3343 34.5 50.2434 34.7368 50.2434 35.0711C50.2434 35.4054 49.908 35.7408 49.5737 35.7408C46.6714 35.7408 44.3281 33.3974 44.3281 30.4951C44.3281 29.044 44.886 27.8164 45.8911 26.8125C46.895 25.8085 48.2343 25.2495 49.5737 25.2495H75.3524C78.2547 25.2495 80.5981 27.5939 80.5981 30.4951V35.0711C80.5992 35.4054 80.2638 35.7408 79.9284 35.7408Z"
      fill="currentColor"
    />
    <path
      d="M26.1395 51.1411C26.0277 51.1411 25.9159 51.1411 25.8041 51.0293C25.5816 50.9175 25.4688 50.695 25.4688 50.3596V40.2037C25.4688 36.9671 28.1475 34.2896 31.384 34.2896H70.4445C72.0063 34.2896 73.5682 34.9592 74.685 36.075C75.8008 37.1907 76.4705 38.6419 76.4705 40.3155C76.4705 43.5521 73.7918 46.2297 70.5552 46.2297H33.7274L26.5856 51.0282C26.4727 51.0293 26.362 51.1411 26.1395 51.1411ZM31 36.5C29.5 36.5 27.5 38 27.5 40.5V47.5L32.5 44C32.5 44 33.3882 44 33.5 44H70.5C72.9562 44 74.4369 42.4897 74.2253 40.3155C74 38 72 36.5 70.3316 36.5C69.6245 36.5 71.5592 36.5 70.3316 36.5H50.5H31Z"
      fill="currentColor"
    />
    <path
      d="M28.9278 30.1608C26.2491 30.1608 24.0176 27.9282 24.0176 25.2506C24.0176 22.573 26.2502 20.3403 28.9278 20.3403C31.6065 20.3403 33.838 22.573 33.838 25.2506C33.838 27.9282 31.6065 30.1608 28.9278 30.1608ZM28.9278 21.7904C27.0305 21.7904 25.4676 23.3533 25.4676 25.2506C25.4676 27.1478 27.0305 28.7097 28.9278 28.7097C30.825 28.7097 32.3869 27.1478 32.3869 25.2506C32.4998 23.3522 30.9368 21.7904 28.9278 21.7904Z"
      fill="currentColor"
    />
    <path
      d="M55.4874 80.3802C53.5902 80.3802 52.0283 78.8172 52.0283 76.9211C52.0283 75.0239 53.5902 73.4609 55.4874 73.4609C57.3846 73.4609 58.9476 75.0239 58.9476 76.9211C58.9476 78.8184 57.3846 80.3802 55.4874 80.3802ZM55.4874 74.8003C54.3716 74.8003 53.3677 75.6936 53.3677 76.9211C53.3677 78.0369 54.2609 79.0408 55.4874 79.0408C56.715 79.0408 57.6082 78.1487 57.6082 76.9211C57.6082 75.6936 56.6043 74.8003 55.4874 74.8003Z"
      fill="currentColor"
    />
  </Icon>
)
