import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ExitIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12.5C6 12.7761 6.22386 13 6.5 13L14.5 13C14.7761 13 15 12.7761 15 12.5L15 3.5C15 3.22386 14.7761 3 14.5 3L6.5 3C6.22386 3 6 3.22386 6 3.5L6 5.5C6 5.77614 5.77614 6 5.5 6C5.22386 6 5 5.77614 5 5.5L5 3.5C5 2.67157 5.67157 2 6.5 2L14.5 2C15.3284 2 16 2.67157 16 3.5L16 12.5C16 13.3284 15.3284 14 14.5 14L6.5 14C5.67157 14 5 13.3284 5 12.5L5 10.5C5 10.2239 5.22386 10 5.5 10C5.77614 10 6 10.2239 6 10.5L6 12.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146446 8.35355C-0.0488157 8.15829 -0.0488157 7.84171 0.146446 7.64645L3.14645 4.64645C3.34171 4.45118 3.65829 4.45118 3.85355 4.64645C4.04882 4.84171 4.04882 5.15829 3.85355 5.35355L1.70711 7.5L10.5 7.5C10.7761 7.5 11 7.72386 11 8C11 8.27614 10.7761 8.5 10.5 8.5L1.70711 8.5L3.85355 10.6464C4.04882 10.8417 4.04882 11.1583 3.85355 11.3536C3.65829 11.5488 3.34171 11.5488 3.14645 11.3536L0.146446 8.35355Z"
      fill="currentColor"
    />
  </Icon>
)
