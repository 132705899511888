import {DownIcon, XIcon} from '@/icons'
import {Checkbox, Popover} from '..'
import {cn} from '@/lib/utils'
import {CheckBoxDropdownOption, Props} from './types'
import {ChangeEvent, KeyboardEvent, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

export const CheckboxesDropdown = ({
  label,
  placeholder,
  className,
  options,
  contentClassName,
  required,
  value,
  onChange,
}: Props) => {
  const [isInputOpen, setIsInputOpen] = useState(false)
  const [addMoreValue, setAddMoreValue] = useState('')
  const [values, setValues] = useState(
    Object.fromEntries(
      typeof value === 'string'
        ? value.split(', ').map(key => [key, true])
        : options?.map(option => [option.value, Boolean((value as any)?.[option.value])]),
    ),
  )
  const {t} = useTranslation()

  const selectedValuesStr = useMemo(
    () =>
      Object.entries(values)
        .filter(([, val]) => val)
        .map(([key]) => key)
        .join(', '),
    [values],
  )

  const onCheckBox = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const updatedValue = {
      ...values,
      [key]: event.target.checked,
    }
    setValues(updatedValue)
    if (onChange) {
      onChange(
        Object.entries(updatedValue)
          .filter(([, val]) => val)
          .map(([k]) => k)
          .join(', '),
      )
    }
  }

  const addMoreOptions = () => {
    const findOptions = options?.find(c => c.value === addMoreValue)
    if (addMoreValue !== '' && !findOptions) {
      options.push({label: addMoreValue, value: addMoreValue})
      setAddMoreValue('')
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      addMoreOptions()
    }
  }

  const checkboxesOptions = useMemo<CheckBoxDropdownOption[]>((): CheckBoxDropdownOption[] => {
    const uniqueData: CheckBoxDropdownOption[] = []
    const uniqueValues = new Set()
    options.concat(Object.keys(values || {}).map(item => ({label: item, value: item}))).forEach(item => {
      if (!uniqueValues.has(item.value)) {
        uniqueValues.add(item.value)
        uniqueData.push(item)
      }
    })
    return Array.from(uniqueValues).map(opt => ({label: opt, value: opt})) as CheckBoxDropdownOption[]
  }, [options, values, addMoreValue])

  return (
    <Popover
      className={contentClassName}
      content={
        <div className="flex flex-col gap-4">
          {[...checkboxesOptions]?.map(option => (
            <Checkbox
              key={option.value as string}
              {...option}
              checked={values[option.value as string] as boolean}
              onChange={event => onCheckBox(event, option.value as string)}
            />
          ))}
          <div>
            {isInputOpen && (
              <div className="flex w-full rounded bg-blue-7 px-2.5 py-2 justify-between">
                <input
                  value={addMoreValue}
                  onChange={e => setAddMoreValue(e.target?.value)}
                  className="bg-blue-7"
                  type="text"
                  onBlur={addMoreOptions}
                  onKeyDown={handleKeyDown}
                />

                <button onClick={() => setIsInputOpen(false)}>
                  <XIcon className="w-5 text-blue-2 h-5" />
                </button>
              </div>
            )}
            <button onClick={() => setIsInputOpen(true)} className="text-blue-9 mt-5 text-base font-bold leading-6 ">
              {t('common.buttons.add_one_more')} {label}
            </button>
          </div>
        </div>
      }
    >
      <div>
        {label && (
          <span className="block mb-2.5 text-blue-1 leading-6 font-bold">
            {label} {required && <span className="text-orange">*</span>}
          </span>
        )}
        <div
          className={cn(
            'border border-input shadow-box-sm bg-blue-7 rounded-lg py-4 px-6 w-full text-blue-2 flex items-center justify-between cursor-pointer min-h-[63.479px]',
            className,
          )}
        >
          <span className={cn('text-xl leading-7.5 line-clamp-1', selectedValuesStr ? 'text-blue' : 'text-blue-2')}>
            {selectedValuesStr || <span className="text-[#9ca3af]">{placeholder}</span> || 'Select values'}
          </span>
          <DownIcon className="w-4 h-4 min-w-[16px] min-h-[16px]" />
        </div>
      </div>
    </Popover>
  )
}
