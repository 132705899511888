import {cn} from '@/lib/utils'
import {useState, useMemo} from 'react'
import {ReviewCard} from './components'
import {useGetHomePageQuery} from '@/features/home'
import {useTranslation} from 'react-i18next'

export const Reviews = () => {
  const [activeTagIdx, setActiveTabIdx] = useState(0)
  const {data: {data: homePage} = {}} = useGetHomePageQuery('')
  const {t} = useTranslation()
  const [type, setType] = useState('user')
  const tabs = useMemo(
    () => [
      {label: 'home.reviews.students', value: 'students', type: 'user'},
      {label: 'home.reviews.universities', value: 'universities', type: 'university'},
      {label: 'home.reviews.partners', value: 'partners', type: 'company'},
    ],
    [],
  )

  const sortHandle = (index: number, cardType: string) => {
    setType(cardType)
    setActiveTabIdx(index)
  }

  return (
    <section id="reviews" className="bg-blue text-white py-7 md:pt-14 md:pb-24">
      <div className="px-4 md:container mx-auto">
        <h2 data-aos="fade-up" className="text-4xl md:text-6xl font-bold leading-9 md:leading-17 text-center">
          {t('home.reviews.title')}
        </h2>
        <div
          data-aos="fade-up"
          className="relative w-max mt-5 md:mt-10 mb-7 md:mb-14 mx-auto border border-blue-2 rounded-full p-1"
        >
          <div
            className="w-1/3 bg-white rounded-full absolute top-1 left-1 h-[calc(100%-8px)] duration-default"
            style={{left: `calc(${activeTagIdx * 33 + (activeTagIdx === tabs.length - 1 ? 0 : 1)}%)`}}
          />
          {tabs.map((tab, index) => (
            <button
              key={tab.value}
              onClick={() => sortHandle(index, tab.type)}
              className={cn(
                'relative py-1 md:py-2.5 px-4 md:px-16 text-blue-4 duration-default text-sm md:text-base',
                activeTagIdx === index ? 'text-blue' : 'hover:text-white',
              )}
            >
              {t(tab.label)}
            </button>
          ))}
        </div>
        <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-14">
          {homePage?.feedbacks
            ?.filter((c: any) => c.type === type)
            .map((review: any, index: number) => <ReviewCard key={index} review={review} />)}
        </div>
      </div>
    </section>
  )
}
