import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const CheckboxCheckIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 17 12">
    <path
      d="M5.96556 11.7643L0.735652 6.53442C0.421449 6.22021 0.421449 5.71077 0.735652 5.39654L1.8735 4.25865C2.1877 3.94442 2.69718 3.94442 3.01138 4.25865L6.5345 7.78174L14.0806 0.235652C14.3948 -0.0785507 14.9043 -0.0785507 15.2185 0.235652L16.3563 1.37353C16.6705 1.68774 16.6705 2.19718 16.3563 2.51141L7.10344 11.7644C6.7892 12.0786 6.27976 12.0786 5.96556 11.7643Z"
      fill="currentColor"
    />
  </Icon>
)
