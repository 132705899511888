import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const NotificationIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path d="M10 20C11.3807 20 12.5 18.8807 12.5 17.5H7.5C7.5 18.8807 8.61929 20 10 20Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.39781L9.00351 2.59906C6.7194 3.06036 5.00003 5.08079 5.00003 7.5C5.00003 8.28478 4.83207 10.2466 4.42644 12.1777C4.22508 13.1364 3.95568 14.1345 3.59762 15H16.4024C16.0443 14.1345 15.7749 13.1364 15.5736 12.1777C15.168 10.2466 15 8.28477 15 7.5C15 5.08077 13.2806 3.06034 10.9965 2.59906L10 2.39781ZM17.7741 15C18.0532 15.5592 18.3768 16.0012 18.75 16.25H1.25C1.62316 16.0012 1.94681 15.5592 2.22587 15C3.34903 12.7492 3.75003 8.59895 3.75003 7.5C3.75003 4.47417 5.90025 1.95056 8.75605 1.3738C8.75205 1.33308 8.75 1.29178 8.75 1.25C8.75 0.559644 9.30964 0 10 0C10.6904 0 11.25 0.559644 11.25 1.25C11.25 1.29177 11.248 1.33307 11.2439 1.37379C14.0998 1.95053 16.25 4.47415 16.25 7.5C16.25 8.59895 16.651 12.7492 17.7741 15Z"
      fill="currentColor"
    />
  </Icon>
)
