/* eslint-disable react-hooks/exhaustive-deps */
import {Loader, Pagination} from '@/components/common'
import {MediaCard} from './components'
import {useTranslation} from 'react-i18next'
import {useLazyGetHighlightsQuery} from '@/features/home-edit'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {PaginationChangePramas} from '@/components/common/pagination/types'
import {useEffect} from 'react'

export const SocialMedia = () => {
  const [getHighlights, {data: {data: highlights = [], afterFilteringCount = 0} = {}, isLoading}] =
    useLazyGetHighlightsQuery()
  const {t} = useTranslation()
  const handleRequest = useHandleRequest()

  const fetchHighlights = async (params?: PaginationChangePramas) => {
    await handleRequest({
      request: async () => {
        const result = await getHighlights({
          page: 1,
          ...(params || {}),
          per_page: 4,
        })

        return result
      },
    })
  }

  const onPaginationChange = (pramas: PaginationChangePramas) => {
    fetchHighlights(pramas)
  }

  useEffect(() => {
    fetchHighlights()
  }, [])

  if (!highlights?.length) {
    return ''
  }

  return (
    <section id="social-media" className="px-4 md:container">
      <div className="h-20 md:h-32 w-full" />
      <h2 data-aos="fade-up" className="text-4xl md:text-[50px] font-bold leading-9 md:leading-17 text-center">
        {t('home.social_media_highlights.title')}
      </h2>
      <div data-aos="fade-up">
        {!isLoading ? (
          <div className="w-full scrollbar-hide  overflow-x-auto">
            <div className="flex items-center md:grid  md:grid-cols-2 lg:grid-cols-4 gap-5 mt-7 md:mt-14 mb-12 w-max md:w-[auto]">
              {highlights?.map((highlight: any) => <MediaCard key={highlight._id} highlight={highlight} />)}
            </div>
          </div>
        ) : (
          <div className="relative h-[440px]">
            <Loader className="absolute shadow-none" />
          </div>
        )}
        {afterFilteringCount ? (
          <div className="mt-8 flex justify-center">
            <Pagination totalDataCount={afterFilteringCount} onPageChange={onPaginationChange} perPage={4 as any} />
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  )
}
