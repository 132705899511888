import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const XIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      d="M9.99874 8.51922L15.1839 3.33398L16.6654 4.81547L11.4802 10.0007L16.6654 15.1858L15.1839 16.6673L9.99874 11.4822L4.81351 16.6673L3.33203 15.1858L8.51727 10.0007L3.33203 4.81547L4.81351 3.33398L9.99874 8.51922Z"
      fill="currentColor"
    />
  </Icon>
)
