import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const TrashCircleICon = (props: IconProps) => (
  <Icon fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
    <path
      d="M9.98077 10.4C10.2038 10.4 10.3846 currentColor10.5791 10.3846 10.8V15.6C10.3846 15.8209 10.2038 16 9.98077 16C9.75773 16 9.57692 15.8209 9.57692 15.6V10.8C9.57692 10.5791 9.75773 10.4 9.98077 10.4Z"
      fill="currentColor"
    />
    <path
      d="M12 10.4C12.223 10.4 12.4038 10.5791 12.4038 10.8V15.6C12.4038 15.8209 12.223 16 12 16C11.777 16 11.5962 15.8209 11.5962 15.6V10.8C11.5962 10.5791 11.777 10.4 12 10.4Z"
      fill="currentColor"
    />
    <path
      d="M14.4231 10.8C14.4231 10.5791 14.2423 10.4 14.0192 10.4C13.7962 10.4 13.6154 10.5791 13.6154 10.8V15.6C13.6154 15.8209 13.7962 16 14.0192 16C14.2423 16 14.4231 15.8209 14.4231 15.6V10.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.25 8.4C17.25 8.84183 16.8884 9.2 16.4423 9.2H16.0385V16.4C16.0385 17.2837 15.3152 18 14.4231 18H9.57692C8.68477 18 7.96154 17.2837 7.96154 16.4V9.2H7.55769C7.11162 9.2 6.75 8.84183 6.75 8.4V7.6C6.75 7.15817 7.11162 6.8 7.55769 6.8H10.3846C10.3846 6.35817 10.7462 6 11.1923 6H12.8077C13.2538 6 13.6154 6.35817 13.6154 6.8H16.4423C16.8884 6.8 17.25 7.15817 17.25 7.6V8.4ZM8.86457 9.2L8.76923 9.24721V16.4C8.76923 16.8418 9.13085 17.2 9.57692 17.2H14.4231C14.8692 17.2 15.2308 16.8418 15.2308 16.4V9.24721L15.1354 9.2H8.86457ZM7.55769 8.4V7.6H16.4423V8.4H7.55769Z"
      fill="currentColor"
    />
  </Icon>
)
