export const formatPrice = (price?: number | string) =>
  Number(String(price).replaceAll(' ', '')) < 0
    ? '---'
    : String(Math.floor(typeof price === 'number' ? price : +(price || '').replace(/[^\d]/g, '')?.replaceAll(' ', '')))
        .replaceAll(' ', '')
        .replace(/[^\d]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const formatNumber = (num: string) => num.replace(/[^\d]*(\d+(\.\d*)?)[^\d]*/, '$1').replace(/[^\d.]/g, '')

export const formatPriceBackToNumber = (price?: number | string) =>
  typeof price === 'number' ? price : +(price || '')?.replaceAll(' ', '')
