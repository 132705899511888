import {Image} from '@/components/common'
import {Props} from './types'
import {MediaImageIcon} from '@/icons'
import {InstagramIcon} from '../../icons'
import {getImageURL} from '@/utils/get-image'

export const MediaCard = ({highlight}: Props) => (
  <div className="relative rounded-2xl overflow-hidden min-w-[220px] h-[220px] md:min-w-[300px] md:h-[336px] group">
    <Image
      src={getImageURL(highlight.image)}
      alt={highlight.link}
      className="absolute top-0 left-0 min-w-full min-h-full"
      imageClassName="absolute w-full h-full !duration-300 !transition group-hover:scale-105 cursor-pointer"
    />
    <a href={highlight.link} target="_blank" className="absolute top-0 left-0 w-full h-full" />

    <button className="absolute top-5 right-5 text-white">
      <MediaImageIcon />
    </button>

    <button className="absolute md:bottom-5 md:right-5 bottom-3 right-3 text-white">
      <InstagramIcon className="md:w-8 md:h-8" />
    </button>
  </div>
)
