import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ChatIcon = ({...props}: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34736 14.8674C3.61035 15.1312 3.74204 15.4985 3.70654 15.8692C3.62699 16.7001 3.44377 17.5648 3.20846 18.3681C4.95208 17.9651 6.01697 17.4976 6.50102 17.2523C6.77578 17.1131 7.09227 17.0802 7.38978 17.16C8.21208 17.3805 9.08845 17.5 10 17.5C14.9945 17.5 18.75 13.9906 18.75 10C18.75 6.00943 14.9945 2.5 10 2.5C5.0055 2.5 1.25 6.00943 1.25 10C1.25 11.8351 2.02163 13.538 3.34736 14.8674ZM2.73112 19.7487C2.7227 19.7503 2.71427 19.752 2.70583 19.7537C2.59104 19.7763 2.47403 19.7986 2.35477 19.8204C2.1875 19.851 2.0158 19.8808 1.83965 19.9097C1.59088 19.9504 1.40046 19.6902 1.49872 19.458C1.56292 19.3064 1.62621 19.1474 1.68788 18.9826C1.72705 18.8779 1.76556 18.7708 1.80323 18.6618C1.80486 18.6571 1.80648 18.6524 1.8081 18.6476C2.11778 17.748 2.36996 16.7139 2.46223 15.7501C0.928827 14.2124 0 12.2011 0 10C0 5.16751 4.47715 1.25 10 1.25C15.5228 1.25 20 5.16751 20 10C20 14.8325 15.5228 18.75 10 18.75C8.97906 18.75 7.99385 18.6161 7.06605 18.3674C6.41671 18.6964 5.01792 19.2954 2.73112 19.7487Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6.875C5 6.52982 5.27982 6.25 5.625 6.25H14.375C14.7202 6.25 15 6.52982 15 6.875C15 7.22018 14.7202 7.5 14.375 7.5H5.625C5.27982 7.5 5 7.22018 5 6.875ZM5 10C5 9.65482 5.27982 9.375 5.625 9.375H14.375C14.7202 9.375 15 9.65482 15 10C15 10.3452 14.7202 10.625 14.375 10.625H5.625C5.27982 10.625 5 10.3452 5 10ZM5 13.125C5 12.7798 5.27982 12.5 5.625 12.5H10.625C10.9702 12.5 11.25 12.7798 11.25 13.125C11.25 13.4702 10.9702 13.75 10.625 13.75H5.625C5.27982 13.75 5 13.4702 5 13.125Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)
