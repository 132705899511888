import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const JournalismIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path d="M45.7986 55.7V63.2H39.4992H33.2988V55.7H45.7986Z" fill="transparent" />
    <path
      d="M67.5977 57C61.7983 57 56.998 61.8 56.998 67.6C56.998 73.5 61.7983 78.2 67.5977 78.2C73.398 78.2 78.1983 73.4 78.1983 67.6C78.2983 61.8 73.498 57 67.5977 57Z"
      fill="transparent"
    />
    <path d="M72.4979 67.4999L64.8984 71.9999V63.0999L72.4979 67.4999Z" fill="transparent" />
    <path
      d="M58.2991 32V37L71.9989 34.5V53.2L58.2991 50.7V55.7H45.7993H33.2996H20.7998V32H58.2991Z"
      fill="transparent"
    />
    <path
      d="M55.098 31.9998C53.8979 34.8998 50.9977 36.9998 47.5985 36.9998C44.1983 36.9998 41.2982 34.8998 40.0981 31.9998C39.6981 30.9998 39.498 29.9998 39.498 28.8998C39.498 24.3998 43.0983 20.7998 47.5985 20.7998C52.0978 20.7998 55.698 24.3998 55.698 28.8998C55.798 29.9998 55.498 30.9998 55.098 31.9998Z"
      fill="transparent"
    />
    <path
      d="M31.3983 36.9998C27.9981 36.9998 25.0989 34.8998 23.8989 31.9998C23.4988 30.9998 23.2988 29.9998 23.2988 28.8998C23.2988 24.3998 26.899 20.7998 31.3983 20.7998C35.8986 20.7998 39.4988 24.3998 39.4988 28.8998C39.4988 29.9998 39.2988 31.0998 38.8988 31.9998C37.6987 34.8998 34.7985 36.9998 31.3983 36.9998Z"
      fill="transparent"
    />
    <path
      d="M33.2988 57H20.7991C20.0991 57 19.499 56.4 19.499 55.7V32C19.499 31.3 20.0991 30.7 20.7991 30.7H23.8993C24.5993 30.7 25.1994 31.3 25.1994 32C25.1994 32.7 24.5993 33.3 23.8993 33.3H21.9992V54.6H33.2988C33.9989 54.6 34.5989 55.2 34.5989 55.9C34.4989 56.4 33.8989 57 33.2988 57Z"
      fill="currentColor"
    />
    <path
      d="M40.0988 33.1999H38.8987C38.1987 33.1999 37.5986 32.5999 37.5986 31.8999C37.5986 31.1999 38.1987 30.5999 38.8987 30.5999H40.0988C40.7988 30.5999 41.3989 31.1999 41.3989 31.8999C41.3998 32.6999 40.7998 33.1999 40.0988 33.1999Z"
      fill="transparent"
    />
    <path
      d="M58.2988 57H45.7991C45.0991 57 44.499 56.4 44.499 55.7C44.499 55 45.0991 54.4 45.7991 54.4H56.9988V50.6C56.9988 50.2 57.1988 49.9 57.3988 49.6C57.6988 49.4 58.0988 49.3 58.3989 49.3L70.6986 51.5V36L58.3989 38.2C57.9988 38.3 57.6988 38.2 57.3988 37.9C57.0988 37.7 56.9988 37.3 56.9988 36.9V33.1H55.0987C54.3986 33.1 53.7986 32.5 53.7986 31.8C53.7986 31.1 54.3986 30.5 55.0987 30.5H58.1988C58.8989 30.5 59.4989 31.1 59.4989 31.8V35.3L71.7987 33.1C72.1987 33 72.4987 33.1 72.7987 33.4C73.0987 33.6 73.1987 34 73.1987 34.4V53.2C73.1987 53.6 72.9987 53.9 72.7987 54.2C72.4987 54.4 72.0987 54.5 71.7987 54.5L59.4989 52.3V55.8C59.4989 56.4 58.8989 57 58.2988 57Z"
      fill="currentColor"
    />
    <path
      d="M31.3996 38.1999C27.6003 38.1999 24.2001 35.8999 22.7 32.3999C22.2 31.2999 22 29.9999 22 28.7999C22 23.5999 26.2002 19.3999 31.3996 19.3999C36.5999 19.3999 40.8001 23.5999 40.8001 28.7999C40.8001 29.9999 40.6001 31.2999 40.1001 32.3999C38.599 35.9999 35.1998 38.1999 31.3996 38.1999ZM31.3996 21.9999C27.6003 21.9999 24.5001 25.0999 24.5001 28.8999C24.5001 29.7999 24.7002 30.6999 25.0002 31.4999C26.1002 34.0999 28.6004 35.6999 31.3005 35.6999C34.0007 35.6999 36.6009 33.9999 37.5999 31.4999C37.9 30.6999 38.1 29.7999 38.1 28.8999C38.3 25.0999 35.1998 21.9999 31.3996 21.9999Z"
      fill="currentColor"
    />
    <path
      d="M47.5988 38.1999C43.7986 38.1999 40.3984 35.8999 38.8983 32.3999C38.3983 31.2999 38.1982 29.9999 38.1982 28.7999C38.1982 23.5999 42.3985 19.3999 47.5988 19.3999C52.7981 19.3999 56.9984 23.5999 56.9984 28.7999C56.9984 29.9999 56.7984 31.2999 56.2983 32.3999C54.7992 35.9999 51.399 38.1999 47.5988 38.1999ZM47.5988 21.9999C43.7986 21.9999 40.6984 25.0999 40.6984 28.8999C40.6984 29.7999 40.8984 30.6999 41.1984 31.4999C42.2985 34.0999 44.7986 35.6999 47.4988 35.6999C50.299 35.6999 52.7991 33.9999 53.7982 31.4999C54.0982 30.6999 54.2982 29.7999 54.2982 28.8999C54.4992 25.0999 51.399 21.9999 47.5988 21.9999Z"
      fill="currentColor"
    />
    <path
      d="M67.598 79.5C61.0986 79.5 55.6982 74.2 55.6982 67.6C55.6982 61 60.9986 55.7 67.598 55.7C74.1984 55.7 79.4987 61 79.4987 67.6C79.4987 74.2 74.1993 79.5 67.598 79.5ZM67.598 58.2C62.3986 58.2 58.1984 62.4 58.1984 67.6C58.1984 72.8 62.3986 77 67.598 77C72.7983 77 76.9985 72.8 76.9985 67.6C76.9985 62.4 72.7983 58.2 67.598 58.2Z"
      fill="currentColor"
    />
    <path
      d="M64.8983 73.1999C64.6983 73.1999 64.4983 73.0999 64.2983 72.9999C63.8983 72.7999 63.6982 72.3999 63.6982 71.8999V63.0999C63.6982 62.6999 63.8983 62.1999 64.2983 61.9999C64.6983 61.7999 65.1983 61.7999 65.5984 61.9999L73.2978 66.3999C73.6978 66.5999 73.8979 66.9999 73.8979 67.4999C73.8979 67.8999 73.6978 68.3999 73.2978 68.5999L65.4983 72.9999C65.2983 73.0999 65.0983 73.1999 64.8983 73.1999ZM66.0984 65.2999V69.7999L69.9986 67.4999L66.0984 65.2999Z"
      fill="currentColor"
    />
    <path
      d="M45.7988 64.5H33.2991C32.5991 64.5 31.999 63.9 31.999 63.3V55.8C31.999 55.1 32.5991 54.5 33.2991 54.5H45.7998C46.4989 54.5 47.0989 55.1 47.0989 55.8V63.3C46.9989 63.9 46.3989 64.5 45.7988 64.5ZM34.4992 62H44.4998V57H34.4992V62Z"
      fill="currentColor"
    />
    <path
      d="M30.6984 79.4999C30.4984 79.4999 30.2984 79.3999 30.0984 79.2999C29.4983 78.9999 29.2983 78.1999 29.6984 77.5999L38.4989 62.5999C38.7989 62.0999 39.3989 61.8999 39.899 61.9999C40.399 62.0999 40.799 62.5999 40.799 63.1999V78.1999C40.799 78.8999 40.199 79.4999 39.4989 79.4999C38.7989 79.4999 38.1989 78.8999 38.1989 78.1999V67.8999L31.7995 78.8998C31.5985 79.2998 31.1984 79.4999 30.6984 79.4999Z"
      fill="currentColor"
    />
    <path
      d="M48.2984 79.4998C47.8983 79.4998 47.4983 79.2998 47.1983 78.8998L38.3988 63.8998C38.0988 63.2998 38.2988 62.4998 38.7988 62.1998C39.3988 61.8998 40.1989 62.0998 40.4989 62.5998L49.2994 77.5998C49.5994 78.1998 49.3994 78.9998 48.8994 79.2998C48.6984 79.3998 48.4984 79.4998 48.2984 79.4998Z"
      fill="currentColor"
    />
  </Icon>
)
