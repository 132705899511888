import {CheckboxCheckIcon} from '@/icons/exam'
import {cn} from '@/lib/utils'
import React, {useEffect, useState} from 'react'
import {Props} from './types'

export const YellowCheckbox: React.FC<Props> = ({checked: checkedValue, onChange, disabled, className}) => {
  const [checked, setChecked] = useState(checkedValue)

  useEffect(() => {
    setChecked(checkedValue)
  }, [checkedValue])

  return (
    <button
      type="button"
      onClick={() => {
        setChecked(!checked)
        onChange?.(!checked)
      }}
      className={cn(
        'grid place-content-center rounded-md w-[30px] h-[30px] duration-100',
        checked ? 'bg-orange-6' : 'bg-blue-5',
        className,
      )}
      disabled={disabled}
    >
      <CheckboxCheckIcon className={cn('duration-150 w-4 h-3', checked ? 'text-white' : 'text-blue-5')} />
    </button>
  )
}
