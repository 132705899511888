import * as React from 'react'
import {format} from 'date-fns'
import {Calendar as CalendarIcon} from 'lucide-react'

import {cn} from '@/lib/utils'
import {Button} from '@/components/ui/button'
import {Calendar} from '@/components/ui/calendar'
import {Popover, PopoverContent, PopoverTrigger} from '@/components/ui/popover'
import {TimePicker} from '..'
import {Props} from './types'

export const DateTimePicker: React.FC<Props> = ({placeholder, value, onChange, disabled, className}) => {
  const [date, setDate] = React.useState<Date | undefined>(value)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={'outline'}
          className={cn('w-[230px] justify-start text-left font-normal', !date && 'text-muted-foreground', className)}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, 'PPP HH:mm:ss') : <span>{placeholder || 'Pick a date'}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={d => {
            setDate(d)
            onChange?.(d as Date)
          }}
          initialFocus
        />
        <div className="p-3 border-t border-border">
          <TimePicker
            setDate={d => {
              setDate(d)
              onChange?.(d as Date)
            }}
            date={date}
          />
        </div>
      </PopoverContent>
    </Popover>
  )
}
