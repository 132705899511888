import {RegisterOptions} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

export const useValidationOptions = () => {
  const {t} = useTranslation()

  return (required: boolean, minLength?: boolean | number, maxLength?: boolean | number, extraValidatons = {}): any => {
    const validations: RegisterOptions<any> = extraValidatons

    if (required) {
      validations.required = t('error_messages.please_fill_out_the_form')
    }

    if (typeof minLength === 'number') {
      validations.minLength = {
        value: minLength,
        message: `${t('error_messages.minimum')} ${minLength} ${t('error_messages.characters_required')} `,
      }
    }

    if (typeof maxLength === 'number') {
      validations.maxLength = {
        value: maxLength,
        message: `${t('error_messages.minimum')} ${maxLength}  ${t('error_messages.characters_allowed')}`,
      }
    }

    return validations
  }
}
