import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const LawIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path d="M72.0014 28.5L68.9012 32.3H52.101L52.001 28.5H72.0014Z" fill="transparent" />
    <path
      d="M77.6022 46C77.6022 50.8 73.7019 54.8 68.8016 54.8C63.9022 54.8 60.001 50.9 60.001 46C61.9011 46 75.7021 46 77.6022 46Z"
      fill="transparent"
    />
    <path
      d="M68.9016 55.4004C63.7022 55.4004 59.502 51.2004 59.502 46.0004C59.502 45.7004 59.803 45.4004 60.103 45.4004H77.6032C77.9032 45.4004 78.2022 45.7004 78.2022 46.0004C78.3023 51.2004 74.002 55.4004 68.9016 55.4004ZM62 47.5C62 50 65.5 53.5 69 53.5C72.5 53.5 76 50.5 76 47.5H62Z"
      fill="currentColor"
    />
    <path
      d="M63.9017 46.5998C63.8007 46.5998 63.8007 46.5998 63.7017 46.5998C63.4017 46.4998 62.399 46.3 62.499 46L67.4993 32C67.6003 31.7 68.8021 31.5998 69.1021 31.6998C69.4031 31.7998 69.6021 32.1998 69.5021 32.4998L64.5018 46.2998C64.4018 46.4998 64.1007 46.5998 63.9017 46.5998Z"
      fill="currentColor"
    />
    <path
      d="M73.9016 46.5995C73.6016 46.5995 72.6 46.2 72.5 46L67.4997 32.5C67.4007 32.2 68.3012 31.6995 68.7013 31.5995C69.0013 31.4995 69.4014 31.5995 69.5014 31.9995L74.5017 45.6995C74.6007 45.9995 74.5017 46.3995 74.1017 46.4995C74.0017 46.5995 73.9016 46.5995 73.9016 46.5995Z"
      fill="currentColor"
    />
    <path
      d="M68.4993 32.9C68.3993 32.9 68.1992 32.9 68.1002 32.8C67.8002 32.6 67.3 31.8 67.5 31.5L69 29.5H51.5977C51.2976 29.5 51 28.9 51 28.6C51 28.3 51.2986 28 51.5977 28H71.5985C71.7985 28 72.0995 28.1 72.1985 28.4C72.2985 28.6 72.2986 28.9 72.0985 29.1L68.9983 32.9C68.7993 32.8 68.6993 32.9 68.4993 32.9Z"
      fill="currentColor"
    />
    <path
      d="M68.9012 32.9002H52.101C51.801 32.9002 51.501 32.6002 51.501 32.3002C51.501 32.0002 51.801 31.1658 52.101 31.1658H69.4012C69.7013 31.1658 69.4012 32.0002 69.4012 32.3002C69.5012 32.6002 69.2012 32.9002 68.9012 32.9002Z"
      fill="currentColor"
    />
    <path
      d="M71.902 23.7998C73.6021 23.7998 75.0022 25.1998 75.0022 26.8998C75.0022 28.5998 73.6021 29.9998 71.902 29.9998C70.2018 29.9998 68.8018 28.5998 68.8018 26.8998C68.8018 25.0998 70.2018 23.7998 71.902 23.7998Z"
      fill="transparent"
    />
    <path
      d="M21.8004 69.7998C23.5005 69.7998 24.9007 71.1998 24.9007 72.8998C24.9007 74.5998 23.5005 75.9998 21.8004 75.9998C20.1003 75.9998 18.7002 74.5998 18.7002 72.8998C18.7002 71.0998 20.1003 69.7998 21.8004 69.7998Z"
      fill="transparent"
    />
    <path
      d="M60.6019 74.3V76.2H39.4014V74.3C39.4014 73.3 40.2025 72.5 41.2015 72.5C43.4017 72.5 56.9006 72.5 58.8008 72.5C59.8008 72.5 60.6019 73.3 60.6019 74.3Z"
      fill="transparent"
    />
    <path
      d="M56.3017 70.5998V72.4998H43.8018V70.5998C43.8018 69.5998 44.6018 68.7998 45.6009 68.7998C48.9011 68.7998 51.2013 68.7998 54.5005 68.7998C55.4016 68.7998 56.3017 69.5998 56.3017 70.5998Z"
      fill="transparent"
    />
    <path
      d="M53.8003 68.8H46.3008C46.3008 68.2 47.5009 36.5 47.6009 35H52.6002C52.6002 36.5 53.7003 68.2 53.8003 68.8Z"
      fill="transparent"
    />
    <path
      d="M39.402 52.5C39.402 57.3 35.5017 61.3 30.6024 61.3C25.702 61.3 21.8018 57.4 21.8018 52.5C23.7019 52.5 37.5018 52.5 39.402 52.5Z"
      fill="transparent"
    />
    <path
      d="M74.1019 66.9004C74.6019 66.9004 75.002 67.3004 75.002 67.8004C75.002 68.3004 74.6019 68.7004 74.1019 68.7004C73.6019 68.7004 73.2018 68.3004 73.2018 67.8004C73.1018 67.3004 73.5019 66.9004 74.1019 66.9004Z"
      fill="currentColor"
    />
    <path
      d="M60.6012 15C61.1013 15 61.5013 15.4 61.5013 15.9C61.5013 16.4 61.1013 16.8 60.6012 16.8C60.1012 16.8 59.7012 16.4 59.7012 15.9C59.7012 15.4 60.1012 15 60.6012 15Z"
      fill="currentColor"
    />
    <path
      d="M57.2018 58.0996C57.7019 58.0996 58.1008 58.4996 58.1008 58.9996C58.1008 59.4996 57.7019 59.8996 57.2018 59.8996C56.7018 59.8996 56.3018 59.4996 56.3018 58.9996C56.3018 58.4996 56.7028 58.0996 57.2018 58.0996Z"
      fill="currentColor"
    />
    <path
      d="M46.9001 18.6992C47.4001 18.6992 47.8001 19.0992 47.8001 19.5992C47.8001 20.0992 47.4001 20.4992 46.9001 20.4992C46.4 20.4992 46 20.0992 46 19.5992C46 19.0992 46.4 18.6992 46.9001 18.6992Z"
      fill="currentColor"
    />
    <path
      d="M42.9001 44C43.4001 44 43.7992 44.4 43.7992 44.9C43.7992 45.4 43.4001 45.8 42.9001 45.8C42.399 45.8 42 45.4 42 44.9C42 44.4 42.4 44 42.9001 44Z"
      fill="currentColor"
    />
    <path
      d="M39.1009 65.5996C39.6009 65.5996 40.001 65.9996 40.001 66.4996C40.001 66.9996 39.6009 67.3996 39.1009 67.3996C38.6009 67.3996 38.2008 66.9996 38.2008 66.4996C38.1008 65.9996 38.5018 65.5996 39.1009 65.5996Z"
      fill="currentColor"
    />
    <path
      d="M23.9154 25C24.4154 25 24.8155 25.4 24.8155 25.9C24.8155 26.4 24.4154 26.8 23.9154 26.8C23.4153 26.8 23.0153 26.4 23.0153 25.9C22.9153 25.4 23.3153 25 23.9154 25Z"
      fill="currentColor"
    />
    <path d="M27.2028 28.5L30.3029 32.3H47.1031L47.2031 28.5H27.2028Z" fill="transparent" />
    <path
      d="M21.6019 46C21.6019 50.8 25.5022 54.8 30.4025 54.8C35.3019 54.8 39.2031 50.9 39.2031 46C37.303 46 23.502 46 21.6019 46Z"
      fill="transparent"
    />
    <path
      d="M30.3025 55.4004C35.5019 55.4004 39.7021 51.2004 39.7021 46.0004C39.7021 45.7004 39.4011 45.4004 39.1011 45.4004H21.6009C21.3009 45.4004 21.0019 45.7004 21.0019 46.0004C20.9018 51.2004 25.2022 55.4004 30.3025 55.4004ZM37.2041 47.5C37.2041 50 33.7041 53.5 30.2041 53.5C26.7041 53.5 23.2041 50.5 23.2041 47.5H37.2041Z"
      fill="currentColor"
    />
    <path
      d="M35.3024 46.5998C35.4034 46.5998 35.4034 46.5998 35.5024 46.5998C35.8025 46.4998 36.8051 46.3 36.7051 46L31.7048 32C31.6038 31.7 30.402 31.5998 30.102 31.6998C29.801 31.7998 29.602 32.1998 29.702 32.4998L34.7023 46.2998C34.8023 46.4998 35.1034 46.5998 35.3024 46.5998Z"
      fill="currentColor"
    />
    <path
      d="M25.3025 46.5995C25.6025 46.5995 26.6041 46.2 26.7041 46L31.7044 32.5C31.8034 32.2 30.9029 31.6995 30.5028 31.5995C30.2028 31.4995 29.8027 31.5995 29.7027 31.9995L24.7024 45.6995C24.6034 45.9995 24.7024 46.3995 25.1024 46.4995C25.2024 46.5995 25.3025 46.5995 25.3025 46.5995Z"
      fill="currentColor"
    />
    <path
      d="M30.7048 32.9C30.8048 32.9 31.0049 32.9 31.1039 32.8C31.4039 32.6 31.9041 31.8 31.7041 31.5L30.2041 29.5H47.6064C47.9065 29.5 48.2041 28.9 48.2041 28.6C48.2041 28.3 47.9055 28 47.6064 28H27.6056C27.4056 28 27.1046 28.1 27.0056 28.4C26.9056 28.6 26.9055 28.9 27.1056 29.1L30.2058 32.9C30.4048 32.8 30.5048 32.9 30.7048 32.9Z"
      fill="currentColor"
    />
    <path
      d="M30.3029 32.9002H47.1031C47.4031 32.9002 47.7031 32.6002 47.7031 32.3002C47.7031 32.0002 47.4031 31.1658 47.1031 31.1658H29.8029C29.5028 31.1658 29.8029 32.0002 29.8029 32.3002C29.7029 32.6002 30.0029 32.9002 30.3029 32.9002Z"
      fill="currentColor"
    />
    <path
      d="M53.3572 69C53.0813 69 51.7923 68.5086 51.7923 68.14L51.3325 47.6224L50.8727 28.5795H49.0335L48.5737 47.6224L48.114 68.2629C48.114 68.6314 46.8277 69 46.5518 69C46.2759 69 46 68.6314 46 68.2629L47.1036 26.7372C47.1036 26.3686 47.3795 26 47.6545 26H52.2527C52.5286 26 52.8054 26.3686 52.8054 26.7372L54 68.14C54.0009 68.6314 53.725 69 53.3572 69C53.4491 69 53.4491 69 53.3572 69Z"
      fill="currentColor"
    />
    <path
      d="M56.3011 73.1002C56.0011 73.1002 55.5 72.8 55.5 72.5V70.6002C55.5 69.9002 55.201 69.4002 54.501 69.4002H45.6013C44.9013 69.4002 44.4013 69.9002 44.4013 70.6002V72.5002C44.4013 72.8002 44.1013 73.1002 43.8012 73.1002C43.5002 73.1002 42.5 72.8002 42.5 72.5002V70.6002C42.5 68 44.3013 67 45.6013 67H54C55.3001 67 57.5 67.5 57.5 70.6002V72.5C57.5 72.8 56.6012 73.1002 56.3011 73.1002Z"
      fill="currentColor"
    />
    <path
      d="M60.6023 76.9001C60.3022 76.9001 59.5 76.6001 59.5 76.3001V74.4001C59.5 73.7001 59.5012 73.2001 58.8012 73.2001H41.2019C40.5019 73.2001 40.5 73.7001 40.5 74.4001V76.3001C40.5 76.6001 39.7018 76.9001 39.4018 76.9001C39.1017 76.9001 38.8018 76.6001 38.8018 76.3001V74.4001C38.8018 72.0922 39.9009 71.3269 41.2019 71.3269L58.5 71.5C59.8011 71.5 61.2013 72 61.2013 74.4001C61.2013 76.8002 61.2013 76.3001 61.2013 76.3001C61.3023 76.6001 61.0023 76.9001 60.6023 76.9001Z"
      fill="currentColor"
    />
    <path
      d="M71.9018 30.6C69.8016 30.6 68.1016 28.9 68.1016 26.8C68.1016 24.7 69.8016 23 71.9018 23C74.0019 23 75.7021 24.7 75.7021 26.8C75.7021 28.9 73.9019 30.6 71.9018 30.6ZM71.9018 24.4C70.5017 24.4 69.4016 25.5 69.4016 26.9C69.4016 28.3 70.5017 29.4 71.9018 29.4C73.3019 29.4 74.401 28.3 74.401 26.9C74.401 25.5 73.3019 24.4 71.9018 24.4Z"
      fill="currentColor"
    />
    <path
      d="M21.8002 76.6C19.7001 76.6 18 74.9 18 72.8C18 70.7 19.7001 69 21.8002 69C23.9004 69 25.6005 70.7 25.6005 72.8C25.6005 74.9 23.8004 76.6 21.8002 76.6ZM21.8002 70.4C20.4001 70.4 19.3001 71.5 19.3001 72.9C19.3001 74.3 20.4001 75.4 21.8002 75.4C23.2003 75.4 24.2994 74.3 24.2994 72.9C24.2994 71.5 23.2003 70.4 21.8002 70.4Z"
      fill="currentColor"
    />
    <path
      d="M62.5021 76.9002H37.5014C37 76.9002 37 76.3 37 76C37 75.7 37 75 37.5 75H50H62.5021C63 75 63 75.7 63 76C62.999 76.3 63 76.9002 62.5021 76.9002Z"
      fill="currentColor"
    />
  </Icon>
)
