import {CertificatesNames} from '../certificates'

export enum StudyLevel {
  BACHELOR = 'Bachelor',
  CERTIFICATION = 'Certification',
  MBA = 'MBA',
  MASTERS = 'Masters',
  PHD = 'PhD',
  RESEARCH = 'Research',
  FOUNDATION = 'Foundation',
}

export const STUDY_LEVELS = [
  {label: 'Bachelor', value: StudyLevel.BACHELOR},
  {label: 'Certification', value: StudyLevel.CERTIFICATION},
  {label: 'MBA', value: StudyLevel.MBA},
  {label: 'Masters', value: StudyLevel.MASTERS},
  {label: 'Research', value: StudyLevel.RESEARCH},
  {label: 'PhD', value: StudyLevel.PHD},
  {label: 'Foundation', value: StudyLevel.FOUNDATION},
]

export const LEARNING_LEVELS = [
  {label: 'Beginner', value: 'Beginner'},
  {label: 'Intermediate', value: 'Intermediate'},
  {label: 'Advanced', value: 'Advanced'},
]

export const SKILL_CATEGORIES = [
  {label: 'Beginner', value: 'Beginner'},
  {label: 'Intermediate', value: 'Intermediate'},
  {label: 'Advanced', value: 'Advanced'},
]

export const STUDY_DURATIONS = [
  {label: '4-year', value: '4' as any},
  {label: '2-year', value: '2' as any},
]

export const STUDY_FORMATS = [
  {label: 'Full time', value: 'Full time'},
  {label: 'Part time', value: 'Part time'},
]

export const STUDY_ATTENDANCE = [
  {label: 'Offline on Campus', value: 'Offile on Campus'},
  {label: 'Offline outside of Campus', value: 'Offile outside of Campus'},
  {label: 'Both - Online & Offline', value: 'Both - Online & Offline'},
]

export const GENDERS = [
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'},
]

export const IELTS_SCORES = [
  {label: '5', value: '5'},
  {label: '5.5', value: '5.5'},
  {label: '6', value: '6'},
  {label: '6.5', value: '6.5'},
  {label: '7', value: '7'},
  {label: '7.5', value: '7.5'},
  {label: '8', value: '8'},
  {label: '8.5', value: '8.5'},
  {label: '9', value: '9'},
]

export enum IDENTIFICATION {
  ID_CARD = 'ID-CARD',
  PASSPORT = 'PASSPORT',
}

export const IDENTIFICATIONS = [
  {label: 'ID Card', value: IDENTIFICATION.ID_CARD},
  {label: 'Passport', value: IDENTIFICATION.PASSPORT},
]

export const CERTIFICATES = [
  {label: 'IELTS', value: CertificatesNames.IELTS},
  {label: 'CEFR', value: CertificatesNames.CEFR},
  {label: 'TOEFL', value: CertificatesNames.TOEFL},
  {label: 'SAT', value: CertificatesNames.SAT},
  {label: 'ITEP', value: CertificatesNames.ITEP},
  {label: 'DUOLINGO', value: CertificatesNames.DUOLINGO},
]

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const Specialities = [
  {
    value: 'Art & Illustration',
    label: 'Art & Illustration',
  },
  {
    value: 'Business',
    label: 'Business',
  },
  {
    value: 'IT',
    label: 'IT',
  },
  {
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    value: 'Security',
    label: 'Security',
  },
  {
    value: 'Marketing',
    label: 'Marketing',
  },
]
