export enum DashboardSeaerchablePages {
  UNIVERSITIES = '/dashboard/universities',
  COMPANIES = '/dashboard/companies',
  VACANCIES = '/dashboard/vacancies',
  SKILLS = '/dashboard/skills',
  USERS = '/dashboard/users',
  CONSULTINGS = '/dashboard/consultings',
  MANAGED_USERS = '/dashboard/managed-users',
  APPLIED_USERS = '/dashboard/applied-users',
  SAVED_USERS = '/dashboard/saved-users',
}
