import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const CollegeIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91661 2.37484L2.42249 5.37247C2.42261 5.37242 2.41975 5.37356 2.41425 5.37698C2.40876 5.3804 2.40137 5.38564 2.3926 5.39317C2.37454 5.40868 2.35476 5.43041 2.33674 5.45713C2.31872 5.48387 2.30596 5.51043 2.29831 5.533C2.29102 5.55452 2.29042 5.5662 2.29037 5.56712C2.29037 5.56719 2.29037 5.56716 2.29037 5.56712L2.29036 8.33383C2.29036 8.44699 2.38554 8.54217 2.4987 8.54217H17.4987C17.6119 8.54217 17.707 8.44699 17.707 8.33383V5.56716C17.707 5.5672 17.707 5.56722 17.707 5.56716C17.707 5.56624 17.7064 5.55452 17.6991 5.533C17.6914 5.51043 17.6787 5.48387 17.6607 5.45713C17.6426 5.43041 17.6229 5.40868 17.6048 5.39317C17.596 5.38564 17.5886 5.3804 17.5831 5.37698C17.5815 5.37596 17.5801 5.37515 17.5789 5.37451C17.5762 5.37298 17.5748 5.37243 17.5749 5.37247L10.0808 2.37484C10.0784 2.37427 10.0736 2.37319 10.066 2.37199C10.0485 2.36922 10.0251 2.36719 9.9987 2.36719C9.97229 2.36719 9.9489 2.36922 9.93139 2.37199C9.92382 2.37319 9.91897 2.37427 9.91661 2.37484ZM9.9987 1.11719C10.1602 1.11719 10.3581 1.13948 10.5391 1.21189L18.0391 4.21187C18.325 4.3262 18.548 4.5372 18.6972 4.75844C18.8461 4.97939 18.957 5.26351 18.957 5.56716V8.33383C18.957 9.13734 18.3022 9.79217 17.4987 9.79217H2.4987C1.69519 9.79217 1.04036 9.13734 1.04036 8.33383V5.56716C1.04036 5.26351 1.1513 4.97939 1.30024 4.75844C1.44938 4.5372 1.67241 4.3262 1.95825 4.21187L9.45825 1.21189C9.63927 1.13948 9.83723 1.11719 9.9987 1.11719Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4987 15.6257C2.38554 15.6257 2.29036 15.7208 2.29036 15.834V17.709H17.707V15.834C17.707 15.7208 17.6119 15.6257 17.4987 15.6257H2.4987ZM1.04036 15.834C1.04036 15.0305 1.69519 14.3757 2.4987 14.3757H17.4987C18.3022 14.3757 18.957 15.0305 18.957 15.834V18.334C18.957 18.6792 18.6772 18.959 18.332 18.959H1.66536C1.32019 18.959 1.04036 18.6792 1.04036 18.334V15.834Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33203 8.54232C3.67721 8.54232 3.95703 8.82214 3.95703 9.16732V15.0007C3.95703 15.3458 3.67721 15.6257 3.33203 15.6257C2.98685 15.6257 2.70703 15.3458 2.70703 15.0007V9.16732C2.70703 8.82214 2.98685 8.54232 3.33203 8.54232Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66537 8.54232C7.01054 8.54232 7.29037 8.82214 7.29037 9.16732V15.0007C7.29037 15.3458 7.01054 15.6257 6.66537 15.6257C6.32019 15.6257 6.04037 15.3458 6.04037 15.0007V9.16732C6.04037 8.82214 6.32019 8.54232 6.66537 8.54232Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9987 8.54232C10.3439 8.54232 10.6237 8.82214 10.6237 9.16732V15.0007C10.6237 15.3458 10.3439 15.6257 9.9987 15.6257C9.65352 15.6257 9.3737 15.3458 9.3737 15.0007V9.16732C9.3737 8.82214 9.65352 8.54232 9.9987 8.54232Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.332 8.54232C13.6772 8.54232 13.957 8.82214 13.957 9.16732V15.0007C13.957 15.3458 13.6772 15.6257 13.332 15.6257C12.9869 15.6257 12.707 15.3458 12.707 15.0007V9.16732C12.707 8.82214 12.9869 8.54232 13.332 8.54232Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6654 8.54232C17.0105 8.54232 17.2904 8.82214 17.2904 9.16732V15.0007C17.2904 15.3458 17.0105 15.6257 16.6654 15.6257C16.3202 15.6257 16.0404 15.3458 16.0404 15.0007V9.16732C16.0404 8.82214 16.3202 8.54232 16.6654 8.54232Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.207031 18.334C0.207031 17.9888 0.486853 17.709 0.832031 17.709H19.1654C19.5105 17.709 19.7904 17.9888 19.7904 18.334C19.7904 18.6792 19.5105 18.959 19.1654 18.959H0.832031C0.486853 18.959 0.207031 18.6792 0.207031 18.334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9987 5.20898C9.65352 5.20898 9.3737 5.48881 9.3737 5.83398C9.3737 6.17916 9.65352 6.45898 9.9987 6.45898C10.3439 6.45898 10.6237 6.17916 10.6237 5.83398C10.6237 5.48881 10.3439 5.20898 9.9987 5.20898ZM8.1237 5.83398C8.1237 4.79845 8.96316 3.95898 9.9987 3.95898C11.0342 3.95898 11.8737 4.79845 11.8737 5.83398C11.8737 6.86952 11.0342 7.70898 9.9987 7.70898C8.96316 7.70898 8.1237 6.86952 8.1237 5.83398Z"
      fill="currentColor"
    />
  </Icon>
)
