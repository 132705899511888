import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ManagedUsersIcon = ({...props}: IconProps) => (
  <Icon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6.25C10 7.63071 8.88071 8.75 7.5 8.75C6.11929 8.75 5 7.63071 5 6.25C5 4.86929 6.11929 3.75 7.5 3.75C8.88071 3.75 10 4.86929 10 6.25ZM7.5 10C9.57107 10 11.25 8.32107 11.25 6.25C11.25 4.17893 9.57107 2.5 7.5 2.5C5.42893 2.5 3.75 4.17893 3.75 6.25C3.75 8.32107 5.42893 10 7.5 10ZM15 16.25C15 17.5 13.75 17.5 13.75 17.5H1.25C1.25 17.5 0 17.5 0 16.25C0 15 1.25 11.25 7.5 11.25C13.75 11.25 15 15 15 16.25ZM13.75 16.2456C13.7482 15.9372 13.5578 15.0131 12.7099 14.1651C11.8945 13.3498 10.3614 12.5 7.49999 12.5C4.6386 12.5 3.10544 13.3498 2.29012 14.1651C1.44219 15.0131 1.25178 15.9372 1.25 16.2456H13.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5267 6.39389C17.3719 5.8687 16.6281 5.8687 16.4733 6.39389L16.4357 6.52126C16.3368 6.85684 15.9535 7.0156 15.6463 6.84825L15.5297 6.78474C15.0488 6.52283 14.5228 7.04885 14.7847 7.52967L14.8483 7.64628C15.0156 7.95351 14.8568 8.3368 14.5213 8.43571L14.3939 8.47325C13.8687 8.62805 13.8687 9.37195 14.3939 9.52675L14.5213 9.56429C14.8568 9.6632 15.0156 10.0465 14.8483 10.3537L14.7847 10.4703C14.5228 10.9512 15.0488 11.4772 15.5297 11.2153L15.6463 11.1517C15.9535 10.9844 16.3368 11.1432 16.4357 11.4787L16.4733 11.6061C16.6281 12.1313 17.3719 12.1313 17.5267 11.6061L17.5643 11.4787C17.6632 11.1432 18.0465 10.9844 18.3537 11.1517L18.4703 11.2153C18.9512 11.4772 19.4772 10.9512 19.2153 10.4703L19.1517 10.3537C18.9844 10.0465 19.1432 9.6632 19.4787 9.56429L19.6061 9.52675C20.1313 9.37195 20.1313 8.62805 19.6061 8.47325L19.4787 8.43571C19.1432 8.3368 18.9844 7.95351 19.1517 7.64628L19.2153 7.52967C19.4772 7.04885 18.9512 6.52283 18.4703 6.78474L18.3537 6.84825C18.0465 7.0156 17.6632 6.85684 17.5643 6.52126L17.5267 6.39389ZM17 10.0983C17.6066 10.0983 18.0983 9.60658 18.0983 9C18.0983 8.39342 17.6066 7.9017 17 7.9017C16.3934 7.9017 15.9017 8.39342 15.9017 9C15.9017 9.60658 16.3934 10.0983 17 10.0983Z"
      fill="currentColor"
    />
  </Icon>
)
