import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const SkillAvatar = (props: IconProps) => (
  <Icon viewBox="0 0 36 36" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.332 12.268L11.6142 12L13.8713 13.3402C14.0938 13.4911 14.8588 13.8964 14.8588 14.156V14.2133C14.8588 14.4265 14.948 14.6311 15.1067 14.7819L19.9374 19.371L20.7108 19.1261C21.0149 19.0298 21.3502 19.105 21.5769 19.3203L24.4175 22.0188C24.748 22.3329 24.748 22.842 24.4175 23.156L23.0752 24.4311C22.7446 24.7452 22.2087 24.7452 21.8782 24.4311L19.0377 21.7327C18.811 21.5173 18.7318 21.1987 18.8332 20.9098L19.091 20.1751L14.2603 15.586C14.1016 15.4352 13.8863 15.3504 13.6618 15.3504H13.6015C13.3282 15.3504 12.9016 14.6236 12.7427 14.4123L11.332 12.268ZM20.9249 19.773C20.7108 19.6412 20.0956 19.7568 19.7963 20.0411C19.2742 20.5371 19.232 20.8452 19.5142 21.1132L22.3356 23.7936C22.5009 23.9506 23.0339 23.4308 23.1992 23.2738C23.3644 23.1168 23.9116 22.6104 23.7463 22.4534L20.9249 19.773Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5833 13.1858C24.6385 13.3923 24.668 13.6094 24.668 13.8333C24.3902 15.5 23.5487 16.3333 22.168 16.3333C21.9429 16.3333 21.3346 16.3333 20.7791 16.0555L15.7791 20.7233C16.3346 21.3333 16.3346 21.9203 16.3346 22.1666C16.3346 23.5474 15.2153 24.6666 13.8346 24.6666C12.4539 24.6666 11.3346 23.5474 11.3346 22.1666C11.3346 20.7859 12.4539 19.6666 13.8346 19.6666C14.081 19.6666 14.668 19.6666 15.2235 20.2222L20.2235 15.5C19.668 14.9444 19.668 14.0584 19.668 13.8333C19.668 12.4526 20.7791 11.6111 22.168 11.3333C22.3919 11.3333 22.609 11.3628 22.8155 11.418L20.5013 13.5555L21.0569 15.2222L22.4457 15.5L24.5833 13.1858ZM13.4421 20.7016L13.8346 20.5L14.2272 20.7016L14.668 20.7233L14.9071 21.0942L15.278 21.3333L15.2996 21.7741L15.5013 22.1666L15.2996 22.5592L15.278 23L14.9071 23.2391L14.668 23.61L14.2272 23.6316L13.8346 23.8333L13.4421 23.6316L13.0013 23.61L12.7622 23.2391L12.3913 23L12.3696 22.5592L12.168 22.1666L12.3696 21.7741L12.3913 21.3333L12.7622 21.0942L13.0013 20.7233L13.4421 20.7016Z"
      fill="currentColor"
    />
  </Icon>
)
