export const STATES = [
  {name: 'Alberta', country: 'Canada'},
  {name: 'British Columbia', country: 'Canada'},
  {name: 'Manitoba', country: 'Canada'},
  {name: 'New Brunswick', country: 'Canada'},
  {name: 'Newfoundland and Labrador', country: 'Canada'},
  {name: 'Nova Scotia', country: 'Canada'},
  {name: 'Ontario', country: 'Canada'},
  {name: 'Prince Edward Island', country: 'Canada'},
  {name: 'Quebec', country: 'Canada'},
  {name: 'Saskatchewan', country: 'Canada'},
  {name: 'Northwest Territories', country: 'Canada'},
  {name: 'Nunavut', country: 'Canada'},
  {name: 'Yukon', country: 'Canada'},
  {name: 'Beijing', country: 'China'},
  {name: 'Tianjin', country: 'China'},
  {name: 'Hebei', country: 'China'},
  {name: 'Shanxi', country: 'China'},
  {name: 'Inner Mongolia', country: 'China'},
  {name: 'Liaoning', country: 'China'},
  {name: 'Jilin', country: 'China'},
  {name: 'Heilongjiang', country: 'China'},
  {name: 'Shanghai', country: 'China'},
  {name: 'Jiangsu', country: 'China'},
  {name: 'Zhejiang', country: 'China'},
  {name: 'Anhui', country: 'China'},
  {name: 'Fujian', country: 'China'},
  {name: 'Jiangxi', country: 'China'},
  {name: 'Shandong', country: 'China'},
  {name: 'Henan', country: 'China'},
  {name: 'Hubei', country: 'China'},
  {name: 'Hunan', country: 'China'},
  {name: 'Guangdong', country: 'China'},
  {name: 'Guangxi', country: 'China'},
  {name: 'Hainan', country: 'China'},
  {name: 'Chongqing', country: 'China'},
  {name: 'Sichuan', country: 'China'},
  {name: 'Guizhou', country: 'China'},
  {name: 'Yunnan', country: 'China'},
  {name: 'Tibet', country: 'China'},
  {name: 'Shaanxi', country: 'China'},
  {name: 'Gansu', country: 'China'},
  {name: 'Qinghai', country: 'China'},
  {name: 'Ningxia', country: 'China'},
  {name: 'Xinjiang', country: 'China'},
  {name: 'Hong Kong', country: 'China'},
  {name: 'Macau', country: 'China'},
  {name: 'Blekinge County', country: 'Sweden'},
  {name: 'Dalarna County', country: 'Sweden'},
  {name: 'Gotland County', country: 'Sweden'},
  {name: 'Gävleborg County', country: 'Sweden'},
  {name: 'Halland County', country: 'Sweden'},
  {name: 'Jämtland County', country: 'Sweden'},
  {name: 'Jönköping County', country: 'Sweden'},
  {name: 'Kalmar County', country: 'Sweden'},
  {name: 'Kronoberg County', country: 'Sweden'},
  {name: 'Norrbotten County', country: 'Sweden'},
  {name: 'Örebro County', country: 'Sweden'},
  {name: 'Östergötland County', country: 'Sweden'},
  {name: 'Skåne County', country: 'Sweden'},
  {name: 'Södermanland County', country: 'Sweden'},
  {name: 'Stockholm County', country: 'Sweden'},
  {name: 'Uppsala County', country: 'Sweden'},
  {name: 'Värmland County', country: 'Sweden'},
  {name: 'Västerbotten County', country: 'Sweden'},
  {name: 'Västernorrland County', country: 'Sweden'},
  {name: 'Västmanland County', country: 'Sweden'},
  {name: 'Västra Götaland County', country: 'Sweden'},
  {name: 'East Midlands', country: 'United Kingdom'},
  {name: 'East of England', country: 'United Kingdom'},
  {name: 'London', country: 'United Kingdom'},
  {name: 'North East', country: 'United Kingdom'},
  {name: 'North West', country: 'United Kingdom'},
  {name: 'South East', country: 'United Kingdom'},
  {name: 'South West', country: 'United Kingdom'},
  {name: 'West Midlands', country: 'United Kingdom'},
  {name: 'Yorkshire and the Humber', country: 'United Kingdom'},
  {name: 'Aberdeen City', country: 'United Kingdom'},
  {name: 'Aberdeenshire', country: 'United Kingdom'},
  {name: 'Angus', country: 'United Kingdom'},
  {name: 'Argyll and Bute', country: 'United Kingdom'},
  {name: 'Clackmannanshire', country: 'United Kingdom'},
  {name: 'Dumfries and Galloway', country: 'United Kingdom'},
  {name: 'Dundee City', country: 'United Kingdom'},
  {name: 'East Ayrshire', country: 'United Kingdom'},
  {name: 'East Dunbartonshire', country: 'United Kingdom'},
  {name: 'East Lothian', country: 'United Kingdom'},
  {name: 'East Renfrewshire', country: 'United Kingdom'},
  {name: 'Edinburgh', country: 'United Kingdom'},
  {name: 'Eilean Siar', country: 'United Kingdom'},
  {name: 'Falkirk', country: 'United Kingdom'},
  {name: 'Fife', country: 'United Kingdom'},
  {name: 'Glasgow City', country: 'United Kingdom'},
  {name: 'Highland', country: 'United Kingdom'},
  {name: 'Inverclyde', country: 'United Kingdom'},
  {name: 'Midlothian', country: 'United Kingdom'},
  {name: 'Moray', country: 'United Kingdom'},
  {name: 'North Ayrshire', country: 'United Kingdom'},
  {name: 'North Lanarkshire', country: 'United Kingdom'},
  {name: 'Orkney Islands', country: 'United Kingdom'},
  {name: 'Perth and Kinross', country: 'United Kingdom'},
  {name: 'Renfrewshire', country: 'United Kingdom'},
  {name: 'Scottish Borders', country: 'United Kingdom'},
  {name: 'Shetland Islands', country: 'United Kingdom'},
  {name: 'South Ayrshire', country: 'United Kingdom'},
  {name: 'South Lanarkshire', country: 'United Kingdom'},
  {name: 'Stirling', country: 'United Kingdom'},
  {name: 'West Dunbartonshire', country: 'United Kingdom'},
  {name: 'West Lothian', country: 'United Kingdom'},
  {name: 'Blaenau Gwent', country: 'United Kingdom'},
  {name: 'Bridgend', country: 'United Kingdom'},
  {name: 'Caerphilly', country: 'United Kingdom'},
  {name: 'Cardiff', country: 'United Kingdom'},
  {name: 'Carmarthenshire', country: 'United Kingdom'},
  {name: 'Ceredigion', country: 'United Kingdom'},
  {name: 'Conwy', country: 'United Kingdom'},
  {name: 'Denbighshire', country: 'United Kingdom'},
  {name: 'Flintshire', country: 'United Kingdom'},
  {name: 'Gwynedd', country: 'United Kingdom'},
  {name: 'Isle of Anglesey', country: 'United Kingdom'},
  {name: 'Merthyr Tydfil', country: 'United Kingdom'},
  {name: 'Monmouthshire', country: 'United Kingdom'},
  {name: 'Neath Port Talbot', country: 'United Kingdom'},
  {name: 'Newport', country: 'United Kingdom'},
  {name: 'Pembrokeshire', country: 'United Kingdom'},
  {name: 'Powys', country: 'United Kingdom'},
  {name: 'Rhondda Cynon Taf', country: 'United Kingdom'},
  {name: 'Swansea', country: 'United Kingdom'},
  {name: 'Torfaen', country: 'United Kingdom'},
  {name: 'Vale of Glamorgan', country: 'United Kingdom'},
  {name: 'Wrexham', country: 'United Kingdom'},
  {name: 'Antrim and Newtownabbey', country: 'United Kingdom'},
  {name: 'Armagh City, Banbridge and Craigavon', country: 'United Kingdom'},
  {name: 'Belfast', country: 'United Kingdom'},
  {name: 'Causeway Coast and Glens', country: 'United Kingdom'},
  {name: 'Derry and Strabane', country: 'United Kingdom'},
  {name: 'Fermanagh and Omagh', country: 'United Kingdom'},
  {name: 'Lisburn and Castlereagh', country: 'United Kingdom'},
  {name: 'Mid and East Antrim', country: 'United Kingdom'},
  {name: 'Mid Ulster', country: 'United Kingdom'},
  {name: 'Newry, Mourne and Down', country: 'United Kingdom'},
  {name: 'Alabama', country: 'United States'},
  {name: 'Alaska', country: 'United States'},
  {name: 'Arizona', country: 'United States'},
  {name: 'Arkansas', country: 'United States'},
  {name: 'California', country: 'United States'},
  {name: 'Colorado', country: 'United States'},
  {name: 'Connecticut', country: 'United States'},
  {name: 'Delaware', country: 'United States'},
  {name: 'Florida', country: 'United States'},
  {name: 'Georgia', country: 'United States'},
  {name: 'Hawaii', country: 'United States'},
  {name: 'Idaho', country: 'United States'},
  {name: 'Illinois', country: 'United States'},
  {name: 'Indiana', country: 'United States'},
  {name: 'Iowa', country: 'United States'},
  {name: 'Kansas', country: 'United States'},
  {name: 'Kentucky', country: 'United States'},
  {name: 'Louisiana', country: 'United States'},
  {name: 'Maine', country: 'United States'},
  {name: 'Maryland', country: 'United States'},
  {name: 'Massachusetts', country: 'United States'},
  {name: 'Michigan', country: 'United States'},
  {name: 'Minnesota', country: 'United States'},
  {name: 'Mississippi', country: 'United States'},
  {name: 'Missouri', country: 'United States'},
  {name: 'Montana', country: 'United States'},
  {name: 'Nebraska', country: 'United States'},
  {name: 'Nevada', country: 'United States'},
  {name: 'New Hampshire', country: 'United States'},
  {name: 'New Jersey', country: 'United States'},
  {name: 'New Mexico', country: 'United States'},
  {name: 'New York', country: 'United States'},
  {name: 'North Carolina', country: 'United States'},
  {name: 'North Dakota', country: 'United States'},
  {name: 'Ohio', country: 'United States'},
  {name: 'Oklahoma', country: 'United States'},
  {name: 'Oregon', country: 'United States'},
  {name: 'Pennsylvania', country: 'United States'},
  {name: 'Rhode Island', country: 'United States'},
  {name: 'South Carolina', country: 'United States'},
  {name: 'South Dakota', country: 'United States'},
  {name: 'Tennessee', country: 'United States'},
  {name: 'Texas', country: 'United States'},
  {name: 'Utah', country: 'United States'},
  {name: 'Vermont', country: 'United States'},
  {name: 'Virginia', country: 'United States'},
  {name: 'Washington', country: 'United States'},
  {name: 'West Virginia', country: 'United States'},
  {name: 'Wisconsin', country: 'United States'},
  {name: 'Wyoming', country: 'United States'},
  {name: 'Andijan', country: 'Uzbekistan'},
  {name: 'Bukhara', country: 'Uzbekistan'},
  {name: 'Fergana', country: 'Uzbekistan'},
  {name: 'Jizzakh', country: 'Uzbekistan'},
  {name: 'Karakalpakstan', country: 'Uzbekistan'},
  {name: 'Namangan', country: 'Uzbekistan'},
  {name: 'Navoiy', country: 'Uzbekistan'},
  {name: 'Qashqadaryo', country: 'Uzbekistan'},
  {name: 'Samarqand', country: 'Uzbekistan'},
  {name: 'Sirdaryo', country: 'Uzbekistan'},
  {name: 'Surxondaryo', country: 'Uzbekistan'},
  {name: 'Tashkent City', country: 'Uzbekistan'},
  {name: 'Tashkent Region', country: 'Uzbekistan'},
  {name: 'Xorazm', country: 'Uzbekistan'},
]

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    shortName: 'af',
    code: '+93',
  },
  {
    name: 'Albania',
    shortName: 'al',
    code: '+355',
  },
  {
    name: 'Algeria',
    shortName: 'dz',
    code: '+213',
  },
  {
    name: 'Andorra',
    shortName: 'ad',
    code: '+376',
  },
  {
    name: 'Angola',
    shortName: 'ao',
    code: '+244',
  },
  {
    name: 'Antigua and Barbuda',
    shortName: 'ag',
    code: '+1268',
  },
  {
    name: 'Argentina',
    shortName: 'ar',
    code: '+54',
  },
  {
    name: 'Armenia',
    shortName: 'am',
    code: '+374',
  },
  {
    name: 'Australia',
    shortName: 'au',
    code: '+61',
  },
  {
    name: 'Austria',
    shortName: 'at',
    code: '+43',
  },
  {
    name: 'Azerbaijan',
    shortName: 'az',
    code: '+994',
  },
  {
    name: 'Bahamas',
    shortName: 'bs',
    code: '+1242',
  },
  {
    name: 'Bahrain',
    shortName: 'bh',
    code: '+973',
  },
  {
    name: 'Bangladesh',
    shortName: 'bd',
    code: '+880',
  },
  {
    name: 'Barbados',
    shortName: 'bb',
    code: '+1246',
  },
  {
    name: 'Belarus',
    shortName: 'by',
    code: '+375',
  },
  {
    name: 'Belgium',
    shortName: 'be',
    code: '+32',
  },
  {
    name: 'Belize',
    shortName: 'bz',
    code: '+501',
  },
  {
    name: 'Benin',
    shortName: 'bj',
    code: '+229',
  },
  {
    name: 'Bhutan',
    shortName: 'bt',
    code: '+975',
  },
  {
    name: 'Bolivia',
    shortName: 'bo',
    code: '+591',
  },
  {
    name: 'Bosnia and Herzegovina',
    shortName: 'ba',
    code: '+387',
  },
  {
    name: 'Botswana',
    shortName: 'bw',
    code: '+267',
  },
  {
    name: 'Brazil',
    shortName: 'br',
    code: '+55',
  },
  {
    name: 'Brunei',
    shortName: 'bn',
    code: '+673',
  },
  {
    name: 'Bulgaria',
    shortName: 'bg',
    code: '+359',
  },
  {
    name: 'Burkina Faso',
    shortName: 'bf',
    code: '+226',
  },
  {
    name: 'Burundi',
    shortName: 'bi',
    code: '+257',
  },
  {
    name: 'Cabo Verde',
    shortName: 'cv',
    code: '+238',
  },
  {
    name: 'Cambodia',
    shortName: 'kh',
    code: '+855',
  },
  {
    name: 'Cameroon',
    shortName: 'cm',
    code: '+237',
  },
  {
    name: 'Canada',
    shortName: 'ca',
    code: '+1',
  },
  {
    name: 'Central African Republic',
    shortName: 'cf',
    code: '+236',
  },
  {
    name: 'Chad',
    shortName: 'td',
    code: '+235',
  },
  {
    name: 'Chile',
    shortName: 'cl',
    code: '+56',
  },
  {
    name: 'China',
    shortName: 'cn',
    code: '+86',
  },
  {
    name: 'Colombia',
    shortName: 'co',
    code: '+57',
  },
  {
    name: 'Comoros',
    shortName: 'km',
    code: '+269',
  },
  {
    name: 'Congo',
    shortName: 'cg',
    code: '+242',
  },
  {
    name: 'Costa Rica',
    shortName: 'cr',
    code: '+506',
  },
  {
    name: 'Croatia',
    shortName: 'hr',
    code: '+385',
  },
  {
    name: 'Cuba',
    shortName: 'cu',
    code: '+53',
  },
  {
    name: 'Cyprus',
    shortName: 'cy',
    code: '+357',
  },
  {
    name: 'Czechia',
    shortName: 'cz',
    code: '+420',
  },
  {
    name: 'Denmark',
    shortName: 'dk',
    code: '+45',
  },
  {
    name: 'Djibouti',
    shortName: 'dj',
    code: '+253',
  },
  {
    name: 'Dominica',
    shortName: 'dm',
    code: '+1767',
  },
  {
    name: 'Dominican Republic',
    shortName: 'do',
    code: '+1809',
  },
  {
    name: 'Ecuador',
    shortName: 'ec',
    code: '+593',
  },
  {
    name: 'Egypt',
    shortName: 'eg',
    code: '+20',
  },
  {
    name: 'El Salvador',
    shortName: 'sv',
    code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    shortName: 'gq',
    code: '+240',
  },
  {
    name: 'Eritrea',
    shortName: 'er',
    code: '+291',
  },
  {
    name: 'Estonia',
    shortName: 'ee',
    code: '+372',
  },
  {
    name: 'Eswatini',
    shortName: 'sz',
    code: '+268',
  },
  {
    name: 'Ethiopia',
    shortName: 'et',
    code: '+251',
  },
  {
    name: 'Fiji',
    shortName: 'fj',
    code: '+679',
  },
  {
    name: 'Finland',
    shortName: 'fi',
    code: '+358',
  },
  {
    name: 'France',
    shortName: 'fr',
    code: '+33',
  },
  {
    name: 'Gabon',
    shortName: 'ga',
    code: '+241',
  },
  {
    name: 'Gambia',
    shortName: 'gm',
    code: '+220',
  },
  {
    name: 'Georgia',
    shortName: 'ge',
    code: '+995',
  },
  {
    name: 'Germany',
    shortName: 'de',
    code: '+49',
  },
  {
    name: 'Ghana',
    shortName: 'gh',
    code: '+233',
  },
  {
    name: 'Greece',
    shortName: 'gr',
    code: '+30',
  },
  {
    name: 'Grenada',
    shortName: 'gd',
    code: '+1473',
  },
  {
    name: 'Guatemala',
    shortName: 'gt',
    code: '+502',
  },
  {
    name: 'Guinea',
    shortName: 'gn',
    code: '+224',
  },
  {
    name: 'Guinea-Bissau',
    shortName: 'gw',
    code: '+245',
  },
  {
    name: 'Guyana',
    shortName: 'gy',
    code: '+592',
  },
  {
    name: 'Haiti',
    shortName: 'ht',
    code: '+509',
  },
  {
    name: 'Honduras',
    shortName: 'hn',
    code: '+504',
  },
  {
    name: 'Hungary',
    shortName: 'hu',
    code: '+36',
  },
  {
    name: 'Iceland',
    shortName: 'is',
    code: '+354',
  },
  {
    name: 'India',
    shortName: 'in',
    code: '+91',
  },
  {
    name: 'Indonesia',
    shortName: 'id',
    code: '+62',
  },
  {
    name: 'Iran',
    shortName: 'ir',
    code: '+98',
  },
  {
    name: 'Iraq',
    shortName: 'iq',
    code: '+964',
  },
  {
    name: 'Ireland',
    shortName: 'ie',
    code: '+353',
  },
  {
    name: 'Israel',
    shortName: 'il',
    code: '+972',
  },
  {
    name: 'Italy',
    shortName: 'it',
    code: '+39',
  },
  {
    name: 'Jamaica',
    shortName: 'jm',
    code: '+1-876',
  },
  {
    name: 'Japan',
    shortName: 'jp',
    code: '+81',
  },
  {
    name: 'Jordan',
    shortName: 'jo',
    code: '+962',
  },
  {
    name: 'Kazakhstan',
    shortName: 'kz',
    code: '+7',
  },
  {
    name: 'Kenya',
    shortName: 'ke',
    code: '+254',
  },
  {
    name: 'Kiribati',
    shortName: 'ki',
    code: '+686',
  },
  {
    name: 'Korea, North',
    shortName: 'kp',
    code: '+850',
  },
  {
    name: 'Korea, South',
    shortName: 'kr',
    code: '+82',
  },
  {
    name: 'Kosovo',
    shortName: 'xk',
    code: '+383',
  },
  {
    name: 'Kuwait',
    shortName: 'kw',
    code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    shortName: 'kg',
    code: '+996',
  },
  {
    name: 'Laos',
    shortName: 'la',
    code: '+856',
  },
  {
    name: 'Latvia',
    shortName: 'lv',
    code: '+371',
  },
  {
    name: 'Lebanon',
    shortName: 'lb',
    code: '+961',
  },
  {
    name: 'Lesotho',
    shortName: 'ls',
    code: '+266',
  },
  {
    name: 'Liberia',
    shortName: 'lr',
    code: '+231',
  },
  {
    name: 'Libya',
    shortName: 'ly',
    code: '+218',
  },
  {
    name: 'Liechtenstein',
    shortName: 'li',
    code: '+423',
  },
  {
    name: 'Lithuania',
    shortName: 'lt',
    code: '+370',
  },
  {
    name: 'Luxembourg',
    shortName: 'lu',
    code: '+352',
  },
  {
    name: 'Madagascar',
    shortName: 'mg',
    code: '+261',
  },
  {
    name: 'Malawi',
    shortName: 'mw',
    code: '+265',
  },
  {
    name: 'Malaysia',
    shortName: 'my',
    code: '+60',
  },
  {
    name: 'Maldives',
    shortName: 'mv',
    code: '+960',
  },
  {
    name: 'Mali',
    shortName: 'ml',
    code: '+223',
  },
  {
    name: 'Malta',
    shortName: 'mt',
    code: '+356',
  },
  {
    name: 'Marshall Islands',
    shortName: 'mh',
    code: '+692',
  },
  {
    name: 'Mauritania',
    shortName: 'mr',
    code: '+222',
  },
  {
    name: 'Mauritius',
    shortName: 'mu',
    code: '+230',
  },
  {
    name: 'Mexico',
    shortName: 'mx',
    code: '+52',
  },
  {
    name: 'Micronesia',
    shortName: 'fm',
    code: '+691',
  },
  {
    name: 'Moldova',
    shortName: 'md',
    code: '+373',
  },
  {
    name: 'Monaco',
    shortName: 'mc',
    code: '+377',
  },
  {
    name: 'Mongolia',
    shortName: 'mn',
    code: '+976',
  },
  {
    name: 'Montenegro',
    shortName: 'me',
    code: '+382',
  },
  {
    name: 'Morocco',
    shortName: 'ma',
    code: '+212',
  },
  {
    name: 'Mozambique',
    shortName: 'mz',
    code: '+258',
  },
  {
    name: 'Myanmar',
    shortName: 'mm',
    code: '+95',
  },
  {
    name: 'Namibia',
    shortName: 'na',
    code: '+264',
  },
  {
    name: 'Nauru',
    shortName: 'nr',
    code: '+674',
  },
  {
    name: 'Nepal',
    shortName: 'np',
    code: '+977',
  },
  {
    name: 'Netherlands',
    shortName: 'nl',
    code: '+31',
  },
  {
    name: 'New Zealand',
    shortName: 'nz',
    code: '+64',
  },
  {
    name: 'Nicaragua',
    shortName: 'ni',
    code: '+505',
  },
  {
    name: 'Niger',
    shortName: 'ne',
    code: '+227',
  },
  {
    name: 'Nigeria',
    shortName: 'ng',
    code: '+234',
  },
  {
    name: 'North Macedonia',
    shortName: 'mk',
    code: '+389',
  },
  {
    name: 'Norway',
    shortName: 'no',
    code: '+47',
  },
  {
    name: 'Oman',
    shortName: 'om',
    code: '+968',
  },
  {
    name: 'Pakistan',
    shortName: 'pk',
    code: '+92',
  },
  {
    name: 'Palau',
    shortName: 'pw',
    code: '+680',
  },
  {
    name: 'Panama',
    shortName: 'pa',
    code: '+507',
  },
  {
    name: 'Papua New Guinea',
    shortName: 'pg',
    code: '+675',
  },
  {
    name: 'Paraguay',
    shortName: 'py',
    code: '+595',
  },
  {
    name: 'Peru',
    shortName: 'pe',
    code: '+51',
  },
  {
    name: 'Philippines',
    shortName: 'ph',
    code: '+63',
  },
  {
    name: 'Poland',
    shortName: 'pl',
    code: '+48',
  },
  {
    name: 'Portugal',
    shortName: 'pt',
    code: '+351',
  },
  {
    name: 'Qatar',
    shortName: 'qa',
    code: '+974',
  },
  {
    name: 'Romania',
    shortName: 'ro',
    code: '+40',
  },
  {
    name: 'Russia',
    shortName: 'ru',
    code: '+7',
  },
  {
    name: 'Rwanda',
    shortName: 'rw',
    code: '+250',
  },
  {
    name: 'Saint Kitts and Nevis',
    shortName: 'kn',
    code: '+1869',
  },
  {
    name: 'Saint Lucia',
    shortName: 'lc',
    code: '+1758',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    shortName: 'vc',
    code: '+1784',
  },
  {
    name: 'Samoa',
    shortName: 'ws',
    code: '+685',
  },
  {
    name: 'San Marino',
    shortName: 'sm',
    code: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    shortName: 'st',
    code: '+239',
  },
  {
    name: 'Saudi Arabia',
    shortName: 'sa',
    code: '+966',
  },
  {
    name: 'Senegal',
    shortName: 'sn',
    code: '+221',
  },
  {
    name: 'Serbia',
    shortName: 'rs',
    code: '+381',
  },
  {
    name: 'Seychelles',
    shortName: 'sc',
    code: '+248',
  },
  {
    name: 'Sierra Leone',
    shortName: 'sl',
    code: '+232',
  },
  {
    name: 'Singapore',
    shortName: 'sg',
    code: '+65',
  },
  {
    name: 'Slovakia',
    shortName: 'sk',
    code: '+421',
  },
  {
    name: 'Slovenia',
    shortName: 'si',
    code: '+386',
  },
  {
    name: 'Solomon Islands',
    shortName: 'sb',
    code: '+677',
  },
  {
    name: 'Somalia',
    shortName: 'so',
    code: '+252',
  },
  {
    name: 'South Africa',
    shortName: 'za',
    code: '+27',
  },
  {
    name: 'South Sudan',
    shortName: 'ss',
    code: '+211',
  },
  {
    name: 'Spain',
    shortName: 'es',
    code: '+34',
  },
  {
    name: 'Sri Lanka',
    shortName: 'lk',
    code: '+94',
  },
  {
    name: 'Sudan',
    shortName: 'sd',
    code: '+249',
  },
  {
    name: 'Suriname',
    shortName: 'sr',
    code: '+597',
  },
  {
    name: 'Sweden',
    shortName: 'se',
    code: '+46',
  },
  {
    name: 'Switzerland',
    shortName: 'ch',
    code: '+41',
  },
  {
    name: 'Syria',
    shortName: 'sy',
    code: '+963',
  },
  {
    name: 'Taiwan',
    shortName: 'tw',
    code: '+886',
  },
  {
    name: 'Tajikistan',
    shortName: 'tj',
    code: '+992',
  },
  {
    name: 'Tanzania',
    shortName: 'tz',
    code: '+255',
  },
  {
    name: 'Thailand',
    shortName: 'th',
    code: '+66',
  },
  {
    name: 'Timor-Leste',
    shortName: 'tl',
    code: '+670',
  },
  {
    name: 'Togo',
    shortName: 'tg',
    code: '+228',
  },
  {
    name: 'Tonga',
    shortName: 'to',
    code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    shortName: 'tt',
    code: '+1868',
  },
  {
    name: 'Tunisia',
    shortName: 'tn',
    code: '+216',
  },
  {
    name: 'Turkey',
    shortName: 'tr',
    code: '+90',
  },
  {
    name: 'Turkmenistan',
    shortName: 'tm',
    code: '+993',
  },
  {
    name: 'Tuvalu',
    shortName: 'tv',
    code: '+688',
  },
  {
    name: 'Uganda',
    shortName: 'ug',
    code: '+256',
  },
  {
    name: 'Ukraine',
    shortName: 'ua',
    code: '+380',
  },
  {
    name: 'United Arab Emirates',
    shortName: 'ae',
    code: '+971',
  },
  {
    name: 'United Kingdom',
    shortName: 'gb',
    code: '+44',
  },
  {
    name: 'United States',
    shortName: 'us',
    code: '+1',
  },
  {
    name: 'Uruguay',
    shortName: 'uy',
    code: '+598',
  },
  {
    name: 'Uzbekistan',
    shortName: 'uz',
    code: '+998',
  },
  {
    name: 'Vanuatu',
    shortName: 'vu',
    code: '+678',
  },
  {
    name: 'Vatican City',
    shortName: 'va',
    code: '+379',
  },
  {
    name: 'Venezuela',
    shortName: 've',
    code: '+58',
  },
  {
    name: 'Vietnam',
    shortName: 'vn',
    code: '+84',
  },
  {
    name: 'Yemen',
    shortName: 'ye',
    code: '+967',
  },
  {
    name: 'Zambia',
    shortName: 'zm',
    code: '+260',
  },
  {
    name: 'Zimbabwe',
    shortName: 'zw',
    code: '+263',
  },
]
