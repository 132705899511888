/* eslint-disable react-hooks/exhaustive-deps */
import {Suspense, useEffect, useMemo, useState} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {AppRouter} from './router'
import {PrivateRoutes} from './constants/private-routes'
import {PrivateLayout, PublicLayout} from './components/layout'
import {ErrorBoundary} from './components/layout/components'
import {Toaster} from './components/ui/toaster'
import {LayoutExcludedRoutes} from './constants/layout-excluded-routes'
import {useLazyGetUserQuery} from './features/auth'
import {useHandleRequest} from './hooks/use-handle-request'
import {AuthRoutes} from './constants/auth-routes'
import {GetUserResponse} from './features/auth/types'
import {useStorage} from './utils/storage'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {Loader} from './components/common'
import {ExamRoutes} from './constants/exam-routes'

export const App = () => {
  const [getUser, {data: user}] = useLazyGetUserQuery()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const handleRequest = useHandleRequest()
  const isLayoutExlcuded = useMemo(
    () => (Object.values(LayoutExcludedRoutes || {}) as string[]).includes(location.pathname),
    [location],
  )
  const isAuthPage = useMemo(
    () => (Object.values(AuthRoutes || {}) as string[]).includes(location.pathname),
    [location],
  )
  const isPrivatePage = useMemo(
    () => (Object.values(PrivateRoutes || {}) as string[]).some(route => location.pathname.includes(route)),
    [location],
  )
  const isExamPage = useMemo(() => location.pathname.startsWith(ExamRoutes.EXAMS), [location])

  const fetchUser = async () => {
    await handleRequest({
      request: async () => {
        const result = await getUser('')
        return result
      },
      onSuccess: (data: GetUserResponse) => {
        if (isAuthPage && data.success && data.data) {
          navigate('/dashboard')
        }
      },
      onError: (error): any => {
        useStorage.removeCredentials()

        if (error?.error?.msg === 'unauthorized' && !isPrivatePage) {
          return () => {}
        }
      },
    })
    setIsLoading(false)
  }

  useEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [location, window])

  useEffect(() => {
    const accessToken = useStorage.getTokens().accessToken?.split(' ')[1]

    if (!user && accessToken) {
      fetchUser()
    } else {
      setIsLoading(false)
    }
  }, [isAuthPage, isPrivatePage])

  useEffect(() => {
    document.body.style.overflowY = 'auto'
  }, [location])

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
    })
  }, [])

  const renderContent = () => (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <AppRouter />
        <Toaster />
      </ErrorBoundary>
    </Suspense>
  )

  if (isLoading) {
    if (!localStorage.getItem('language')) {
      fetch('https://freeipapi.com/api/json')
        .then(res => res.json())
        .then(({countryCode}: {countryCode: string}) => {
          if (countryCode === 'RU' || countryCode === 'UZ') {
            localStorage.setItem('language', countryCode.toLowerCase())
          } else {
            localStorage.setItem('language', 'en')
          }
        })
    }
    return ''
  }

  if (isPrivatePage && !(user?.success || user?.data)) {
    return <Navigate to="/auth/login" />
  }

  if (isLayoutExlcuded) {
    return renderContent()
  }

  if (isPrivatePage) {
    return <PrivateLayout>{renderContent()}</PrivateLayout>
  }

  if (isExamPage) {
    return <>{renderContent()}</>
  }

  return <PublicLayout>{renderContent()}</PublicLayout>
}
