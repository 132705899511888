/* eslint-disable no-constant-condition */
import {Dropdown, Input, Loader, Select} from '@/components/common'
import {ArrowUpIcon, MenuIcon, SearchIcon} from '@/icons'
import {forwardRef, useEffect, useMemo, useState} from 'react'
import {Account} from './components'
import {Props} from './types'
import {cn} from '@/lib/utils'
import {useLazyGetAdminDashboardUniversitiesQuery} from '@/features/university'
import {UniversityStatus} from '@/constants/statusues'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useUserRole} from '@/hooks/use-user-role'
import {Role} from '@/constants/roles'
import {useAppSelector} from '@/store/hooks'
import {useDispatch} from 'react-redux'
import {selectUniversity} from '@/store/slices/university'
import {GetAdminDashboardUniversitiesResponse} from '@/features/university/types'
import {baseApi} from '@/features'
import {RTKTagNames} from '@/constants/rtk-tag-names'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {DashboardSeaerchablePages} from '@/constants/dashboard-searchable-pages'
import {changeSearchQuery} from '@/store/slices/dashboard-search'
import {useQuery} from '@/hooks/use-query'
import {ChatScreen} from '@/constants/chat'
import {emitCompanyChats, emitUniversityChats, emitUserChats} from '@/services/socket'
import {GbIcon, RusIcon, UZIcon} from '@/icons/lang-icon'

export const Header = forwardRef<HTMLDivElement | null, Props>(({setMenu, menu}, ref) => {
  const {t, i18n} = useTranslation()
  const [getUniversities, {data: {data: universities = []} = {}, isLoading}] =
    useLazyGetAdminDashboardUniversitiesQuery()
  const languages: {[key: string]: any} = useMemo(
    () => ({
      en: {
        label: (
          <span className="flex items-center gap-x-2">
            <GbIcon className="rounded" /> <span>{t('home.navbar.langs.en')}</span>
          </span>
        ),
        shortLabel: (
          <button type="button" className=" w-full flex items-center gap-2 text-blue-1 duration-default cursor-pointer">
            <GbIcon />
            EN
          </button>
        ),
        onClick: () => handleChangeLanguage('en'),
      },
      ru: {
        label: (
          <span className="flex items-center gap-x-2">
            <RusIcon /> <span>{t('home.navbar.langs.ru')}</span>
          </span>
        ),
        shortLabel: (
          <>
            <button
              type="button"
              className="w-full flex items-center gap-2 text-blue-1 duration-default cursor-pointer"
            >
              <RusIcon className="text-xl" />
              RU
            </button>
          </>
        ),
        onClick: () => handleChangeLanguage('ru'),
      },
      uz: {
        label: (
          <span className="w-full flex items-center gap-x-2">
            <UZIcon /> <span>{t('home.navbar.langs.uz')}</span>
          </span>
        ),
        shortLabel: (
          <button type="button" className="flex items-center gap-2 text-blue-1 duration-default cursor-pointer">
            <UZIcon />
            UZ
          </button>
        ),
        onClick: () => handleChangeLanguage('uz'),
      },
    }),
    [i18n, t],
  )
  const userRole = useUserRole()
  const handleRequest = useHandleRequest()
  const {selectedUniversity} = useAppSelector(state => state.university)
  const {searchQuery} = useAppSelector(state => state.dashboardSearch)
  const [isSearch, setIsserach] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const query = useQuery()
  const showSearch = useMemo(
    () =>
      Object.values(DashboardSeaerchablePages).includes(location.pathname as DashboardSeaerchablePages) ||
      location.pathname.startsWith('/dashboard/vacancies/'),
    [location],
  )

  const fetchUniversities = async () => {
    await handleRequest({
      request: async () => {
        const result = await getUniversities({
          status: UniversityStatus.ACTIVE,
        })
        return result
      },
      onSuccess: (data: GetAdminDashboardUniversitiesResponse) => {
        dispatch(selectUniversity((data?.data as any)?.data?.[0]?._id as never))
      },
    })
  }

  const handleChangeLanguage = (language: string) => {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  useEffect(() => {
    if (userRole === Role.CONSULTING) {
      fetchUniversities()
    }
  }, [userRole])

  useEffect(() => {
    dispatch(changeSearchQuery('' as never))
  }, [location.pathname.split('/').slice(0, 3).join('/')])

  useEffect(() => {
    if (isSearch) {
      setIsserach(false)
    }
  }, [window.innerWidth, location.pathname])

  if (isLoading) {
    return (
      <div className="relative h-80">
        <Loader />
      </div>
    )
  }

  return (
    <header
      ref={ref}
      className={cn(
        'px-7 sm:px-4 border-b border-blue-4 w-full fixed top-0 left-0 z-40 h-[100px] bg-white',
        menu ? 'lg:pl-14' : 'lg:pl-56',
      )}
    >
      {isSearch ? (
        <>
          <div className="lg:hidden w-full h-full flex justify-center items-center">
            <div className="w-full !z-[99]  max-w-[340px]">
              <Input
                type="search"
                baseClassName="w-full  border-blue-4 bg-blue-7"
                value={searchQuery}
                onBlur={() => setIsserach(false)}
                onChange={event => dispatch(changeSearchQuery(event.target.value as never))}
              />
            </div>
          </div>
          {isSearch ? (
            <div
              onClick={() => setIsserach(false)}
              className=" bg-transparent fixed top-0 left-0 h-screen w-full z-[99]"
            ></div>
          ) : (
            ''
          )}
        </>
      ) : (
        <div className="flex justify-between items-center gap-10 w-full h-full sm:px-8 lg:px-16">
          <div className="flex items-center gap-8">
            <button onClick={() => setMenu((prev: any) => !prev)}>
              <MenuIcon className="w-8 sm:w-10 h-8 sm:h-10" />
            </button>
            {userRole === Role.CONSULTING && (
              <div>
                <Select
                  options={universities?.map(university => ({label: university.title, value: university._id})) || []}
                  className="w-[202px] line-clamp-1 text-sm h-10 max-h-[40px] min-h-[40px] py-0 px-3"
                  value={selectedUniversity}
                  onChange={university => {
                    dispatch(baseApi.util.invalidateTags([RTKTagNames.UNIVERSITY]))
                    dispatch(selectUniversity(university as never))
                    switch (query.get('chatTab')) {
                      case ChatScreen.APPLICANT:
                        emitUserChats()
                        break
                      case ChatScreen.UNIVERSITY:
                        emitUniversityChats()
                        break
                      case ChatScreen.COMPANY:
                        emitCompanyChats()
                        break
                    }
                  }}
                  placeholder="Select university"
                />
              </div>
            )}
          </div>
          {showSearch && (
            <>
              <div className="lg:inline-block hidden w-full max-w-[340px]">
                <Input
                  type="search"
                  baseClassName="w-full border-blue-4 bg-blue-7"
                  value={searchQuery}
                  onChange={event => dispatch(changeSearchQuery(event.target.value as never))}
                />
              </div>
              <button
                className="inline-block lg:hidden border border-blue-4 text-blue-4 p-3 hover:text-blue hover:border-blue rounded"
                aria-label="Search button"
                onClick={() => setIsserach(true)}
              >
                <SearchIcon className=" duration-default cursor-pointer " />
              </button>
            </>
          )}
          <div className="flex gap-x-6">
            <Dropdown
              buttons={Object.values(languages)}
              className="w-32 min-w-max"
              buttonClassName="justify-start w-full text-blue-2 text-base font-medium hover:text-blue duration-default"
            >
              <span className="text-blue-1 flex items-center gap-2 hover:text-blue duration-default cursor-pointer">
                {languages?.[i18n.resolvedLanguage as string]?.shortLabel}
                <ArrowUpIcon />
              </span>
            </Dropdown>
            <Account />
          </div>
        </div>
      )}
    </header>
  )
})
