/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import {BoldTabs, FormModal} from '@/components/common'
import {Props} from './types'
import {Overview} from './components/overview'
import {SubmitHandler, useForm} from 'react-hook-form'
import {Form} from '@/components/ui/form'
import {About} from './components/about'
import {Media} from './components/media'
import {Profile} from './components/profile'
import {ReactNode, useEffect} from 'react'
import {useCreateUniversityMutation} from '@/features/university'
import {CreateUniversityRequest} from '@/features/university/types'
import {useHandleRequest} from '@/hooks/use-handle-request'
import {useToast} from '@/components/ui/use-toast'
import {formatBackToShortNumber} from '@/utils/format-phone-number'
import {useUploadFiles} from '@/hooks/use-upload-files'
import {useTranslation} from 'react-i18next'
import {useValidationOptions} from '@/hooks/use-validation-options'

export const CreateForm = ({close, open}: Props) => {
  const {t} = useTranslation()
  const [createUniversity, {isLoading: isCreating}] = useCreateUniversityMutation()
  const [uploadFiles, isUploadingFiles] = useUploadFiles()
  const form = useForm<CreateUniversityRequest>()
  const handleRequest = useHandleRequest()
  const getValidationOptions = useValidationOptions()
  const {toast} = useToast()

  const onSubmit: SubmitHandler<CreateUniversityRequest> = async formData => {
    await handleRequest({
      request: async () => {
        formData.phone_number = formatBackToShortNumber(formData.phone_number) as any
        if (formData.logo) {
          formData.logo = (await uploadFiles([(formData as any).logo]))[0]
        }
        if (Array.isArray(formData.media) && formData.media.length) {
          formData.media = await uploadFiles((formData as any).media)
        }
        formData.study_level = formData.study_level.split(', ').filter((c: any) => c)
        const result = await createUniversity(formData)
        return result
      },
      onSuccess: () => {
        toast({
          title: t('toast.university_successfully_created'),
        })
        close()
      },
    })
  }

  useEffect(() => {
    form.register('title', getValidationOptions(true))
    form.register('email', getValidationOptions(true))
    form.register('password', getValidationOptions(true))
  }, [])

  return (
    <FormModal
      className="min-w-full lg:min-w-[850px]"
      close={close}
      open={open}
      loading={isCreating || isUploadingFiles}
      wrapForm={(content: ReactNode) => (
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Form {...form}>{content}</Form>
        </form>
      )}
    >
      <BoldTabs
        tabs={[
          {
            label: t('dashboard.universities.active_universities_page.profile'),
            children: <Profile form={form} />,
          },
          {
            label: t('dashboard.universities.active_universities_page.overview'),
            children: <Overview form={form} />,
          },
          {
            label: t('dashboard.universities.active_universities_page.about'),
            children: <About form={form} />,
          },
          {
            label: t('dashboard.universities.active_universities_page.media'),
            children: <Media form={form} />,
          },
        ]}
      />
    </FormModal>
  )
}
