import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ArrowLeft = ({...props}: IconProps) => (
  <Icon {...props} viewBox="0 0 28 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.124 9.20474C27.124 8.69953 26.7145 8.28998 26.2093 8.28998L4.63382 8.28998L10.3903 2.53346C10.7476 2.17623 10.7476 1.59703 10.3903 1.23979C10.0331 0.882551 9.4539 0.882551 9.09666 1.23979L1.77855 8.55791C1.42131 8.91514 1.42131 9.49434 1.77855 9.85158L9.09666 17.1697C9.4539 17.5269 10.0331 17.5269 10.3903 17.1697C10.7476 16.8125 10.7476 16.2333 10.3903 15.876L4.63382 10.1195L26.2093 10.1195C26.7145 10.1195 27.124 9.70995 27.124 9.20474Z"
      fill="currentColor"
    />
  </Icon>
)
