/* eslint-disable react-hooks/exhaustive-deps */
import {useGetHomePageQuery} from '@/features/home'
import {ImageCard} from './components'
import {getFindImage, getImageURL} from '@/utils/get-image'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {University} from '@/types/university'
import {SliderV3} from '@/components/common'

export const Universities = () => {
  const {data: {data: homePage} = {}} = useGetHomePageQuery('')
  const {t} = useTranslation()

  const sliderData = useMemo(
    () =>
      homePage?.universities?.map((image: University, index: number) => ({
        card: (
          <ImageCard
            id={image._id}
            key={index}
            title={image.title}
            src={getImageURL(getFindImage(image.media as any))}
          />
        ),
      })),
    [homePage],
  )

  if (!homePage?.universities?.length) {
    return <></>
  }

  return (
    <section className="max-w-[1600px] mx-auto px-4 mb-10 md:mb-20">
      <div>
        <h2 data-aos="fade-up" className="text-4xl md:text-[50px] leading-9 md:leading-17 font-bold text-center">
          {t('home.universities_of_the_day.title')}
        </h2>
        <p
          data-aos="fade-up"
          className="text-sm md:text-2xl leading-6 md:leading-8.5 text-blue-1 text-center mt-3 pb-7 md:pb-14"
        >
          {t('home.universities_of_the_day.description')}
        </p>
        <div data-aos="fade-up">
          <SliderV3 cards={sliderData} />
        </div>
      </div>
    </section>
  )
}
