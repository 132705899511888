export enum ChatSocketKeys {
  DISCONNECT = 'disconnect',
  EXCEPTION = 'exception',
  USER_ONLINE = 'userOnline',
  USER_OFFLINE = 'userOffline',
  MESSAGES_READ = 'messagesRead',
  MY_MESSAGES = 'myMessages',
  SEND_MESSAGE = 'sendMessage',
  NEW_MESSAGE = 'newMessage',
  GET_USER_CHATS = 'getUserChats',
  GET_COMPANY_CHATS = 'getCompanyChats',
  GET_UNIVERSITY_CHATS = 'getUniversityChats',
  GET_ADMIN_CHAT = 'getAdminChat',
  GET_MESSAGES = 'getMessages',
  GET_ME = 'getMe',
  CHAT = 'chat',
  START_CHAT = 'startChat',
  SET_FAVORITE = 'setFavorite',
  TYPING = 'typing',
  FAVORITE_CHAT = 'favoriteChat',
  DELETE_CHAT = 'deleteChat',
  DELETE_MESSAGES = 'deleteMessages',
  USER_CHATS = 'userChats',
  COMPANY_CHATS = 'companyChats',
  UNIVERSITY_CHATS = 'universityChats',
  ADMIN_CHAT = 'adminChat',
  APPLICANT_MESSAGES = 'applicantMessages',
  COMPANY_MESSAGES = 'companyMessages',
  UNIVERSITY_MESSAGES = 'universityMessages',
}
