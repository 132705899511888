import {SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/select'
import {Select as UISelect} from '../../ui/select'
import {ComponentProps, Props} from './types'
import {useMemo} from 'react'
import {DownIcon} from '@/icons'
import {cn} from '@/lib/utils'

export const Select = ({label, required, options, value, onChange, defaultValue, className, placeholder}: Props) => {
  const componentProps = useMemo(() => {
    const props: ComponentProps = {}

    if (onChange) {
      props.value = value || ''
      props.onValueChange = onChange
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return props
  }, [value, onChange, defaultValue])

  return (
    <label>
      {label && (
        <div className="flex items-center mb-2.5 text-blue-1 leading-6 font-bold">
          <p className="line-clamp-1">{label}</p>
          {required && <span className="text-orange">*</span>}
        </div>
      )}
      <UISelect {...componentProps}>
        <SelectTrigger
          className={cn(
            'flex bg-blue-7 data-[placeholder]:text-blue-2 data-[placeholder]:text-base min-h-[63.479px] w-full rounded-lg py-4 px-6 text-xl leading-7.5 target',
            className,
          )}
          hideIcon
        >
          <SelectValue
            defaultValue={value}
            placeholder={placeholder}
            className="placeholder:text-blue-19 placeholder:text-base"
          />
          <DownIcon className="w-4 h-4" />
        </SelectTrigger>
        <SelectContent className="max-h-[200px]">
          {options.map(option => (
            <SelectItem
              disabled={option.disabled}
              key={option.value}
              value={option.value}
              className="w-auto line-clamp-1 cursor-pointer"
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </UISelect>
    </label>
  )
}
