import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const PlusIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 13 12">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0C6.91421 0 7.25 0.335786 7.25 0.75V5.25H11.75C12.1642 5.25 12.5 5.58579 12.5 6C12.5 6.41421 12.1642 6.75 11.75 6.75H7.25V11.25C7.25 11.6642 6.91421 12 6.5 12C6.08579 12 5.75 11.6642 5.75 11.25V6.75H1.25C0.835786 6.75 0.5 6.41421 0.5 6C0.5 5.58579 0.835786 5.25 1.25 5.25H5.75V0.75C5.75 0.335786 6.08579 0 6.5 0Z"
      fill="currentColor"
    />
  </Icon>
)
