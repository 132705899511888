import {DateMonthPicker} from '@/components/common'
import {useEffect, useState} from 'react'
import {Props} from './types'
import {cn} from '@/lib/utils'

export const CVMonthPicker = ({required, onChange, value, label, labelClassName}: Props) => {
  const [startDate, setStartDate] = useState(value?.start ? value?.start : '')
  const [endDate, setEndDate] = useState(value?.end ? value?.end : '')

  useEffect(() => {
    if (onChange && startDate && endDate) {
      onChange({start: new Date(startDate), end: new Date(endDate)})
    }
  }, [onChange, startDate, endDate])

  return (
    <label>
      {label && (
        <span className={cn('block mb-2.5 text-blue-1 leading-6 font-bold', labelClassName)}>
          {label} {required && <span className="text-orange">*</span>}
        </span>
      )}
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-5 relative">
        <DateMonthPicker
          onChange={(date: string | undefined) => setStartDate(date as string)}
          startYear={15}
          endYear={0}
          value={startDate as string}
        />
        <DateMonthPicker
          value={endDate as string}
          startYear={startDate ? new Date().getFullYear() - new Date(startDate).getFullYear() : 15}
          endYear={10}
          onChange={date => setEndDate(date as string)}
        />
      </div>
    </label>
  )
}
