import {Image} from '@/components/common'
import {Link} from './components'
import {Contacts} from '@/constants/contacts'
import {FacebookIcon, InstagramIcon, LinkedinIcon, TelegramIcon} from '@/icons'
import {useTranslation} from 'react-i18next'

export const Footer = () => {
  const {t} = useTranslation()

  return (
    <footer className="bg-blue-5">
      <div className="container">
        <div className="md:flex md:gap-y-4 md:gap-x-0 flex-col md:flex-row md:flex-wrap md:justify-between gap-x-2 gap-y-7 py-10 md:py-20 grid grid-cols-2">
          <div className="md:w-auto col-span-2">
            <div className="flex items-center gap-3 md:mb-5 mb-3">
              <Image src="/images/logo.svg" alt="Student Hunter" />
              <h2 className="text-blue leading-10 md:text-4xl font-semibold whitespace-nowrap">Student Hunter</h2>
            </div>
            <p className="text-xs md:text-sm font-normal leading-6 text-blue-1 md:max-w-[284px]">
              {t('home.footer.phrase')}
            </p>
          </div>
          <div className="md:w-auto md:pl-6">
            <h2 className="text-blue md:text-4xl md:font-semibold font-bold md:mb-6 mb-3">
              {t('home.footer.about_us')}
            </h2>
            <div className="flex flex-col gap-2 leading:4.5 md:leading-6.5 text-xs md:text-base">
              <Link path="/about">{t('home.footer.our_story')}</Link>
              <Link path="/blogs">{t('home.footer.blog')}</Link>
              <Link path="/contact">{t('home.footer.help')}</Link>
              <a className="text-blue-1 link" href="#faq">
                {t('home.footer.faqs')}
              </a>
            </div>
          </div>
          <div className="md:w-auto col-span-1">
            <h2 className="text-blue md:text-4xl md:font-semibold font-bold md:mb-6 mb-3">
              {t('home.footer.features')}
            </h2>
            <div className="flex flex-col gap-2 md:leading-6.5 md:text-base leading-4.5 text-xs">
              <Link path="/rankings">{t('home.footer.university_rankings')}</Link>
              <p className="text-blue-1 link">{t('home.footer.knowledge_book')}</p>
              <p className="text-blue-1 link">{t('home.footer.application_guide')}</p>
              <Link path="/cv-builder">{t('home.footer.cv_builder')}</Link>
              <a className="text-blue-1 link" href="#social-media">
                {t('home.footer.social_media_highlights')}
              </a>
            </div>
          </div>
          <div className="md:w-auto">
            <h2 className="text-blue md:text-4xl md:font-semibold md:mb-6 font-bold mb-3">
              {t('home.footer.contact_us')}
            </h2>
            <div className="flex flex-col gap-2 md:leading-6.5 md:text-base leading-4.5 text-xs md:mb-0 mb-8">
              <a href={`mailto:${Contacts.EMAIL}`} className="text-blue-1 leading-6.5">
                {Contacts.EMAIL}
              </a>
              <a href={`tel:${Contacts.PHONE_NUMBER}`} className="text-blue-1 leading-6.5">
                {Contacts.PHONE_NUMBER}
              </a>
              <div className="flex items-center gap-3 mt-2.5">
                <a href={Contacts.TELEGRAM} aria-label="Contact us via Telegram">
                  <TelegramIcon className="w-6 h-6" />
                </a>
                <a href={Contacts.INSTAGRAM} aria-label="Contact us via Instagram">
                  <InstagramIcon className="w-6 h-6" />
                </a>
                <a href={Contacts.LINKEDIN} aria-label="Contact us via Linkedin">
                  <LinkedinIcon className="w-6 h-6" />
                </a>
                <a href={Contacts.FACEBOOK} aria-label="Contact us via Facebook">
                  <FacebookIcon className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[70px] md:flex-row flex-col-reverse items-center gap-4 md:gap-16 leading-4.5 md:leading-normal md:text-base text-xs md:border-t md:border-blue-2 pb-10 md:pt-5">
          <p>© Copyright - {new Date().getFullYear()}</p>
          <div className="flex flex-row flex-wrap md:items-center gap-y-3 gap-x-8 leading-4.5 md:leading-normal">
            <p>{t('home.footer.terms')}</p>
            <Link path="/privacy-policy">{t('home.footer.privacy_policy')}</Link>
            <Link path="/contact">{t('home.footer.support')}</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
