import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const BlogAvatarIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 36 36" fill="none">
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6779 21.894C12.8883 22.1049 12.9936 22.3988 12.9652 22.6954C12.9016 23.3601 12.755 24.0519 12.5668 24.6945C13.9617 24.3721 14.8136 23.9981 15.2008 23.8019C15.4206 23.6905 15.6738 23.6642 15.9118 23.728C16.5697 23.9044 17.2708 24 18 24C21.9956 24 25 21.1925 25 18C25 14.8075 21.9956 12 18 12C14.0044 12 11 14.8075 11 18C11 19.4681 11.6173 20.8304 12.6779 21.894ZM12.1849 25.7989C12.1782 25.8003 12.1714 25.8016 12.1647 25.8029C12.0728 25.821 11.9792 25.8388 11.8838 25.8563C11.75 25.8808 11.6126 25.9047 11.4717 25.9277C11.2727 25.9603 11.1204 25.7521 11.199 25.5664C11.2503 25.4451 11.301 25.3179 11.3503 25.1861C11.3816 25.1023 11.4124 25.0167 11.4426 24.9294C11.4439 24.9256 11.4452 24.9219 11.4465 24.9181C11.6942 24.1984 11.896 23.3711 11.9698 22.6001C10.7431 21.3699 10 19.7609 10 18C10 14.134 13.5817 11 18 11C22.4183 11 26 14.134 26 18C26 21.866 22.4183 25 18 25C17.1832 25 16.3951 24.8929 15.6528 24.6939C15.1334 24.9571 14.0143 25.4363 12.1849 25.7989Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 15.5C14 15.2239 14.2239 15 14.5 15H21.5C21.7761 15 22 15.2239 22 15.5C22 15.7761 21.7761 16 21.5 16H14.5C14.2239 16 14 15.7761 14 15.5ZM14 18C14 17.7239 14.2239 17.5 14.5 17.5H21.5C21.7761 17.5 22 17.7239 22 18C22 18.2761 21.7761 18.5 21.5 18.5H14.5C14.2239 18.5 14 18.2761 14 18ZM14 20.5C14 20.2239 14.2239 20 14.5 20H18.5C18.7761 20 19 20.2239 19 20.5C19 20.7761 18.7761 21 18.5 21H14.5C14.2239 21 14 20.7761 14 20.5Z"
      fill="currentColor"
    />
  </Icon>
)
