export enum UniversityStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
  PENDING = 2,
}

export enum UniversityAppliedUserStatus {
  NOT_SET = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  SHORTLISTED = 3,
}

export enum FacultyStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum BlogStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
  DRAFT = 2,
}

export enum ApplicantStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  PENDING = 2,
}

export enum ApplicantAppliedProgramStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ApplicantEducationStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ApplicantExperienceStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ApplicantCertificateStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ApplicantOtherCertificateStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ApplicantAchivementStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum VacancyStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum AppliedVacancyStatus {
  PENDING,
}

export enum CompanyStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  PENDING = 2,
}

export enum AppliedApplicantStatus {
  NOT_SET = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  SHORTLISTED = 3,
}

export enum AppliedStatus {
  NOT_SET = 0,
  APPLIED = 1,
}

export enum SavedStatus {
  UNSAVED = 0,
  SAVED = 1,
}

export enum VacancyApplicantStatus {
  NOT_SET = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  SHORTLISTED = 3,
  SAVED = 4,
}

export enum ConsultingStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  PENDING = 2,
}

export enum ConsultingContractStatus {
  INACTIVE = 0,
  ACITVE = 1,
}

export enum FeedbackType {
  USER = 'user',
  UNIVERSITY = 'university',
  COMPANY = 'company',
}

export enum ApplicantCompanyVacancyStatus {
  NOT_SET = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  PENDING = 3,
  LIKED = 4,
}

export enum ApplicantUniversityStatus {
  ACCEPTED = 1,
  REJECTED = 2,
  PENDING = 3,
}

export enum SkillStatus {
  ARCHIVED = 0,
  ACTIVE = 1,
}

export enum VacancyExamQuestionType {
  MULTI_CHOICE = 'multi-choice',
  TFN = 'tfn',
  SHORT_TEXT = 'short-text',
}

export enum VacancyExamTFNAnswer {
  TRUE = 'true',
  FALSE = 'false',
  NOT_GIVEN = 'ng',
}
