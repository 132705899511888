import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const HospitalityIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path d="M50.0012 73.8H43.8008V58.8H56.3007V73.8H50.0012Z" fill="transparent" />
    <path
      d="M39.2995 29.5C39.7995 30 39.9995 30.6 39.9995 31.3C39.9995 32.7 38.9005 33.8 37.4994 33.8H31.1989C30.4989 33.8 29.8998 33.5 29.3998 33.1C28.8997 32.6 28.6997 32 28.6997 31.3C28.6997 30.8 28.7997 30.4 28.9998 30H23.6994C22.9993 30 22.4003 29.7 21.9002 29.3C21.3992 28.8 21.2002 28.2 21.2002 27.5C21.2002 26.1 22.2993 25 23.6994 25H34.9992C35.6992 25 36.3003 25.3 36.7993 25.7C37.2994 26.2 37.4994 26.8 37.4994 27.5C37.4994 28 37.3984 28.4 37.1983 28.8H37.4994C38.2004 28.8 38.7995 29 39.2995 29.5Z"
      fill="transparent"
    />
    <path
      d="M69.9995 27.5C72.0986 27.5 73.7997 29.201 73.7997 31.3C73.7997 33.399 72.0986 35.1 69.9995 35.1C67.9013 35.1 66.2002 33.399 66.2002 31.3C66.2002 29.201 67.9013 27.5 69.9995 27.5Z"
      fill="transparent"
    />
    <path d="M58.8016 22.5H50.001V27.5H58.8016V22.5Z" fill="transparent" />
    <path d="M72.6001 52.5H68.7998V56.3H72.6001V52.5Z" fill="transparent" />
    <path d="M72.6001 61.3H68.7998V65.1001H72.6001V61.3Z" fill="transparent" />
    <path d="M31.3002 61.3H27.5V65.1001H31.3002V61.3Z" fill="transparent" />
    <path d="M31.3002 52.5H27.5V56.3H31.3002V52.5Z" fill="transparent" />
    <path d="M47.6011 43.8H43.8008V53.8H47.6011V43.8Z" fill="transparent" />
    <path d="M56.3003 43.8H52.5V53.8H56.3003V43.8Z" fill="transparent" />
    <path
      d="M36.3 47.5V68.8H22.499V47.5H36.3ZM31.2996 65V61.2H27.4994V65H31.2996ZM31.2996 56.3V52.5H27.4994V56.3H31.2996Z"
      fill="transparent"
    />
    <path
      d="M77.5008 47.5V68.8H63.7998V47.5H77.5008ZM72.5014 65V61.2H68.7011V65H72.5014ZM72.5014 56.3V52.5H68.7011V56.3H72.5014Z"
      fill="transparent"
    />
    <path
      d="M63.7997 68.7998V73.7998H56.3002V58.7998H43.8003V73.7998H36.3008V68.7998V47.4998V42.4998L50.0008 31.2998L63.7997 42.5998V47.5998V68.7998ZM56.3002 53.7998V43.7998H52.4999V53.7998H56.3002ZM47.5006 53.7998V43.7998H43.7013V53.7998H47.5006Z"
      fill="transparent"
    />
    <path
      d="M63.7981 74.9999C63.099 74.9999 62.499 74.3999 62.499 73.6999V68.6999C62.499 67.9999 63.099 67.3999 63.7981 67.3999C64.4982 67.3999 65.0992 67.9999 65.0992 68.6999V73.6999C64.9982 74.3999 64.3991 74.9999 63.7981 74.9999Z"
      fill="currentColor"
    />
    <path
      d="M63.798 48.7999C63.099 48.7999 62.499 48.1999 62.499 47.4999V43.0999L49.9991 32.8999L37.4992 43.0999V47.4999C37.4992 48.1999 36.8992 48.7999 36.1991 48.7999C35.4991 48.7999 34.999 48.1999 34.999 47.4999V42.4999C34.999 42.0999 35.199 41.7999 35.4991 41.4999L49.3 30.1999C49.8 29.7999 50.4001 29.7999 50.9001 30.1999L64.7001 41.4999C65.0001 41.6999 65.2001 42.0999 65.2001 42.4999V47.4999C64.9981 48.1999 64.3991 48.7999 63.798 48.7999Z"
      fill="currentColor"
    />
    <path
      d="M36.2991 74.9999C35.5991 74.9999 34.998 74.3999 34.998 73.6999V68.6999C34.998 67.9999 35.5991 67.3999 36.2991 67.3999C36.9992 67.3999 37.5982 67.9999 37.5982 68.6999V73.6999C37.4992 74.3999 36.8982 74.9999 36.2991 74.9999Z"
      fill="currentColor"
    />
    <path
      d="M77.4991 69.9997H63.7981C63.099 69.9997 62.499 69.3997 62.499 68.6997V47.4997C62.499 46.7997 63.099 46.1997 63.7981 46.1997H77.5991C78.2991 46.1997 78.8982 46.7997 78.8982 47.4997V68.7997C78.7991 69.3997 78.1991 69.9997 77.4991 69.9997ZM64.9982 67.4997H76.299V48.7997H64.9982V67.4997Z"
      fill="currentColor"
    />
    <path
      d="M36.3003 70H22.4993C21.7993 70 21.2002 69.4 21.2002 68.7V47.5C21.2002 46.8 21.7993 46.2 22.4993 46.2H36.3003C37.0003 46.2 37.5994 46.8 37.5994 47.5V68.8C37.5004 69.4 36.8993 70 36.3003 70ZM23.8004 67.5H34.9992V48.8H23.8004V67.5Z"
      fill="currentColor"
    />
    <path
      d="M77.4993 48.8C76.7993 48.8 76.1982 48.2 76.1982 47.5V45C76.1982 44.3 76.7993 43.7 77.4993 43.7C78.1994 43.7 78.7984 44.3 78.7984 45V47.5C78.7994 48.2 78.1994 48.8 77.4993 48.8Z"
      fill="currentColor"
    />
    <path
      d="M22.4993 48.8002C21.7993 48.8002 21.2002 48.2002 21.2002 47.5002V45.0002C21.2002 44.3002 21.7993 43.7002 22.4993 43.7002C23.1994 43.7002 23.8004 44.3002 23.8004 45.0002V47.5002C23.8004 48.2002 23.2003 48.8002 22.4993 48.8002Z"
      fill="currentColor"
    />
    <path
      d="M56.2994 74.9998C55.5993 74.9998 55.0003 74.3998 55.0003 73.6998V59.9998H44.9996V73.7998C44.9996 74.4998 44.3996 75.0998 43.7005 75.0998C43.0005 75.0998 42.4004 74.4998 42.4004 73.7998V58.7998C42.4004 58.0998 43.0005 57.4998 43.7005 57.4998H56.2004C56.9004 57.4998 57.4995 58.0998 57.4995 58.7998V73.7998C57.4995 74.3998 56.9004 74.9998 56.2994 74.9998Z"
      fill="currentColor"
    />
    <path
      d="M49.9993 74.9998C49.2993 74.9998 48.6982 74.3998 48.6982 73.6998V63.2998C48.6982 62.5998 49.2983 61.9998 49.9993 61.9998C50.6994 61.9998 51.2984 62.5998 51.2984 63.2998V73.6998C51.2984 74.3998 50.6994 74.9998 49.9993 74.9998Z"
      fill="currentColor"
    />
    <path
      d="M47.4997 54.9999H43.7005C43.0005 54.9999 42.4004 54.3999 42.4004 53.6999V43.6999C42.4004 42.9999 43.0005 42.3999 43.7005 42.3999H47.4997C48.1998 42.3999 48.7999 42.9999 48.7999 43.6999V53.6999C48.7999 54.3999 48.1998 54.9999 47.4997 54.9999ZM44.9996 52.4999H46.2987V44.9999H44.9996V52.4999Z"
      fill="currentColor"
    />
    <path
      d="M56.2996 54.9999H52.4993C51.7993 54.9999 51.2002 54.3999 51.2002 53.6999V43.6999C51.2002 42.9999 51.7993 42.3999 52.4993 42.3999H56.2996C56.9996 42.3999 57.5997 42.9999 57.5997 43.6999V53.6999C57.4997 54.3999 56.9006 54.9999 56.2996 54.9999ZM53.8004 52.4999H55.0005V44.9999H53.6994V52.4999H53.8004Z"
      fill="currentColor"
    />
    <path
      d="M31.2996 57.4998H27.4993C26.7993 57.4998 26.2002 56.8998 26.2002 56.1998V52.3998C26.2002 51.6998 26.7993 51.0999 27.4993 51.0999H31.2996C31.9996 51.0999 32.5987 51.6998 32.5987 52.3998V56.1998C32.4996 56.8998 31.8996 57.4998 31.2996 57.4998ZM28.7994 54.9998H29.9995V53.6998H28.7004V54.9998H28.7994Z"
      fill="currentColor"
    />
    <path
      d="M31.2996 66.3002H27.4993C26.7993 66.3002 26.2002 65.7002 26.2002 65.0002V61.2001C26.2002 60.5001 26.7993 59.9001 27.4993 59.9001H31.2996C31.9996 59.9001 32.5987 60.5001 32.5987 61.2001V65.0002C32.4996 65.7002 31.8996 66.3002 31.2996 66.3002ZM28.7994 63.8002H29.9995V62.5002H28.7004V63.8002H28.7994Z"
      fill="currentColor"
    />
    <path
      d="M72.5008 57.4998H68.7005C68.0004 57.4998 67.3994 56.8998 67.3994 56.1998V52.3998C67.3994 51.6998 68.0004 51.0999 68.7005 51.0999H72.5008C73.2008 51.0999 73.7998 51.6998 73.7998 52.3998V56.1998C73.7998 56.8998 73.2008 57.4998 72.5008 57.4998ZM69.9996 54.9998H71.3007V53.6998H69.9996V54.9998Z"
      fill="currentColor"
    />
    <path
      d="M72.5008 66.2999H68.7005C68.0004 66.2999 67.3994 65.6999 67.3994 64.9999V61.1999C67.3994 60.4999 68.0004 59.8999 68.7005 59.8999H72.5008C73.2008 59.8999 73.7998 60.4999 73.7998 61.1999V64.9999C73.7998 65.6999 73.2008 66.2999 72.5008 66.2999ZM69.9996 63.7999H71.3007V62.4999H69.9996V63.7999Z"
      fill="currentColor"
    />
    <path
      d="M49.9993 23.7999C49.2993 23.7999 48.6982 23.1999 48.6982 22.4999V21.1999C48.6982 20.4999 49.2983 19.8999 49.9993 19.8999C50.6994 19.8999 51.2984 20.4999 51.2984 21.1999V22.4999C51.2984 23.1999 50.6994 23.7999 49.9993 23.7999Z"
      fill="currentColor"
    />
    <path
      d="M49.9993 32.4998C49.2993 32.4998 48.6982 31.8998 48.6982 31.1998V27.3998C48.6982 26.6998 49.2983 26.0999 49.9993 26.0999C50.6994 26.0999 51.2984 26.6998 51.2984 27.3998V31.1998C51.2984 31.8998 50.6994 32.4998 49.9993 32.4998Z"
      fill="currentColor"
    />
    <path
      d="M58.8 28.8H49.9993C49.2993 28.8 48.6982 28.2 48.6982 27.5V22.5C48.6982 21.8 49.2983 21.2 49.9993 21.2H58.8C59.499 21.2 60.099 21.8 60.099 22.5V27.5C60 28.2 59.399 28.8 58.8 28.8ZM51.2984 26.3H57.5989V23.8H51.2984V26.3Z"
      fill="currentColor"
    />
    <path
      d="M66.3003 74.9999H33.8001C33.1 74.9999 32.501 74.3999 32.501 73.6999C32.501 72.9999 33.1 72.3999 33.8001 72.3999H66.3003C67.0004 72.3999 67.5994 72.9999 67.5994 73.6999C67.5004 74.3999 66.8994 74.9999 66.3003 74.9999Z"
      fill="currentColor"
    />
    <path
      d="M72.5014 79.9999H27.5012C26.8012 79.9999 26.2021 79.3999 26.2021 78.6999C26.2021 77.9999 26.8012 77.3999 27.5012 77.3999H72.5014C73.2015 77.3999 73.8005 77.9999 73.8005 78.6999C73.8005 79.3999 73.2015 79.9999 72.5014 79.9999Z"
      fill="currentColor"
    />
    <path
      d="M37.5 35.0001H31.1996C30.1995 35.0001 29.2995 34.6001 28.4994 33.9001C27.7994 33.2001 27.4003 32.2001 27.4003 31.2001H23.6001C22.6 31.2001 21.6999 30.8001 20.8999 30.1001C20.1998 29.4001 19.7998 28.4001 19.7998 27.4001C19.7998 25.3001 21.4999 23.6001 23.6001 23.6001H34.9999C36.0009 23.6001 36.9 24.0001 37.701 24.7001C38.4001 25.4001 38.8001 26.4001 38.8001 27.4001C38.8001 27.5001 38.8001 27.5001 38.8001 27.6001C39.3002 27.8001 39.8002 28.1001 40.2002 28.5001C40.9003 29.2001 41.3003 30.2001 41.3003 31.2001C41.3003 33.3001 39.5992 35.0001 37.5 35.0001ZM23.8001 26.3001C23.1 26.3001 22.499 26.9001 22.499 27.6001C22.499 27.9001 22.6 28.2001 22.899 28.5001C23.099 28.7001 23.399 28.9001 23.7991 28.9001H29.0995C29.4985 28.9001 29.9985 29.1001 30.1985 29.5001C30.3985 29.9001 30.3985 30.4001 30.1985 30.8001C30.0985 30.8001 29.9985 31.0001 29.9985 31.3001C29.9985 31.6001 30.0995 31.9001 30.3985 32.2001C30.5986 32.4001 30.8986 32.6001 31.2986 32.6001H37.598C38.2981 32.6001 38.8991 32.0001 38.8991 31.3001C38.8991 31.0001 38.7981 30.7001 38.4991 30.4001C38.2991 30.2001 37.9991 30.0001 37.599 30.0001H37.299C36.899 30.0001 36.399 29.8001 36.1989 29.4001C35.9999 29.0001 35.9999 28.5001 36.1989 28.1001C36.2999 27.9001 36.399 27.7001 36.399 27.5001C36.399 27.2001 36.299 26.9001 35.9999 26.6001C35.7999 26.4001 35.4989 26.2001 35.0999 26.2001H23.8001V26.3001Z"
      fill="currentColor"
    />
    <path
      d="M69.9984 36.2998C67.1992 36.2998 64.998 34.0998 64.998 31.2998C64.998 28.4998 67.1992 26.2998 69.9984 26.2998C72.7986 26.2998 74.9987 28.4998 74.9987 31.2998C74.9987 34.0998 72.7986 36.2998 69.9984 36.2998ZM69.9984 28.7998C68.5983 28.7998 67.4992 29.8998 67.4992 31.2998C67.4992 32.6998 68.5983 33.7998 69.9984 33.7998C71.3985 33.7998 72.4986 32.6998 72.4986 31.2998C72.4996 29.8998 71.3985 28.7998 69.9984 28.7998Z"
      fill="currentColor"
    />
  </Icon>
)
