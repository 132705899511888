import {ChangeEvent, useMemo, useRef, useState} from 'react'
import {Props} from './types'
import {CaretDownFill, Eye2Icon, EyeHidden2Icon} from '@/icons'
import {Select, SelectContent, SelectItem, SelectTrigger} from '@/components/ui/select'
import {COUNTRIES} from '@/constants/countries'
import {cn} from '@/lib/utils'
import {formatGlobalPhoneNumber, getPureNumber} from '@/utils/format-phone-number'
import {formatNumber, formatPrice} from '@/utils/format-price'
import {Image} from '..'

export const SecondInput = ({
  label,
  placeholder,
  required,
  type = 'text',
  value,
  onChange,
  onBlur,
  className,
  fieldClassName,
  onKeyDown,
  labelClassName,
  disabled,
}: Props) => {
  const [showValue, setShowValue] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRIES?.find(country => typeof value === 'string' && value?.includes(country.code))?.shortName ||
      COUNTRIES[186]?.shortName,
  )
  const inputRef = useRef<HTMLInputElement | null>(null)
  const countryCode = useMemo(() => {
    const code = COUNTRIES.find(country => country.shortName === selectedCountry)?.code || ''

    return code
  }, [selectedCountry])
  const [phoneNumber, setPhoneNumber] = useState(value || '')

  const inputProps = useMemo(() => {
    const props: HTMLInputElement = {} as any

    if (type === 'tel') {
      props.value =
        countryCode +
        ' ' +
        formatGlobalPhoneNumber((getPureNumber(phoneNumber) || '').replace(' ', ''), selectedCountry)
    } else if (type === 'price') {
      props.value = phoneNumber
    } else if (onChange) {
      props.value = value || ''
    }

    return props
  }, [countryCode, phoneNumber, type, value, onChange, selectedCountry])

  const formatPhoneNumber = (inputPhoneNumber?: string) => {
    const numericPhoneNumber = inputPhoneNumber?.slice(inputPhoneNumber.indexOf(' ') + 1).replace(' ', '')
    if (numericPhoneNumber) {
      return formatGlobalPhoneNumber(numericPhoneNumber, selectedCountry)
    }
  }

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement> | any) => {
    const inputPhoneNumber = e.target.value || ''
    if (type === 'tel' && inputPhoneNumber !== countryCode) {
      const formattedPhoneNumber = formatPhoneNumber(inputPhoneNumber)
      setPhoneNumber(formattedPhoneNumber || '')
    }
    if (type === 'price') {
      setPhoneNumber(formatPrice(inputPhoneNumber))
    }

    if (type === 'number') {
      setPhoneNumber(formatNumber(`${inputPhoneNumber || ''}`))
    }

    if (onChange) {
      switch (type) {
        case 'tel':
          onChange(countryCode + formatPhoneNumber(inputPhoneNumber)?.replaceAll(' ', ''))
          break
        case 'price':
          onChange(inputPhoneNumber.replace(/[^\d]/g, '').replaceAll(' ', ''))
          break
        case 'number':
          onChange(formatNumber(`${inputPhoneNumber || ''}`))
          break
        default:
          onChange(inputPhoneNumber)
      }
    }

    if (onBlur) {
      onBlur(inputPhoneNumber)
    }
  }

  const onCountryChange = (shortName: string) => {
    setSelectedCountry(shortName)
    if (type === 'tel' && phoneNumber) {
      const formattedPhoneNumber = formatGlobalPhoneNumber(phoneNumber.replace(' ', ''), shortName)
      setPhoneNumber(formattedPhoneNumber || '')
    }
  }

  return (
    <label>
      {label && (
        <div className={cn('flex items-center mb-2.5 text-blue-1 leading-6 font-bold', labelClassName)}>
          <p className="line-clamp-1">{label}</p>
          {required && <span className="text-orange">*</span>}
        </div>
      )}
      <div className="relative">
        {type === 'tel' && (
          <Select value={selectedCountry} onValueChange={onCountryChange}>
            <SelectTrigger
              hideIcon
              className={cn(
                'absolute h-[60px] top-1/2 -translate-y-1/2 left-5 border-0 w-max p-0 !outline-0 !ring-0 shadow-none flex items-center gap-3',
                className,
              )}
            >
              <span>
                <Image
                  src={`https://flagcdn.com/${selectedCountry}.svg`}
                  className="w-8 h-8"
                  imageClassName="w-8 h-8 object-contain"
                />
              </span>
              <CaretDownFill className="w-3 h-3" />
            </SelectTrigger>
            <SelectContent className="max-h-[200px]">
              {COUNTRIES.map(country => (
                <SelectItem key={country.shortName} value={country.shortName} className="cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span>
                      <Image
                        src={`https://flagcdn.com/${country.shortName}.svg`}
                        className="w-4 h-4"
                        imageClassName="w-4 h-4 object-contain"
                      />
                    </span>
                    <span>{country.code}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        <input
          {...(inputProps as any)}
          type={
            type !== 'password' ? (['number', 'price'].includes(type) ? 'text' : type) : showValue ? 'text' : 'password'
          }
          className={cn(
            'block bg-blue-7 w-full rounded-lg py-4 px-6 text-xl leading-7.5 peer border border-input',
            type === 'tel' && 'pl-[90px]',
            fieldClassName,
          )}
          placeholder={placeholder}
          onChange={handlePhoneNumberChange}
          ref={inputRef}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setShowValue(prev => !prev)}
            className="text-blue-2 absolute top-1/2 -translate-y-1/2 right-5"
          >
            {showValue ? <Eye2Icon className="w-6 h-6" /> : <EyeHidden2Icon className="w-7 h-7" />}
          </button>
        )}
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue opacity-0  duration-100" />
      </div>
    </label>
  )
}
