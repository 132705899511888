import {Avatar} from '@/components/common'
import {Props} from './types'
import {getImageURL} from '@/utils/get-image'

export const ReviewCard = ({review}: Props) => (
  <div className="flex flex-col justify-between border border-blue-1 rounded-lg md:rounded-2xl p-4 md:p-10">
    <div>
      <div className="hidden lg:inline-block">
        <Avatar
          src={getImageURL(review?.image)}
          fullName={review?.name}
          className="w-10 h-10 lg:w-[80px] lg:h-[80px]"
          avatarClassName="w-10 lg:w-[80px] h-10 lg:h-[80px]"
          type="program"
          imageClassName="w-10 h-10 lg:w-[80px] lg:h-[80px]"
        />
      </div>
      <div className="flex  items-center gap-2.5"></div>
      <p className="mt-0 mb-6 md:mt-6 md:mb-10 text-xs md:text-sm italic font-light leading-4.5 md:leading-6">
        “{review?.feedback.replace(/<[^>]*>?/gm, '')}”
      </p>
    </div>
    <div className="flex gap-2.5 items-center">
      <div className="h-full inline-flex items-center lg:hidden lg:pb-0">
        <Avatar
          src={getImageURL(review?.image)}
          fullName={review?.name}
          className="w-10 lg:w-[80px] lg:h-[80px]"
          avatarClassName="w-10 lg:w-[80px] h-10 lg:h-[80px]"
          type="program"
          imageClassName="w-10 lg:w-[80px] h-10 lg:h-[80px]"
        />
      </div>
      <div>
        <h6 className="text-base md:text-xl font-bold leading-4 md:leading-7.5">{review?.name}</h6>
        <span className="text-xs md:text-sm leading-4.5 md:leading-6">{review?.position}</span>
      </div>
    </div>
  </div>
)
