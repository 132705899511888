export enum RTKTagNames {
  USER = 'USER',
  UNIVERSITY = 'UNIVERSITY',
  FACULTY = 'FACULTY',
  BLOGS = 'BLOGS',
  APPLICANT = 'APPLICANT',
  COMPANY = 'COMPANY',
  CONSULTING = 'CONSULTING',
  VACANCY = 'VACANCY',
  SKILL = 'SKILL',
  HOME_PAGE = 'HOME_PAGE',
  FEEDBACKS = 'FEEDBACKS',
  FAQS = 'FAQS',
  HIGHLIGHTS = 'HIGHLIGHTS',
  DASHBOARD = 'DASHBOARD',
  ADMIN = 'ADMIN',
  ADMINS = 'ADMINS',
  VACANCY_EXAM = 'VACANCY_EXAM',
}
