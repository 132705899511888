import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const EducationIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M44.5992 57.6001C46.1003 58.2011 47.6994 58.6001 49.3995 58.6001C51.1006 58.6001 52.6998 58.2011 54.1999 57.6001L60.0003 63.4011C56.9001 65.4011 53.2998 66.5011 49.3995 66.5011C45.5003 66.5011 41.8 65.4011 38.7998 63.4011L44.5992 57.6001Z"
      fill="transparent"
    />
    <path d="M35.9005 66.1999L25.3008 71.2999L30.3001 60.5999L35.9005 66.1999Z" fill="transparent" />
    <path d="M68.4022 60.5999L73.4025 71.2999L62.8018 66.1999L68.4022 60.5999Z" fill="transparent" />
    <path
      d="M49.3982 52.7999L44.5988 57.5999L38.6994 63.4L35.8992 66.2L30.2988 60.5999L33.099 57.9L38.7984 52.0999L43.7988 47.2L49.3982 52.7999Z"
      fill="transparent"
    />
    <path
      d="M70.6999 31.5001L66.1006 36.1001L60.2002 41.9001L55.0008 47.2001L49.3994 41.6001L54.8008 36.1001L60.6002 30.4001L65.1005 25.9001L70.6999 31.5001Z"
      fill="transparent"
    />
    <path
      d="M73.4006 23.1001C74.9007 24.6001 74.9007 27.2011 73.4006 28.7011L70.6004 31.5011L65 25.9011L67.8002 23.1001C69.4003 21.6001 71.8995 21.6001 73.4006 23.1001Z"
      fill="transparent"
    />
    <path
      d="M30.9003 23.1001L33.7005 25.9011L28.1011 31.5011L25.3009 28.7011C23.8008 27.2011 23.8008 24.6001 25.3009 23.1001C26.8 21.6001 29.4002 21.6001 30.9003 23.1001Z"
      fill="transparent"
    />
    <path
      d="M68.4014 60.6001L62.801 66.2001L60.0018 63.4002L54.2004 57.6001L49.4 52.8001L43.8007 47.2001L38.5013 41.9001L32.7009 36.1001L28.1016 31.5001L33.701 25.9001L38.2013 30.4001L43.9007 36.1001L49.4 41.6001L55.0014 47.2001L59.9008 52.1001L65.7002 57.9002L68.4014 60.6001Z"
      fill="transparent"
    />
    <path
      d="M49.3996 67.7002C45.4003 67.7002 41.5001 66.5992 38.0998 64.4002C37.5008 64.0002 37.2998 63.2992 37.7008 62.7002C38.0998 62.0992 38.7999 61.9001 39.4009 62.3001C42.4001 64.2001 45.8004 65.2002 49.4006 65.2002C53.0009 65.2002 56.4011 64.2001 59.4013 62.3001C60.0023 61.9011 60.8014 62.1002 61.1014 62.7002C61.5015 63.3002 61.3014 64.0992 60.7014 64.4002C57.3002 66.6002 53.4009 67.7002 49.3996 67.7002Z"
      fill="currentColor"
    />
    <path
      d="M49.399 59.8C47.5998 59.8 45.7987 59.401 44.0996 58.7C43.5005 58.4 43.2005 57.7 43.5005 57.099C43.8006 56.499 44.5006 56.2 45.0997 56.499C46.4998 57.099 47.8999 57.399 49.4 57.399C50.9011 57.399 52.3002 57.098 53.7003 56.499C54.3003 56.199 55.1004 56.499 55.3004 57.099C55.5004 57.7 55.3004 58.499 54.7003 58.7C52.9992 59.5 51.2001 59.8 49.399 59.8Z"
      fill="currentColor"
    />
    <path
      d="M49.3991 42.8C49.0991 42.8 48.8 42.7 48.5 42.401C48 41.901 48 41.1 48.5 40.6L64.1991 25C64.6992 24.5 65.4992 24.5 65.9993 25C66.4993 25.5 66.4993 26.3 65.9993 26.8L50.2991 42.5C50.0001 42.7 49.7001 42.8 49.3991 42.8Z"
      fill="currentColor"
    />
    <path
      d="M55.0007 48.4C54.6997 48.4 54.3996 48.299 54.1006 48C53.6006 47.5 53.6006 46.7 54.1006 46.2L69.7997 30.5C70.3007 30 71.0998 30 71.5998 30.5C72.0999 31 72.0999 31.8 71.5998 32.3L55.9007 48.1C55.5997 48.3 55.2997 48.4 55.0007 48.4Z"
      fill="currentColor"
    />
    <path
      d="M35.9011 67.4999C35.601 67.4999 35.302 67.3999 35.001 67.0999C34.501 66.5999 34.501 65.7999 35.001 65.2999L48.4009 51.9009C48.901 51.4009 49.701 51.4009 50.2011 51.9009C50.7011 52.4009 50.7011 53.2009 50.2011 53.7009L36.8011 67.0999C36.6011 67.2999 36.3011 67.4999 35.9011 67.4999Z"
      fill="currentColor"
    />
    <path
      d="M30.3005 61.9001C29.9995 61.9001 29.7004 61.7991 29.4004 61.5001C28.9004 61.0001 28.9004 60.2001 29.4004 59.7001L42.8004 46.3C43.3004 45.8 44.0995 45.8 44.5995 46.3C45.0995 46.8 45.0995 47.6 44.5995 48.1L31.1996 61.5001C31.0005 61.7001 30.7005 61.9001 30.3005 61.9001Z"
      fill="currentColor"
    />
    <path
      d="M25.3019 72.5C25.0018 72.5 24.7018 72.4 24.4018 72.1C24.0018 71.701 23.9018 71.201 24.2018 70.701L29.2021 60.1C29.4021 59.701 29.7022 59.5 30.1022 59.401C30.5012 59.3 30.9023 59.501 31.2013 59.701L36.8017 65.301C37.1017 65.601 37.2007 66.001 37.1017 66.402C37.0017 66.801 36.8017 67.101 36.4016 67.301L25.8019 72.301C25.7019 72.5 25.5019 72.5 25.3019 72.5ZM30.7012 62.8L27.902 68.7L33.8014 65.9L30.7012 62.8Z"
      fill="currentColor"
    />
    <path
      d="M70.6997 32.6999C70.3997 32.6999 70.1006 32.5989 69.7996 32.2999L64.2002 26.6999C63.7002 26.1999 63.7002 25.3999 64.2002 24.8999L67.0004 22.0989C68.0005 21.0989 69.2996 20.5989 70.6997 20.5989C72.0998 20.5989 73.4009 21.0989 74.4 22.0989C75.4 23.0989 75.9001 24.3999 75.9001 25.7989C75.9001 27.1989 75.4 28.4989 74.4 29.4989L71.5998 32.2989C71.3007 32.5999 71.0007 32.6999 70.6997 32.6999ZM66.8004 25.8999L70.6007 29.6999L72.5008 27.7999C73.0009 27.2999 73.3009 26.5999 73.3009 25.9009C73.3009 25.2009 73.0009 24.5009 72.5008 24.0009C72.0008 23.5009 71.3007 23.2009 70.6007 23.2009C69.9006 23.2009 69.2006 23.5009 68.7005 24.0009L66.8004 25.8999Z"
      fill="currentColor"
    />
    <path
      d="M62.8007 67.4999C62.5007 67.4999 62.2016 67.3999 61.9016 67.0999L27.2012 32.3999C26.7012 31.8999 26.7012 31.0989 27.2012 30.5989C27.7012 30.0989 28.5003 30.0989 29.0003 30.5989L63.7008 65.2989C64.2008 65.7989 64.2008 66.5989 63.7008 67.0989C63.5008 67.2999 63.1007 67.4999 62.8007 67.4999Z"
      fill="currentColor"
    />
    <path
      d="M68.4003 61.9C68.0993 61.9 67.7993 61.799 67.5002 61.5L32.7998 26.8C32.2998 26.3 32.2998 25.5 32.7998 25C33.2999 24.5 34.0999 24.5 34.5999 25L69.2994 59.7C69.7994 60.2 69.7994 61 69.2994 61.5C69.1004 61.7 68.7003 61.9 68.4003 61.9Z"
      fill="currentColor"
    />
    <path
      d="M73.4016 72.5C73.2015 72.5 73.0015 72.5 72.9005 72.4L62.3008 67.4C61.9017 67.2 61.7017 66.9 61.6007 66.5C61.5007 66.1 61.7017 65.7 61.9017 65.4L67.5011 59.799C67.8011 59.499 68.2012 59.4 68.6002 59.499C69.0003 59.599 69.3003 59.7989 69.5003 60.1989L74.5006 70.799C74.7006 71.299 74.6006 71.7989 74.3006 72.1989C74.1006 72.3999 73.8006 72.5 73.4016 72.5ZM65 65.8L70.9004 68.5999L68.0002 62.8L65 65.8Z"
      fill="currentColor"
    />
    <path
      d="M28.1008 32.7001C27.7997 32.7001 27.4997 32.5991 27.2007 32.3001L24.4005 29.5001C22.4004 27.5001 22.4004 24.2001 24.4005 22.1001C25.4006 21.1001 26.7007 20.6001 28.1008 20.6001C29.4999 20.6001 30.8009 21.1001 31.8 22.1001L34.6002 24.9011C35.1002 25.4011 35.1002 26.2011 34.6002 26.7011L28.9998 32.4011C28.6998 32.6001 28.4008 32.7001 28.1008 32.7001ZM28.1008 23.2001C27.4007 23.2001 26.7006 23.5001 26.2006 24.0001C25.1005 25.1001 25.1005 26.8001 26.2006 27.8001L28.1008 29.7001L31.9 25.9001L29.9999 24.0001C29.4999 23.5001 28.7998 23.2001 28.1008 23.2001Z"
      fill="currentColor"
    />
    <path
      d="M66.2995 78.8002H31.2991C30.5991 78.8002 29.999 78.2002 29.999 77.5002C29.999 76.8002 30.5991 76.2002 31.2991 76.2002H66.2995C66.9996 76.2002 67.5987 76.8002 67.5987 77.5002C67.4996 78.2002 66.8986 78.8002 66.2995 78.8002Z"
      fill="currentColor"
    />
    <path
      d="M56.3001 73.7999H41.3011C40.601 73.7999 40.001 73.1999 40.001 72.4999C40.001 71.7999 40.601 71.2 41.3011 71.2H56.3001C57.0002 71.2 57.6002 71.7999 57.6002 72.4999C57.5002 73.1999 56.9012 73.7999 56.3001 73.7999Z"
      fill="currentColor"
    />
    <path
      d="M58.8006 27.5001H40.0003C39.3002 27.5001 38.7012 26.9001 38.7012 26.2001C38.7012 25.5001 39.3002 24.9001 40.0003 24.9001H58.8006C59.4997 24.9001 60.0997 25.5001 60.0997 26.2001C60.0007 26.9001 59.3996 27.5001 58.8006 27.5001Z"
      fill="currentColor"
    />
    <path
      d="M53.8009 32.4999H45.0003C44.3002 32.4999 43.7012 31.8999 43.7012 31.1999C43.7012 30.4999 44.3002 29.8999 45.0003 29.8999H53.8009C54.5009 29.8999 55.1 30.4999 55.1 31.1999C55.001 31.8999 54.3999 32.4999 53.8009 32.4999Z"
      fill="currentColor"
    />
  </Icon>
)
