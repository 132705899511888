import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const AgricultureIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M25.5993 65C26.6487 65 27.4994 64.1493 27.4994 63.1C27.4994 62.0506 26.6487 61.2 25.5993 61.2C24.5499 61.2 23.6992 62.0506 23.6992 63.1C23.6992 64.1493 24.5499 65 25.5993 65Z"
      fill="transparent"
    />
    <path
      d="M73.1002 57.5C74.8122 57.5 76.2004 58.888 76.2004 60.6C76.2004 62.312 74.8122 63.7 73.1002 63.7C71.3882 63.7 70.001 62.312 70.001 60.6C70.001 58.888 71.3882 57.5 73.1002 57.5Z"
      fill="transparent"
    />
    <path
      d="M48.7995 38.1997C51.2987 38.1997 53.3998 40.2997 53.3998 42.7997C53.3998 45.2997 51.2996 47.3997 48.7995 47.3997C46.2993 47.3997 44.1992 45.2997 44.1992 42.7997C44.1992 40.1997 46.1993 38.1997 48.7995 38.1997Z"
      fill="transparent"
    />
    <path d="M51.0989 51.9004V64.3004H43.8984V51.9004H48.7987H51.0989Z" fill="transparent" />
    <path
      d="M56.7003 47.3003L57.8004 45.3003L68.5001 51.5003L64.9999 57.7003L54.2002 51.5003L56.7003 47.3003Z"
      fill="transparent"
    />
    <path d="M56.7001 38.2L55.499 36.2L66.1997 30L69.7999 36.2L59.1002 42.4L56.7001 38.2Z" fill="transparent" />
    <path d="M53.6008 21.2998V33.5998H48.8005H46.4004V21.2998H53.6008Z" fill="transparent" />
    <path d="M40.7997 38.2L39.6997 40.2L28.999 34L32.4992 27.8L43.2989 34L40.7997 38.2Z" fill="transparent" />
    <path
      d="M40.801 47.3003L42.001 49.3003L31.3004 55.5003L27.7012 49.3003L38.4008 43.1003L40.801 47.3003Z"
      fill="transparent"
    />
    <path
      d="M76.1994 43.4002C76.1994 43.5002 76.2994 43.7002 76.2994 43.8002C76.2994 44.3002 75.9994 44.8002 75.3993 45.0002C74.6993 45.2002 73.9992 44.9002 73.7992 44.2002C73.7992 44.1002 73.6992 43.9002 73.6992 43.8002C73.6992 43.3002 73.9992 42.8002 74.5993 42.6002C74.6993 42.6002 74.8993 42.5002 74.9993 42.5002C75.4993 42.5002 75.9994 42.8002 76.1994 43.4002Z"
      fill="currentColor"
    />
    <path
      d="M59.8986 25.9C59.8986 26 59.9986 26.2 59.9986 26.3C59.9986 26.8 59.6986 27.3 59.0985 27.5C58.3985 27.7 57.6985 27.4 57.4984 26.7C57.4984 26.6 57.3984 26.4 57.3984 26.3C57.3984 25.8 57.6985 25.3 58.2985 25.1C58.3985 25.1 58.5985 25 58.6985 25C59.2985 25 59.7986 25.3 59.8986 25.9Z"
      fill="currentColor"
    />
    <path
      d="M33.7003 62.1C33.7003 62.2 33.8003 62.4 33.8003 62.5C33.8003 63 33.5003 63.5 32.9003 63.7C32.2002 63.9 31.5002 63.6 31.3002 62.9C31.3002 62.8 31.2002 62.6 31.2002 62.5C31.2002 62 31.5002 61.5 32.1002 61.3C32.2003 61.3 32.4003 61.2 32.5003 61.2C33.0003 61.3 33.5003 61.6 33.7003 62.1Z"
      fill="currentColor"
    />
    <path
      d="M27.4005 39.6C27.4005 39.7 27.5005 39.9 27.5005 40C27.5005 40.5 27.2005 41 26.6005 41.2C25.9004 41.4 25.2004 41.1 25.0004 40.4C25.0004 40.3 24.9004 40.1 24.9004 40C24.9004 39.5 25.2004 39 25.8004 38.8C25.9004 38.8 26.1005 38.7 26.2005 38.7C26.8005 38.8 27.3005 39.1 27.4005 39.6Z"
      fill="currentColor"
    />
    <path
      d="M26.2003 27.1002C26.2003 27.2002 26.3003 27.4002 26.3003 27.5002C26.3003 28.0002 26.0003 28.5002 25.4003 28.7002C24.7003 28.9002 24.0002 28.6002 23.8002 27.9002C23.8002 27.8002 23.7002 27.6002 23.7002 27.5002C23.7002 27.0002 24.0002 26.5002 24.6003 26.3002C24.7003 26.3002 24.9003 26.2002 25.0003 26.2002C25.4993 26.3002 25.9993 26.6002 26.2003 27.1002Z"
      fill="currentColor"
    />
    <path
      d="M48.8003 48.6C45.6002 48.6 43 46 43 42.8C43 39.6 45.6002 37 48.8003 37C51.9995 37 54.5997 39.6 54.5997 42.8C54.6007 46 52.0005 48.6 48.8003 48.6ZM48.8003 39.4C47.0002 39.4 45.5001 40.9 45.5001 42.7C45.5001 44.5 47.0002 46 48.8003 46C50.6005 46 52.1005 44.5 52.1005 42.7C52.1005 40.9 50.6005 39.4 48.8003 39.4Z"
      fill="currentColor"
    />
    <path
      d="M53.5997 34.9H46.4993C45.8002 34.9 45.2002 34.3 45.2002 33.6V21.3C45.2002 20.6 45.8002 20 46.4993 20H53.5997C54.2997 20 54.8988 20.6 54.8988 21.3V33.7C54.7998 34.3 54.2988 34.9 53.5997 34.9ZM47.6994 32.4H52.2996V22.5H47.6994V32.4Z"
      fill="currentColor"
    />
    <path
      d="M48.8001 39.4C48.1 39.4 47.5 38.8 47.5 38.1V33.6C47.5 32.9 48.1 32.3 48.8001 32.3C49.5001 32.3 50.0992 32.9 50.0992 33.6V38.1C50.0001 38.9 49.4001 39.4 48.8001 39.4Z"
      fill="currentColor"
    />
    <path
      d="M59.1008 43.5998C58.7008 43.5998 58.2008 43.3998 58.0007 42.9998L54.4005 36.7998C54.1005 36.1998 54.3005 35.3998 54.9006 35.0998L65.6012 28.8998C65.9012 28.6998 66.2012 28.6998 66.5013 28.7998C66.8013 28.8998 67.1013 29.0998 67.3013 29.3998L70.9015 35.5998C71.1015 35.8998 71.1015 36.1998 71.0015 36.4998C70.9015 36.7998 70.7015 37.0998 70.4015 37.2998L59.7018 43.4998C59.4998 43.5998 59.2998 43.5998 59.1008 43.5998ZM57.2007 36.6998L59.5008 40.6998L68.1013 35.7998L65.8012 31.7998L57.2007 36.6998Z"
      fill="currentColor"
    />
    <path
      d="M52.7008 41.7002C52.3008 41.7002 51.8007 41.5002 51.6007 41.1002C51.3007 40.5002 51.5007 39.7002 52.1008 39.4002L56.001 37.1002C56.501 36.8002 57.1011 36.9002 57.5011 37.3002C58.0011 37.8002 58.0011 38.6002 57.5011 39.1002C57.4011 39.2002 57.3011 39.2002 57.2011 39.3002L53.3008 41.6002C53.2008 41.7002 52.9008 41.7002 52.7008 41.7002Z"
      fill="currentColor"
    />
    <path
      d="M64.9998 59.0003C64.7997 59.0003 64.5997 58.9003 64.3997 58.8003L53.6991 52.6003C53.3991 52.4003 53.199 52.2003 53.099 51.8003C52.999 51.5003 53.099 51.1003 53.199 50.9003L56.7993 44.7003C56.9993 44.4003 57.1993 44.2003 57.5993 44.1003C57.8993 44.0003 58.2994 44.1003 58.4994 44.2003L69.199 50.4003C69.799 50.7003 69.9991 51.5003 69.699 52.1003L65.9988 58.3003C65.7998 58.7003 65.3998 59.0003 64.9998 59.0003ZM55.9002 51.1003L64.5007 56.0003L66.8008 52.0003L58.3013 47.0003L55.9002 51.1003Z"
      fill="currentColor"
    />
    <path
      d="M56.701 48.5999C56.501 48.5999 56.301 48.5999 56.101 48.4999C56.101 48.4999 56.101 48.4999 56.001 48.4999L52.1008 46.1999C51.5007 45.8999 51.3007 45.0999 51.6007 44.4999C51.9007 43.8999 52.7008 43.6999 53.3008 43.9999L57.2011 46.2999C57.8011 46.5999 58.0011 47.2999 57.7011 47.8999C57.6001 48.2999 57.1001 48.5999 56.701 48.5999Z"
      fill="currentColor"
    />
    <path
      d="M51.0997 65.5001H44.0003C43.3002 65.5001 42.7002 64.9001 42.7002 64.2001V51.9001C42.7002 51.2001 43.3002 50.6001 44.0003 50.6001H51.0997C51.7997 50.6001 52.3998 51.2001 52.3998 51.9001V64.3001C52.2998 65.0001 51.6997 65.5001 51.0997 65.5001ZM45.1994 63.0001H49.7996V53.1001H45.1994V63.0001Z"
      fill="currentColor"
    />
    <path
      d="M48.8001 53.1C48.1 53.1 47.5 52.5 47.5 51.8V47.3C47.5 46.6 48.1 46 48.8001 46C49.5001 46 50.0992 46.6 50.0992 47.3V51.8C50.0001 52.6 49.4001 53.1 48.8001 53.1Z"
      fill="currentColor"
    />
    <path
      d="M31.2996 56.7997C31.1996 56.7997 31.0996 56.7997 30.9995 56.7997C30.6995 56.6997 30.3995 56.4997 30.1995 56.1997L26.5993 49.9997C26.3993 49.6997 26.3993 49.3997 26.4993 49.0997C26.5993 48.7997 26.7993 48.4997 27.0993 48.2997L37.8 41.9997C38.1 41.7997 38.4 41.7997 38.7 41.8997C39 41.9997 39.3 42.1997 39.5001 42.4997L43.1003 48.6997C43.4003 49.2997 43.2003 50.0997 42.6002 50.3997L31.9006 56.5997C31.6996 56.6997 31.4996 56.7997 31.2996 56.7997ZM29.3995 49.7997L31.6996 53.7997L40.3001 48.8997L38 44.8997L29.3995 49.7997Z"
      fill="currentColor"
    />
    <path
      d="M40.7994 48.6C40.2994 48.6 39.8994 48.3 39.6994 47.9C39.3993 47.3 39.5994 46.6 40.1994 46.2L44.0986 43.9C44.6987 43.6 45.4987 43.8 45.7987 44.4C46.0987 45 45.8987 45.8 45.2987 46.1L41.3995 48.4C41.3995 48.4 41.3995 48.4 41.2995 48.4C41.1985 48.5 40.9994 48.6 40.7994 48.6Z"
      fill="currentColor"
    />
    <path
      d="M39.7009 41.4002C39.5009 41.4002 39.3009 41.3002 39.1009 41.2002L28.4003 35.0002C28.1002 34.8002 27.9002 34.6002 27.8002 34.2002C27.7002 33.9002 27.8002 33.5002 27.9002 33.3002L31.5004 27.1002C31.7004 26.8002 31.9005 26.6002 32.3005 26.5002C32.6005 26.4002 33.0005 26.5002 33.2005 26.6002L43.9012 32.8002C44.5012 33.1002 44.7012 33.9002 44.4012 34.5002L40.801 40.7002C40.5 41.2002 40.1 41.4002 39.7009 41.4002ZM30.7004 33.5002L39.2999 38.4002L41.6 34.4002L33.0005 29.5002L30.7004 33.5002Z"
      fill="currentColor"
    />
    <path
      d="M44.8002 41.6998C44.6002 41.6998 44.4002 41.5998 44.2002 41.4998L40.301 39.1998C39.7009 38.8998 39.5009 38.1998 39.8009 37.5998C40.1009 36.9998 40.901 36.6998 41.501 36.9998C41.501 36.9998 41.501 36.9998 41.601 36.9998L45.5013 39.2998C46.1013 39.5998 46.3013 40.3998 46.0013 40.9998C45.6003 41.4998 45.2003 41.6998 44.8002 41.6998Z"
      fill="currentColor"
    />
    <path
      d="M61.2989 80.0003H32.4991C32.0991 80.0003 31.6991 79.8003 31.4991 79.5003C31.2991 79.2003 31.199 78.7003 31.3991 78.3004L38.8995 59.5003C39.0995 58.9003 39.6996 58.6003 40.2996 58.7003C40.8996 58.8003 41.2996 59.3004 41.2996 59.9004V73.7003C41.2996 74.4003 40.6996 75.0003 39.9996 75.0003C39.2995 75.0003 38.6995 74.4003 38.6995 73.7003V66.4004L34.3002 77.4003H59.4997L56.1995 67.6003V73.6003C56.1995 74.3003 55.5995 74.9003 54.8995 74.9003C54.1994 74.9003 53.6004 74.3003 53.6004 73.6003V60.0003C53.6004 59.4003 54.0004 58.9004 54.6004 58.8004C55.2005 58.7004 55.8005 59.1003 56.0005 59.6003L62.2999 78.4003C62.3999 78.8003 62.3999 79.2003 62.0999 79.5003C61.9989 79.8003 61.6989 80.0003 61.2989 80.0003Z"
      fill="currentColor"
    />
    <path
      d="M45.0003 80C44.3002 80 43.7002 79.4 43.7002 78.7V70C43.7002 69.3 44.3002 68.7 45.0003 68.7C45.7003 68.7 46.3003 69.3 46.3003 70V78.8C46.3003 79.4 45.7003 80 45.0003 80Z"
      fill="currentColor"
    />
    <path
      d="M50.0003 80C49.3002 80 48.7002 79.4 48.7002 78.7V70C48.7002 69.3 49.3002 68.7 50.0003 68.7C50.7003 68.7 51.2994 69.3 51.2994 70V78.8C51.3003 79.4 50.7003 80 50.0003 80Z"
      fill="currentColor"
    />
    <path
      d="M25.6002 66.3001C23.9001 66.3001 22.5 64.9001 22.5 63.2001C22.5 61.5001 23.9001 60.1001 25.6002 60.1001C27.3003 60.1001 28.7004 61.5001 28.7004 63.2001C28.8004 64.8001 27.3003 66.3001 25.6002 66.3001ZM25.6002 62.5001C25.3002 62.5001 25.0002 62.8001 25.0002 63.1001C25.0002 63.4001 25.3002 63.7001 25.6002 63.7001C25.9002 63.7001 26.2002 63.4001 26.2002 63.1001C26.2012 62.8001 26.0002 62.5001 25.6002 62.5001Z"
      fill="currentColor"
    />
    <path
      d="M73.0995 65C70.6993 65 68.7002 63 68.7002 60.6C68.7002 58.2 70.7003 56.2 73.0995 56.2C75.4996 56.2 77.4997 58.2 77.4997 60.6C77.4997 63 75.4996 65 73.0995 65ZM73.0995 58.8C72.0994 58.8 71.1993 59.6 71.1993 60.7C71.1993 61.8 71.9994 62.6 73.0995 62.6C74.0995 62.6 74.9996 61.8 74.9996 60.7C74.9996 59.6 74.2005 58.8 73.0995 58.8Z"
      fill="currentColor"
    />
  </Icon>
)
