import {useTranslation} from 'react-i18next'
import {Props} from './types'
import {Link} from 'react-router-dom'

export const ProgramCard = ({program}: Props) => {
  const {t} = useTranslation()
  return (
    <Link
      to={`/universities-programs?tabs=programs&search=${t(program.name)}`}
      className="flex flex-col items-center bg-white hover:bg-blue text-blue-1 hover:text-white rounded-lg shadow-box-sm py-[10px] px-[6px] lg:px-6 h-auto lg:h-[200px] lg:pt-2.5 cursor-pointer duration-default"
    >
      <program.Icon className="w-10 md:w-24 h-10 md:h-24 text-orange-6" />
      <p className="pt-1.5 text-xs md:text-lg leading-4 md:leading-7.5 text-center">{t(program.name)}</p>
    </Link>
  )
}
