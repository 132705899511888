import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const FileEarmarkText = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M5 0H11.875V1.25H5C4.30964 1.25 3.75 1.80964 3.75 2.5V17.5C3.75 18.1904 4.30964 18.75 5 18.75H15C15.6904 18.75 16.25 18.1904 16.25 17.5V5.625H17.5V17.5C17.5 18.8807 16.3807 20 15 20H5C3.61929 20 2.5 18.8807 2.5 17.5V2.5C2.5 1.11929 3.61929 0 5 0Z"
      fill="currentColor"
    />
    <path d="M11.875 3.75V0L17.5 5.625H13.75C12.7145 5.625 11.875 4.78553 11.875 3.75Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15C10.3314 15 10.6 14.7015 10.6 14.3333V9.27614L11.9757 10.8047C12.2101 11.0651 12.5899 11.0651 12.8243 10.8047C13.0586 10.5444 13.0586 10.1223 12.8243 9.86193L10.4243 7.19526C10.1899 6.93491 9.81005 6.93491 9.57574 7.19526L7.17574 9.86193C6.94142 10.1223 6.94142 10.5444 7.17574 10.8047C7.41005 11.0651 7.78995 11.0651 8.02426 10.8047L9.4 9.27614V14.3333C9.4 14.7015 9.66863 15 10 15Z"
      fill="#77859C"
    />
  </Icon>
)
