import {validateURL} from '@/utils/validate-url'
import {useTranslation} from 'react-i18next'

export const useURLValidation = () => {
  const {t} = useTranslation()

  return {
    validate: (value: string) =>
      value ? (validateURL(value) ? undefined : t('error_messages.invalid_url')) : undefined,
  }
}
