import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const MedicineIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M29.9981 62.5007C31.3978 62.5007 32.4981 63.5999 32.4981 64.9997C32.4981 66.3995 31.3978 67.4998 29.9981 67.4998C28.5983 67.4998 27.498 66.3995 27.498 64.9997C27.498 63.6998 28.5983 62.5007 29.9981 62.5007Z"
      fill="transparent"
    />
    <path
      d="M69.9989 66.2998C72.0413 66.2998 73.6981 67.9566 73.6981 70C73.6981 72.0423 72.0413 73.6991 69.9989 73.6991C67.9556 73.6991 66.2988 72.0423 66.2988 70C66.2988 67.9566 67.9556 66.2998 69.9989 66.2998Z"
      fill="transparent"
    />
    <path
      d="M77.7993 38.0997C77.7993 51.0002 63.5998 58.4004 49.4993 71.2999C35.3997 58.4004 21.1992 51.1 21.1992 38.0997C21.1992 31 27.4992 25.2002 35.2998 25.2002C42.4005 25.2002 45.9 28.3991 49.4004 34.8998C52.8998 28.3991 56.5001 25.2002 63.5 25.2002C71.4993 25.2002 77.7993 31 77.7993 38.0997Z"
      fill="transparent"
    />
    <path
      d="M49.4972 72.5998C49.1977 72.5998 48.8981 72.4989 48.6974 72.2992C45.2978 69.1992 41.8973 66.3986 38.5965 63.6999C33.5965 59.6992 28.9968 55.7995 25.5972 51.7998C21.7972 47.299 19.9971 42.9 19.9971 38.0996C19.9971 30.3 26.8971 24 35.3976 24C42.5972 24 46.2973 27.1989 49.4972 32.3997C52.6971 27.1001 56.497 24 63.5977 24C72.0982 24 78.9983 30.3 78.9983 38.0996C78.9983 42.9 77.1981 47.2001 73.3982 51.7998C70.0974 55.7995 65.3979 59.6004 60.3978 63.599C57.0981 66.2987 53.6985 69.0993 50.198 72.1984C50.0972 72.4999 49.7977 72.5998 49.4972 72.5998ZM35.3976 26.5C28.2969 26.5 22.4971 31.7007 22.4971 38.2004C22.4971 47.4008 30.2977 53.7007 40.0971 61.7999C43.1972 64.2999 46.2983 66.8998 49.4972 69.8002C52.6971 66.8998 55.898 64.2999 58.8972 61.7999C68.6977 53.8006 76.4982 47.4008 76.4982 38.2004C76.4982 31.7996 70.6984 26.5 63.5977 26.5C57.4974 26.5 54.2985 28.8004 50.5984 35.4997C50.3987 35.9001 49.9984 36.1996 49.4982 36.1996C48.9979 36.1996 48.5986 35.9999 48.3989 35.4997C44.6978 28.6995 41.3981 26.5 35.3976 26.5Z"
      fill="currentColor"
    />
    <path
      d="M27.1974 42.1002C26.4965 42.1002 25.8975 41.5002 25.8975 40.8003C25.8975 34.9007 31.0972 30.1003 37.497 30.1003C38.1969 30.1003 38.797 30.7004 38.797 31.4003C38.797 32.1002 38.1959 32.7002 37.497 32.7002C32.497 32.7002 28.3975 36.3993 28.3975 40.9002C28.3975 41.6 27.8973 42.1002 27.1974 42.1002Z"
      fill="currentColor"
    />
    <path
      d="M53.6977 58.7995C53.1975 58.7995 52.6973 58.499 52.4976 57.9988L48.9972 48.3991L48.5978 50.299C48.498 50.8991 47.9977 51.2995 47.3977 51.2995H37.3976C36.6977 51.2995 36.0977 50.6994 36.0977 49.9995C36.0977 49.2996 36.6977 48.6996 37.3976 48.6996H46.3973L47.3977 43.4988C47.4985 42.9986 47.9977 42.4984 48.4979 42.4984C49.097 42.4984 49.5972 42.799 49.7969 43.2992L53.0967 52.3987L53.5969 48.5988C53.6977 47.9987 54.1969 47.4995 54.797 47.4995H59.798C60.2982 47.4995 60.7984 47.799 60.9981 48.2992L61.7978 50.3999L63.5979 39.7987C63.6988 39.1997 64.198 38.7993 64.798 38.7993C65.3991 38.7993 65.8983 39.1987 66.0989 39.6989L68.3983 48.7984H72.399C73.0988 48.7984 73.6989 49.3985 73.6989 50.0984C73.6989 50.7982 73.0988 51.3983 72.399 51.3983H67.3979C66.7989 51.3983 66.2986 50.9979 66.1978 50.4977L65.1974 46.2984L63.6978 55.398C63.597 55.997 63.1976 56.3983 62.5975 56.3983C61.9975 56.4982 61.4983 56.0978 61.2986 55.5976L59.1979 49.9975H56.0979L54.9986 57.6973C54.8978 58.2973 54.4984 58.6977 53.8984 58.7975C53.7985 58.7995 53.7985 58.7995 53.6977 58.7995Z"
      fill="currentColor"
    />
    <path
      d="M29.9982 68.8009C27.8985 68.8009 26.1982 67.1006 26.1982 64.9999C26.1982 62.9002 27.8985 61.2 29.9982 61.2C32.0979 61.2 33.7982 62.9002 33.7982 64.9999C33.7982 67.1006 32.0979 68.8009 29.9982 68.8009ZM29.9982 63.7998C29.2983 63.7998 28.6983 64.3999 28.6983 65.0998C28.6983 65.7996 29.2983 66.3987 29.9982 66.3987C30.6981 66.3987 31.2991 65.7996 31.2991 65.0998C31.2991 64.3999 30.6981 63.7998 29.9982 63.7998Z"
      fill="currentColor"
    />
    <path
      d="M69.9971 74.9996C67.1966 74.9996 64.9961 72.7991 64.9961 69.9996C64.9961 67.1991 67.1966 64.9985 69.9971 64.9985C72.7967 64.9985 74.9972 67.1991 74.9972 69.9996C74.9972 72.7991 72.7967 74.9996 69.9971 74.9996ZM69.9971 67.4996C68.5964 67.4996 67.4971 68.5988 67.4971 69.9996C67.4971 71.3994 68.5964 72.4986 69.9971 72.4986C71.3969 72.4986 72.4962 71.3994 72.4962 69.9996C72.4972 68.6997 71.3969 67.4996 69.9971 67.4996Z"
      fill="currentColor"
    />
  </Icon>
)
