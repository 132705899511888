/* eslint-disable react-hooks/exhaustive-deps */
import {Popover, PopoverContent, PopoverTrigger} from '@/components/ui/popover'
import {Props} from './type'
import {cn} from '@/lib/utils'
import {ChevronLeft2Icon} from '@/icons'
import {ChevronDownIcon, ChevronRightIcon} from '@radix-ui/react-icons'
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from '@/components/ui/select'
import {Switch} from '@/components/ui/switch'
import {Label} from '@/components/ui/label'
import {useEffect, useMemo, useState} from 'react'
import {useGetMonth} from '@/hooks/use-get-month'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'

export const DateMonthPicker = ({
  className,
  label,
  onChange,
  value,
  required,
  placeholder,
  endYear = 10,
  startYear = 40,
  switchLabel,
  presentHide,
}: Props) => {
  const [year, setYear] = useState(value ? new Date(value).getFullYear() : new Date().getFullYear())
  const [month, setMonth] = useState(value ? new Date(value).getMonth() + 1 : '')
  const [checked, setChecked] = useState<boolean>(false)
  const [dateValue, setDateValue] = useState('')
  const MONTHS = useGetMonth()
  const {t} = useTranslation()

  const YEARS = useMemo(() => {
    const date = new Date()
    const result = []
    for (let i = date.getFullYear() - startYear; i <= date.getFullYear() + endYear; i++) {
      result.push(i)
    }
    return result
  }, [startYear, endYear])

  const nextYear = () => {
    if (YEARS[YEARS.length - 1] > year) {
      setYear(prev => prev + 1)
    }
  }

  const prevYear = () => {
    if (YEARS[0] < year) {
      setYear(prev => prev - 1)
    }
  }

  useEffect(() => {
    if (!checked && onChange && year && month) {
      onChange(dayjs(new Date(`${year}-${month}-01`)).format('YYYY-MM-DD'))
      setDateValue(dayjs(new Date(`${year}-${month}-01`)).format('MMM, YYYY'))
    } else if (checked && onChange) {
      onChange('1970-01-01')
      setDateValue(t('common.present'))
    }
  }, [onChange, year, month, checked, value, t])

  useEffect(() => {
    if (new Date(dateValue).getFullYear() === 1970 && !checked) {
      setChecked(true)
      setDateValue(dayjs(new Date(`${year}-${month}-01`)).format('MMM, YYYY'))
    }
  }, [dateValue])

  return (
    <label>
      {label && (
        <span className="block mb-2.5 text-blue-1 leading-6 font-bold">
          {label} {required && <span className="text-orange">*</span>}
        </span>
      )}
      <Popover>
        <PopoverTrigger className="w-full">
          <button
            type="button"
            className={cn(`bg-blue-7 w-full rounded-lg py-4 px-6 text-xl leading-7.5 peer text-start`)}
          >
            {dateValue || t('common.select_month')}
          </button>
        </PopoverTrigger>
        <PopoverContent className={cn('max-w-[263px] shadow', className)}>
          <div className="flex justify-between p-2 items-center">
            <button onClick={prevYear} type="button" className="text-blue-2">
              <ChevronLeft2Icon className="w-4 h-4" />
            </button>
            <Select
              value={
                !isNaN(year)
                  ? (year !== 1970 && String(year)) || new Date().getFullYear().toString()
                  : new Date().getFullYear().toString()
              }
              onValueChange={date => setYear(Number(date))}
            >
              <SelectTrigger hideIcon className="w-auto border-none shadow-none">
                <div className="text-blue flex  text-xs !font-bold leading-4">
                  <SelectValue placeholder={placeholder} />
                  <ChevronDownIcon className="ml-1 w-4 h-4 text-blue-2" />
                </div>
              </SelectTrigger>
              <SelectContent className="max-h-[150px] border-none ">
                {YEARS.map(yearItem => (
                  <SelectItem className="cursor-pointer" key={yearItem} value={String(yearItem)}>
                    {yearItem}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <button onClick={nextYear} type="button" className="text-blue-2">
              <ChevronRightIcon className="w-4 h-4" />
            </button>
          </div>
          <div className="grid grid-cols-3">
            {MONTHS.map((mon, index) => (
              <button
                disabled={checked}
                onClick={() => setMonth(Number(mon.value))}
                type="button"
                className={`p-2 rounded-full capitalize ${checked ? 'text-blue-2' : ''} ${
                  month === Number(mon.value) && !checked ? 'bg-blue text-white' : ''
                } `}
                key={index}
              >
                {mon.label.slice(0, 3)}
              </button>
            ))}
          </div>

          {!presentHide && <hr className="my-3" />}
          {!presentHide && (
            <div className="flex items-center gap-3">
              <Switch
                checked={checked}
                onCheckedChange={() => setChecked(prev => !prev)}
                id="currently_work_here"
                className="data-[state=checked]:bg-blue"
              />
              <Label htmlFor="currently_work_here" className="px-4">
                {switchLabel}
              </Label>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </label>
  )
}
