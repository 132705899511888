import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const ScienceIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M27.4996 36.3C29.1997 36.3 30.5999 37.7 30.5999 39.4C30.5999 41.1 29.1997 42.5 27.4996 42.5C25.7995 42.5 24.3994 41.1 24.3994 39.4C24.3994 37.7 25.7995 36.3 27.4996 36.3Z"
      fill="transparent"
    />
    <path
      d="M30.5993 30.0002C32.2994 30.0002 33.6995 31.4002 33.6995 33.1002C33.6995 34.8002 32.2994 36.2002 30.5993 36.2002C28.8991 36.2002 27.499 34.8002 27.499 33.1002C27.499 31.4002 28.8991 30.0002 30.5993 30.0002Z"
      fill="transparent"
    />
    <path
      d="M27.4993 33.1C27.4993 34.8 26.0992 36.2 24.399 36.2C22.6989 36.2 21.2988 34.8 21.2988 33.1C21.2988 31.4 22.6989 30 24.399 30C26.0992 30 27.4993 31.4 27.4993 33.1Z"
      fill="transparent"
    />
    <path d="M51.1983 40L50.4983 44L41.5986 42.4L42.3987 38.3H42.4987L51.1983 40Z" fill="transparent" />
    <path
      d="M54.4002 22.9L53.4002 28.5L52.1011 35.6L51.7 37.4L51.2 40L42.5004 38.3001H42.4004L45.5006 21.3L54.4002 22.9Z"
      fill="transparent"
    />
    <path
      d="M54.9992 68.0999C52.599 68.6999 50.0989 68.9999 47.4987 68.6999C46.9986 68.5999 46.4986 68.5999 45.8986 68.4999C37.798 66.9999 31.6986 60.8999 29.8984 53.3999H39.4981C40.9982 56.4999 43.8984 58.8999 47.5987 59.5999C53.6991 60.6999 59.5985 56.6999 60.7986 50.4999C61.8987 44.3999 57.8984 38.4999 51.699 37.2999L51.999 35.4999L53.2981 28.3999C64.3979 30.4999 71.5984 41.0999 69.5982 52.0999C68.1991 60.1999 62.2997 66.1999 54.9992 68.0999Z"
      fill="transparent"
    />
    <path
      d="M50.5007 45.3001C50.4007 45.3001 50.3006 45.3001 50.3006 45.3001L41.401 43.6001C40.701 43.5001 40.3009 42.8001 40.4009 42.1001L44.3012 21.0001C44.4012 20.3001 45.1013 19.9001 45.8013 20.0001L54.701 21.7001C55.401 21.8001 55.801 22.5001 55.701 23.2001L53.3009 35.9001C53.2008 36.3001 53.0008 36.5001 52.8008 36.7001C53.0008 37.0001 53.1008 37.3001 53.0008 37.7001L51.8007 44.4001C51.7007 44.7001 51.6007 45.0001 51.3007 45.2001C51.0007 45.2001 50.7017 45.3001 50.5007 45.3001ZM43.1011 41.4001L49.5016 42.6001L50.5017 37.2001C50.6017 36.8001 50.8017 36.6001 51.0017 36.4001C50.8017 36.1001 50.7017 35.8001 50.8017 35.4001L53.0018 23.9001L46.6024 22.7001L43.1011 41.4001Z"
      fill="currentColor"
    />
    <path
      d="M51.2997 41.2999C51.1997 41.2999 51.0996 41.2999 51.0996 41.2999L42.4 39.5999C41.7 39.4999 41.3 38.7999 41.4 38.0999C41.5 37.3999 42.2 36.9999 42.9001 37.0999L51.5997 38.7999C52.1997 38.8999 52.5997 39.3999 52.5997 39.9999C52.4997 40.6999 51.8997 41.2999 51.2997 41.2999Z"
      fill="currentColor"
    />
    <path
      d="M46.4998 54.6999H39.4993C38.7993 54.6999 38.1992 54.0999 38.1992 53.3999C38.1992 52.6999 38.7993 52.0999 39.4993 52.0999H46.4998C47.1999 52.0999 47.7999 52.6999 47.7999 53.3999C47.7999 54.0999 47.1999 54.6999 46.4998 54.6999Z"
      fill="currentColor"
    />
    <path
      d="M29.9007 54.6999H27.2005C26.5004 54.6999 25.9004 54.0999 25.9004 53.3999C25.9004 52.6999 26.5004 52.0999 27.2005 52.0999H29.9007C30.6007 52.0999 31.2008 52.6999 31.2008 53.3999C31.2008 54.0999 30.6007 54.6999 29.9007 54.6999Z"
      fill="currentColor"
    />
    <path
      d="M41.2996 50.0001H21.9993C21.2992 50.0001 20.7002 49.4001 20.7002 48.7001C20.7002 48.0001 21.2992 47.4001 21.9993 47.4001H41.1996C41.8997 47.4001 42.4987 48.0001 42.4987 48.7001C42.4997 49.4001 41.8997 50.0001 41.2996 50.0001Z"
      fill="currentColor"
    />
    <path
      d="M54.9993 78.8001C54.2993 78.8001 53.6992 78.2 53.6992 77.5V68.1C53.6992 67.4 54.2993 66.8 54.9993 66.8C55.6994 66.8 56.2994 67.4 56.2994 68.1V77.5C56.2994 78.2 55.6994 78.8001 54.9993 78.8001Z"
      fill="currentColor"
    />
    <path
      d="M47.5003 78.7999C46.8002 78.7999 46.2002 78.1999 46.2002 77.4999V68.6999C46.2002 67.9999 46.8002 67.3999 47.5003 67.3999C48.2003 67.3999 48.8004 67.9999 48.8004 68.6999V77.4999C48.8004 78.1999 48.2003 78.7999 47.5003 78.7999Z"
      fill="currentColor"
    />
    <path
      d="M49.7014 70.0999C48.9014 70.0999 48.1013 70.0999 47.4013 69.9999C46.9012 69.8999 46.3012 69.8999 45.8011 69.7999C37.5016 68.2999 30.8011 61.9999 28.801 53.7999C28.7009 53.3999 28.8009 52.9999 29.001 52.6999C29.201 52.3999 29.601 52.1999 30.001 52.1999H39.6007C40.1007 52.1999 40.5008 52.4999 40.7008 52.8999C42.1009 55.7999 44.7011 57.7999 47.9013 58.3999C48.5003 58.4999 49.1014 58.5999 49.8014 58.5999C54.6018 58.5999 58.802 55.0999 59.7011 50.3999C60.7012 44.8999 57.1009 39.6999 51.6006 38.6999C51.3005 38.5999 51.0005 38.4999 50.8005 38.1999C50.6005 37.8999 50.5005 37.5999 50.6005 37.2999L52.3006 28.3999C52.4006 27.7999 52.9007 27.3999 53.5007 27.3999C53.6007 27.3999 53.7007 27.3999 53.7007 27.3999C65.4005 29.5999 73.2011 40.8999 71.0009 52.5999C70.2009 56.6999 68.3007 60.3999 65.5005 63.3999C62.8003 66.2999 59.3011 68.3999 55.4008 69.4999C53.5007 69.7999 51.6006 70.0999 49.7014 70.0999ZM31.6011 54.6999C33.8013 61.1999 39.4007 65.9999 46.2012 67.2999C46.7012 67.3999 47.2012 67.4999 47.7013 67.4999C50.1014 67.7999 52.5016 67.5999 54.7008 66.8999C61.7013 64.9999 67.2007 59.0999 68.5007 51.8999C70.4009 41.9999 64.1014 32.2999 54.4008 29.8999L53.2007 36.3999C59.3011 38.1999 63.2004 44.3999 62.0003 50.7999C60.9002 56.6999 55.7008 60.9999 49.7004 60.9999C48.9004 60.9999 48.2003 60.8999 47.4003 60.7999C43.8 60.0999 40.6008 57.7999 38.8007 54.5999L31.6011 54.6999Z"
      fill="currentColor"
    />
    <path
      d="M73.8 78.8002H35.0003C34.3002 78.8002 33.7002 78.2002 33.7002 77.5002C33.7002 76.8002 34.3002 76.2002 35.0003 76.2002H73.801C74.5 76.2002 75.1001 76.8002 75.1001 77.5002C75.0001 78.2002 74.4 78.8002 73.8 78.8002Z"
      fill="currentColor"
    />
    <path
      d="M77.4993 78.8C76.6992 78.8 76.2002 78.2 76.2002 77.5C76.2002 76.8 76.8002 76.2 77.4993 76.2H77.5993C78.2993 76.2 78.8994 76.8 78.8994 77.5C78.8004 78.2 78.2003 78.8 77.4993 78.8Z"
      fill="currentColor"
    />
    <path
      d="M27.4999 43.8C25.0997 43.8 23.0996 41.8 23.0996 39.4C23.0996 37 25.0997 35 27.4999 35C29.9001 35 31.9002 37 31.9002 39.4C31.9002 41.8 29.9001 43.8 27.4999 43.8ZM27.4999 37.5C26.4998 37.5 25.5998 38.3 25.5998 39.4C25.5998 40.5 26.3998 41.3 27.4999 41.3C28.6 41.3 29.4001 40.5 29.4001 39.4C29.4001 38.3 28.5 37.5 27.4999 37.5Z"
      fill="currentColor"
    />
    <path
      d="M30.5995 37.5002C28.1994 37.5002 26.1992 35.5002 26.1992 33.1002C26.1992 30.7002 28.1994 28.7002 30.5995 28.7002C32.9997 28.7002 34.9998 30.7002 34.9998 33.1002C34.9998 35.5002 32.9997 37.5002 30.5995 37.5002ZM30.5995 31.3002C29.5995 31.3002 28.6994 32.1002 28.6994 33.2002C28.6994 34.3002 29.4994 35.1002 30.5995 35.1002C31.6996 35.1002 32.4996 34.3002 32.4996 33.2002C32.4996 32.1002 31.6996 31.3002 30.5995 31.3002Z"
      fill="currentColor"
    />
    <path
      d="M24.3993 37.5C21.9991 37.5 20 35.5 20 33.1C20 30.7 22.0001 28.7 24.3993 28.7C26.7995 28.7 28.7996 30.7 28.7996 33.1C28.7996 35.5 26.7995 37.5 24.3993 37.5ZM24.3993 31.3C23.3992 31.3 22.4992 32.1 22.4992 33.2C22.4992 34.3 23.2992 35.1 24.3993 35.1C25.4994 35.1 26.2994 34.3 26.2994 33.2C26.2994 32.1 25.3994 31.3 24.3993 31.3Z"
      fill="currentColor"
    />
  </Icon>
)
