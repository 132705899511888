import {Accordion} from '@/components/ui/accordion'
import {FAQAccordion} from './components'
import {useState} from 'react'
import {useGetHomePageQuery} from '@/features/home'
import {FAQ as FAQtype} from '@/types/faq'
import {useTranslation} from 'react-i18next'

export const FAQ = () => {
  const [activeQtn, setActiveQtn] = useState('')
  const {data: {data: home} = {}} = useGetHomePageQuery('')
  const {t} = useTranslation()

  return (
    <section id="faq" className="container mx-auto pb-20">
      <div className="h-32 w-full" />
      <h2 data-aos="fade-up" className="text-4xl md:text-[50px] font-bold leading-9 md:leading-16 text-center">
        {t('home.faqs.title')}
      </h2>
      <p
        data-aos="fade-up"
        className="text-blue-1 text-sm md:text-2xl leading-5 md:leading-8.5 mt-2 md:mt-4 mb-7 md:mb-20 text-center"
      >
        {t('home.faqs.description')}
      </p>
      <div data-aos="fade-up">
        <Accordion
          type="single"
          collapsible
          value={activeQtn}
          onValueChange={setActiveQtn}
          className="flex flex-col md:gap-4 max-w-[952px] mx-auto"
        >
          {home?.faqs?.map((faq: any, index: number) => (
            <FAQAccordion key={index} faq={faq as FAQtype} isActive={activeQtn === (faq.question as any)} />
          ))}
        </Accordion>
      </div>
    </section>
  )
}
