import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ToolsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 21 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.25L1.25 0L5.10155 2.75111C5.43004 2.98574 5.625 3.36458 5.625 3.76827V3.85723C5.625 4.18875 5.7567 4.5067 5.99112 4.74112L13.125 11.875L14.2671 11.4943C14.7163 11.3446 15.2115 11.4615 15.5463 11.7963L19.7411 15.9911C20.2293 16.4793 20.2293 17.2707 19.7411 17.7589L17.7589 19.7411C17.2707 20.2293 16.4793 20.2293 15.9911 19.7411L11.7963 15.5463C11.4615 15.2115 11.3446 14.7163 11.4943 14.2671L11.875 13.125L4.74112 5.99112C4.5067 5.7567 4.18875 5.625 3.85723 5.625H3.76827C3.36458 5.625 2.98574 5.43004 2.75111 5.10155L0 1.25ZM14.1919 13.3081C13.9479 13.064 13.5521 13.064 13.3081 13.3081C13.064 13.5521 13.064 13.9479 13.3081 14.1919L17.0581 17.9419C17.3021 18.186 17.6979 18.186 17.9419 17.9419C18.186 17.6979 18.186 17.3021 17.9419 17.0581L14.1919 13.3081Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.873 2.77867C19.9558 3.08847 20 3.41408 20 3.75C20 5.82107 18.3211 7.5 16.25 7.5C15.9124 7.5 15.5851 7.45538 15.2739 7.37171L7.34699 15.1863C7.44656 15.5235 7.5 15.8805 7.5 16.25C7.5 18.3211 5.82107 20 3.75 20C1.67893 20 0 18.3211 0 16.25C0 14.1789 1.67893 12.5 3.75 12.5C4.1195 12.5 4.47651 12.5534 4.81373 12.653L12.6283 4.72612C12.5446 4.41489 12.5 4.08765 12.5 3.75C12.5 1.67893 14.1789 0 16.25 0C16.5859 0 16.9115 0.0441698 17.2213 0.127019L14.545 2.8033L15 4.99998L17.1967 5.45495L19.873 2.77867ZM3.16119 14.0525L3.75 13.75L4.33881 14.0525L5 14.0849L5.35867 14.6413L5.91506 15L5.94748 15.6612L6.25 16.25L5.94748 16.8388L5.91506 17.5L5.35867 17.8586L5 18.415L4.33881 18.4475L3.75 18.75L3.16119 18.4475L2.5 18.415L2.14133 17.8586L1.58493 17.5L1.55252 16.8388L1.25 16.25L1.55252 15.6612L1.58493 15L2.14133 14.6413L2.5 14.0849L3.16119 14.0525Z"
      fill="currentColor"
    />
  </Icon>
)
