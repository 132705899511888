import {cn} from '@/lib/utils'
import {Props} from './types'

export const TextArea = ({label, className, placeholder, baseClassName, onChange, value}: Props) => (
  <label>
    {label && <span className={cn('text-sm', className)}>{label}</span>}
    <textarea
      placeholder={placeholder}
      className={cn(
        'bg-white py-4 px-6 border border-blue-4 rounded-lg focus:border-blue-3 duration-default placeholder:text-blue-4 w-full text-xl',
        baseClassName,
      )}
      onChange={onChange}
      value={value || ''}
    />
  </label>
)
