/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-null */
import {Props} from './types'
import {cn} from '@/lib/utils'
import {
  BlogAvatarIcon,
  CompanyAvatarIcon,
  IndicatorIcon,
  ProgramAvatarIcon,
  SkillAvatar,
  UniversityAvatarIcon,
  VacancyAvatarIcon,
} from '@/icons'
import {useMemo, useState} from 'react'

export const Avatar = ({
  fullName = '',
  isOffline,
  src = '',
  className,
  showIndicator,
  imageClassName,
  type,
  avatarClassName,
}: Props) => {
  const [isErrorImage, setIsErrorImage] = useState(false)

  const handleImageError = (e: any) => {
    setIsErrorImage(true)
    e.target.style.display = 'none'
  }

  const defaultAvatar = useMemo(() => {
    switch (type) {
      case 'university':
        return <UniversityAvatarIcon className={cn('w-[60px] h-[60px]', avatarClassName)} />
      case 'blog':
        return <BlogAvatarIcon className={cn(' w-[60px] h-[60px]', avatarClassName)} />
      case 'company':
        return <CompanyAvatarIcon className={cn('w-[60px] h-[60px]', avatarClassName)} />
      case 'program':
        return <ProgramAvatarIcon className={cn('w-[60px] h-[60px]', avatarClassName)} />
      case 'skill':
        return <SkillAvatar className={cn('w-[60px] h-[60px]', avatarClassName)} />
      case 'vacancy':
        return <VacancyAvatarIcon className={cn('w-[60px] h-[60px]', avatarClassName)} />
    }
  }, [type, isErrorImage, avatarClassName, src])

  const renderImageIcon = useMemo(() => {
    if (!isErrorImage) {
      return (
        <img
          onError={handleImageError}
          className={cn('w-full block h-full object-cover rounded-full', imageClassName)}
          src={src}
          crossOrigin="anonymous"
          alt={fullName}
        />
      )
    } else {
      return defaultAvatar
    }
  }, [isErrorImage, src, type])

  return (
    <div className={cn('w-12 h-12 relative', className)}>
      {!isOffline && showIndicator && <IndicatorIcon className="absolute -top-0.5 -right-0.5 w-4 h-4 text-green-2" />}
      {renderImageIcon}
    </div>
  )
}
