import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {Props} from './types'
import {cn} from '@/lib/utils'
import {useState} from 'react'

export const TelInput = ({label, required, labelClassName, value, onChange}: Props) => {
  const [focus, setFocus] = useState(false)
  const handleNumber = (val: string) => {
    if (onChange) {
      onChange(`+${val}`)
    }
  }
  return (
    <label>
      {label && (
        <div className={cn('flex items-center mb-2.5 text-blue-1 leading-6 font-bold', labelClassName)}>
          <p className="line-clamp-1">{label}</p>
          {required && <span className="text-orange">*</span>}
        </div>
      )}
      <div className="!relative">
        <PhoneInput
          placeholder="+998901234567"
          inputClass="!pl-[60px] block !bg-blue-7 !w-full !rounded-lg !py-4 !px-6 !text-xl !leading-7.5 peer !border !border-input"
          enableSearch={true}
          country={'uz'}
          onBlur={() => setFocus(false)}
          value={value}
          onFocus={() => setFocus(true)}
          onChange={val => handleNumber(val)}
          searchClass="  !w-full !border-none "
        />
        {focus && <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue  duration-100" />}
      </div>
    </label>
  )
}
