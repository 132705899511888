import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const ManagementIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path d="M70.8002 39.4993H64.5V75.7989H70.8002V39.4993Z" fill="transparent" />
    <path d="M48.3002 49.6995H42V75.7999H48.3002V49.6995Z" fill="transparent" />
    <path d="M59.6 55.5H53.2998V75.8001H59.6V55.5Z" fill="transparent" />
    <path d="M37.101 55.5H30.8008V75.8001H37.101V55.5Z" fill="transparent" />
    <path
      d="M36.9999 76.9991C36.2997 76.9991 35.7006 76.399 35.7006 75.6988V56.6989H32.001V75.7989C32.001 76.4992 31.4009 77.0992 30.7007 77.0992C30.0004 77.0992 29.4004 76.4992 29.4004 75.7989V55.4988C29.4004 54.7986 30.0004 54.1985 30.7007 54.1985H37.0009C37.7001 54.1985 38.3012 54.7986 38.3012 55.4988V75.7989C38.3002 76.4 37.7002 76.9991 36.9999 76.9991Z"
      fill="currentColor"
    />
    <path
      d="M48.3003 76.999C47.6 76.999 47.001 76.3989 47.001 75.6987V50.8986H43.2012V75.6987C43.2012 76.3989 42.6002 76.999 41.9019 76.999C41.2017 76.999 40.6006 76.3989 40.6006 75.6987V49.6984C40.6006 48.9992 41.2017 48.4983 41.9019 48.4983H48.2021C48.9023 48.4983 49.5024 49.0984 49.5024 49.6984V75.7979C49.5004 76.3999 48.9004 76.999 48.3003 76.999Z"
      fill="currentColor"
    />
    <path
      d="M59.5003 76.9991C58.8001 76.9991 58.201 76.399 58.201 75.6988V56.6989H54.4012V75.7989C54.4012 76.4992 53.8002 77.0992 53.1019 77.0992C52.4017 77.0992 52.002 76.399 52.002 75.7989V55.4988C52.002 54.7986 52.6021 54.1985 53.3023 54.1985H59.6025C60.3027 54.1985 60.9018 54.7986 60.9018 55.4988V75.7989C60.8006 76.4 60.2006 76.9991 59.5003 76.9991Z"
      fill="currentColor"
    />
    <path
      d="M70.8003 76.999C70.101 76.999 69.5 76.399 69.5 75.6987V40.6994H65.7002V75.6997C65.7002 76.4 65.1001 77 64.3999 77C63.6997 77 63.1006 76.4 63.1006 75.6997V39.4993C63.1006 38.799 63.6997 38.199 64.3999 38.199H70.7001C71.4003 38.199 71.9994 38.7981 71.9994 39.4993V75.7989C71.9994 76.4 71.4004 76.999 70.8003 76.999Z"
      fill="currentColor"
    />
    <path
      d="M78.2993 76.9988H24.5005C23.8003 76.9988 23.1992 76.3987 23.1992 75.6985V25.6985C23.1992 24.9983 23.7993 24.3982 24.5005 24.3982C25.1998 24.3982 25.7999 24.9983 25.7999 25.6985V74.4984H78.2993C78.9995 74.4984 79.5996 75.0984 79.5996 75.7987C79.4994 76.3997 78.9003 76.9988 78.2993 76.9988Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 32.0003H20.6997C20.0004 32.0003 19.3994 31.4002 19.3994 30.6999C19.3994 29.9996 19.9995 29.3994 20.6997 29.3994H24.5005C25.1997 29.3994 25.7998 29.9996 25.7998 30.6999C25.7998 31.4002 25.1997 32.0003 24.5005 32.0003Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 35.7999H20.6997C20.0004 35.7999 19.3994 35.1998 19.3994 34.4994C19.3994 33.7991 19.9995 33.199 20.6997 33.199H24.5005C25.1997 33.199 25.7998 33.7991 25.7998 34.4994C25.7998 35.1998 25.1997 35.7999 24.5005 35.7999Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 39.4994H20.6997C20.0004 39.4994 19.3994 38.8992 19.3994 38.1989C19.3994 37.4986 19.9995 36.8984 20.6997 36.8984H24.5005C25.1997 36.8984 25.7998 37.4986 25.7998 38.1989C25.7998 38.8992 25.1997 39.4994 24.5005 39.4994Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 43.2994H20.6997C20.0004 43.2994 19.3994 42.6993 19.3994 41.999C19.3994 41.2986 19.9995 40.6985 20.6997 40.6985H24.5005C25.1997 40.6985 25.7998 41.2986 25.7998 41.999C25.7998 42.6993 25.1997 43.2994 24.5005 43.2994Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 47.0001H20.6997C20.0004 47.0001 19.3994 46.3999 19.3994 45.6996C19.3994 44.9993 19.9995 44.3992 20.6997 44.3992H24.5005C25.1997 44.3992 25.7998 44.9993 25.7998 45.6996C25.7998 46.3999 25.1997 47.0001 24.5005 47.0001Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 50.7997H20.6997C20.0004 50.7997 19.3994 50.1995 19.3994 49.4992C19.3994 48.7989 19.9995 48.1987 20.6997 48.1987H24.5005C25.1997 48.1987 25.7998 48.7989 25.7998 49.4992C25.7998 50.1995 25.1997 50.7997 24.5005 50.7997Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 54.4991H20.6997C20.0004 54.4991 19.3994 53.9 19.3994 53.1987C19.3994 52.4983 19.9995 51.8982 20.6997 51.8982H24.5005C25.1997 51.8982 25.7998 52.4983 25.7998 53.1987C25.7998 53.9 25.1997 54.4991 24.5005 54.4991Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 58.3002H20.6997C20.0004 58.3002 19.3994 57.7 19.3994 56.9997C19.3994 56.2994 19.9995 55.6992 20.6997 55.6992H24.5005C25.1997 55.6992 25.7998 56.2994 25.7998 56.9997C25.7998 57.7 25.1997 58.3002 24.5005 58.3002Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 61.9998H20.6997C20.0004 61.9998 19.3994 61.3997 19.3994 60.6994C19.3994 59.9991 19.9995 59.3989 20.6997 59.3989H24.5005C25.1997 59.3989 25.7998 59.9991 25.7998 60.6994C25.7998 61.3997 25.1997 61.9998 24.5005 61.9998Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 65.7994H20.6997C20.0004 65.7994 19.3994 65.1993 19.3994 64.499C19.3994 63.7986 19.9995 63.1985 20.6997 63.1985H24.5005C25.1997 63.1985 25.7998 63.7986 25.7998 64.499C25.7998 65.1993 25.1997 65.7994 24.5005 65.7994Z"
      fill="currentColor"
    />
    <path
      d="M24.5005 69.4998H20.6997C20.0004 69.4998 19.3994 68.8997 19.3994 68.1994C19.3994 67.4991 19.9995 66.8989 20.6997 66.8989H24.5005C25.1997 66.8989 25.7998 67.4991 25.7998 68.1994C25.7998 68.8997 25.1997 69.4998 24.5005 69.4998Z"
      fill="currentColor"
    />
    <path
      d="M30.8012 48.2991H25.8013C25.101 48.2991 24.502 47.699 24.502 46.9988C24.502 46.2986 25.101 45.6985 25.8013 45.6985H30.0008L37.2006 30.1989C37.401 29.7992 37.8006 29.4986 38.2014 29.4986C38.6021 29.4986 39.101 29.5988 39.3004 29.9985L47.001 39.8991L52.3014 32.499C52.5008 32.1994 52.8013 31.9991 53.201 31.9991C53.6007 31.9991 53.9013 32.0993 54.2008 32.3988L57.0008 35.1988L65.7013 24.5989H64.6013C63.9021 24.5989 63.302 23.9989 63.302 23.2986C63.302 22.5984 63.9021 21.9983 64.6013 21.9983H68.4021C68.902 21.9983 69.3017 22.2988 69.5011 22.6985C69.7014 23.0983 69.6013 23.6983 69.3017 23.9988L58.0006 37.7994C57.8013 38.0989 57.4015 38.1991 57.101 38.2993C56.7013 38.2993 56.4018 38.1991 56.2014 37.8996L53.5016 35.1998L48.0008 42.6991C47.8014 42.9997 47.4017 43.199 47.001 43.199C46.6013 43.199 46.2006 42.9987 46.0012 42.6991L38.5009 33.099L31.9012 47.4997C31.7008 47.9996 31.2009 48.2991 30.8012 48.2991Z"
      fill="currentColor"
    />
    <path
      d="M69.4994 28.3001C68.9995 28.3001 68.4996 27.9996 68.2992 27.3994L66.9989 23.5992C66.7996 22.8989 67.1001 22.1986 67.7993 21.9992C68.4996 21.7988 69.1998 22.0994 69.3992 22.7997L70.6985 26.6009C70.8989 27.3002 70.5983 28.0005 69.8981 28.2009C69.7999 28.1999 69.5996 28.3001 69.4994 28.3001Z"
      fill="currentColor"
    />
  </Icon>
)
