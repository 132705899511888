import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const PlusCircleIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 28.125C22.2487 28.125 28.125 22.2487 28.125 15C28.125 7.75126 22.2487 1.875 15 1.875C7.75126 1.875 1.875 7.75126 1.875 15C1.875 22.2487 7.75126 28.125 15 28.125ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5C15.5178 7.5 15.9375 7.91973 15.9375 8.4375V14.0625H21.5625C22.0803 14.0625 22.5 14.4822 22.5 15C22.5 15.5178 22.0803 15.9375 21.5625 15.9375H15.9375V21.5625C15.9375 22.0803 15.5178 22.5 15 22.5C14.4822 22.5 14.0625 22.0803 14.0625 21.5625V15.9375H8.4375C7.91973 15.9375 7.5 15.5178 7.5 15C7.5 14.4822 7.91973 14.0625 8.4375 14.0625H14.0625V8.4375C14.0625 7.91973 14.4822 7.5 15 7.5Z"
      fill="currentColor"
    />
  </Icon>
)
