import {Suspense} from 'react'
import {
  Blogs,
  CallToAction,
  FAQ,
  HowItWorks,
  Loader,
  Main,
  Programs,
  Reviews,
  SocialMedia,
  Universities,
  Vacancies,
} from './components'
export const HomePage = () => (
  <Suspense fallback={<Loader />}>
    <div>
      <Main />
      <Universities />
      <Vacancies />
      <Programs />
      <HowItWorks />
      <Reviews />
      <Blogs />
      <SocialMedia />
      <FAQ />
      <CallToAction />
    </div>
  </Suspense>
)

export default HomePage
