import {Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport} from '@/components/ui/toast'
import {useToast} from '@/components/ui/use-toast'
import {CheckCircleIcon, TimesCircleIcon, TriangleIcon} from '@/icons'

export const Toaster = () => {
  const {toasts} = useToast()

  const iconToast = (variant: string) => {
    switch (variant) {
      case 'destructive':
        return <TimesCircleIcon className="text-orange" />
      case 'warning':
        return <TriangleIcon className="text-[#E7862E]" />
      default:
        return <CheckCircleIcon className="text-green" />
    }
  }

  const toastBgColor = (variant: string) => {
    switch (variant) {
      case 'destructive':
        return 'after:bg-orange bg-orange-12'
      case 'warning':
        return 'after:bg-[#E7862E] !bg-green-[#FFF3E8]'
      default:
        return 'after:bg-green bg-green-5'
    }
  }

  return (
    <ToastProvider>
      {toasts.map(({id, title, description, action, variant, ...props}: any) => (
        <Toast
          className={`rounded-none border-none relative after:absolute  after:top-0 after:left-0 after:w-[4px] after:h-full ${toastBgColor(variant)}`}
          key={id}
          {...props}
        >
          <div className="flex items-center gap-2">
            {iconToast(variant)}
            <div className="flex justify-between items-center">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
              <ToastClose className="right-4 top-1/2  -translate-y-1/2" />
            </div>
          </div>
          {action}
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  )
}
