/* eslint-disable react-hooks/exhaustive-deps */
import {Fragment, useRef, useState, useEffect, useMemo} from 'react'
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom'
import {Button, Image, Dropdown as CommonDropdown} from '@/components/common'
import {HEADER_LINKS} from './constants/header-links'
import {Account, Dropdown, Link, Serach} from './components'
import {LinkType} from './constants/header-links/types'
import {HamburgerIcon, LeftArrowIcon, SearchIcon} from '@/icons'
import {cn} from '@/lib/utils'
import {useTranslation} from 'react-i18next'
import {GbIcon, RusIcon, UZIcon} from '@/icons/lang-icon'
import {useUserData} from '@/hooks/use-user-data'

export const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {i18n} = useTranslation()
  const [height, setHeight] = useState(0)
  const [openMobileNav, setOpenMobileNav] = useState(false)
  const [isSearch, setIsSearch] = useState(true)
  const user = useUserData()
  const fixedHeaderRef = useRef<HTMLDivElement | null>(null)

  const navigateHandeler = (path: string) => {
    setOpenMobileNav(false)
    navigate(path)
  }

  useEffect(() => {
    if (fixedHeaderRef.current?.offsetHeight) {
      setHeight(fixedHeaderRef.current?.offsetHeight)
    }
  }, [fixedHeaderRef])

  useEffect(() => {
    document.body.style.overflowY = openMobileNav ? 'hidden' : 'auto'
  }, [openMobileNav])

  useEffect(() => {
    setOpenMobileNav(false)
  }, [location, window.innerWidth])

  const languages = useMemo(
    () => [
      {
        label: (
          <span className="flex items-center gap-x-2">
            <GbIcon className="rounded" /> <span>{t('home.navbar.langs.en')}</span>
          </span>
        ),
        value: 'en',
        onClick: () => {
          localStorage.setItem('language', 'en')
          i18n.changeLanguage('en')
        },
      },
      {
        label: (
          <span className="w-full flex items-center gap-x-2">
            <UZIcon /> <span>{t('home.navbar.langs.uz')}</span>
          </span>
        ),
        value: 'uz',
        onClick: () => {
          localStorage.setItem('language', 'uz')
          i18n.changeLanguage('uz')
        },
      },
      {
        label: (
          <span className="flex items-center gap-x-2">
            <RusIcon /> <span>{t('home.navbar.langs.ru')}</span>
          </span>
        ),
        value: 'ru',
        onClick: () => {
          localStorage.setItem('language', 'ru')
          i18n.changeLanguage('ru')
        },
      },
    ],
    [i18n.resolvedLanguage, t],
  )

  const renderCurrentLanguage = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case 'en':
        return (
          <button type="button" className=" w-full flex items-center gap-2 text-blue-1 duration-default cursor-pointer">
            <GbIcon />
            EN
          </button>
        )
      case 'uz':
        return (
          <button type="button" className="flex items-center gap-2 text-blue-1 duration-default cursor-pointer">
            <UZIcon />
            UZ
          </button>
        )
      case 'ru':
        return (
          <button type="button" className="w-full flex items-center gap-2 text-blue-1 duration-default cursor-pointer">
            <RusIcon className="text-xl" /> RU
          </button>
        )
    }
  }, [i18n.resolvedLanguage])

  const searchResponsive = useMemo(() => {
    if (!isSearch && window.innerWidth < 600) {
      return 'hidden'
    }
  }, [isSearch, window.innerWidth])

  return (
    <>
      <div style={{height: height + 'px'}} className="w-full h-auto" />
      <header ref={fixedHeaderRef} className="bg-white fixed top-0 w-full z-[99] border-b border-blue-4">
        <div className=" relative container flex items-center justify-between py-4 md:py-6">
          <RouterLink to="/" className={`z-20 mr-5 ${searchResponsive}`}>
            <Image src="/images/logo.svg" alt="Student Hunter" className="w-11 md:w-[50px] h-11 md:h-[50px]" />
          </RouterLink>
          {isSearch ? (
            <div className="hidden xl:flex items-center gap-8">
              {HEADER_LINKS.map((link, index) => (
                <Fragment key={index}>
                  {(link as any).sublinks?.length ? <Dropdown {...(link as any)} /> : <Link {...(link as LinkType)} />}
                </Fragment>
              ))}
              <RouterLink to="/auth/become-a-member">
                <Button variant="outline" className="border-blue bg-white pl-4 pr-5 py-2.5">
                  {t('home.navbar.become_a_member')}
                </Button>
              </RouterLink>
            </div>
          ) : (
            <Serach isSearch={isSearch} setIsSearch={setIsSearch} />
          )}
          <div className={`flex items-center gap-4 xl:gap-7  ${searchResponsive} `}>
            <div className="flex items-center gap-6 xl:gap-7">
              {isSearch && (
                <button
                  className="border border-blue-4 text-blue-4 p-3 hover:text-blue hover:border-blue rounded"
                  aria-label="Search button"
                  onClick={() => setIsSearch(prev => !prev)}
                >
                  <SearchIcon className=" duration-default cursor-pointer " />
                </button>
              )}
              <CommonDropdown
                buttons={languages}
                className="w-32 min-w-max"
                buttonClassName="justify-start w-full text-blue-2 text-base font-medium hover:text-blue duration-default"
              >
                {renderCurrentLanguage}
              </CommonDropdown>
            </div>
            <div className="hidden xl:flex items-center gap-4">
              {user?.email ? (
                <Account />
              ) : (
                <RouterLink to="/auth/login">
                  <Button className="pl-9 pr-10">{t('home.navbar.login')}</Button>
                </RouterLink>
              )}
            </div>
            <button
              type="button"
              onClick={() => setOpenMobileNav(true)}
              className="xl:hidden bg-blue-7 rounded-full w-10 h-10 grid place-content-center"
            >
              <HamburgerIcon className="w-4 h-4 text-blue-4" />
            </button>
          </div>
        </div>
      </header>
      <div
        className={cn(
          'fixed top-0 left-full w-full h-full z-[121] overflow-y-auto duration-500',
          openMobileNav && 'left-0',
        )}
      >
        <div className="bg-blue-16 px-5 py-9">
          <button type="button" onClick={() => setOpenMobileNav(false)} aria-label="Back">
            <LeftArrowIcon className="w-7 h-7" />
          </button>
          <div className="mt-9 mb-6">
            <h4 className="text-xl font-bold leading-7.5 mb-1">{t('home.mobile_sidebar.title')}</h4>
            <p className="text-blue-1 text-xs leading-4">{t('home.mobile_sidebar.description')}</p>
          </div>
          <div className="grid grid-cols-2 gap-5">
            {Object.keys(user || {}).length ? (
              <>
                <Button
                  onClick={() => navigateHandeler('/dashboard')}
                  className="px-12 py-1 rounded font-medium leading-8.5"
                >
                  {t('home.header.go_to_dashboard')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => navigateHandeler('/auth/sign-up')}
                  variant="outline"
                  className="bg-transparent border border-blue-2 rounded px-12 py-1 font-medium leading-8.5 max-w-full"
                >
                  {t('home.navbar.signup')}
                </Button>
                <Button
                  onClick={() => navigateHandeler('/auth/login')}
                  className="px-12 py-1 rounded font-medium leading-8.5 max-w-full"
                >
                  {t('home.navbar.login')}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between gap-9 min-h-[calc(100%-260px)] bg-white px-6 py-10">
          <div className="flex flex-col gap-5">
            {HEADER_LINKS.map((link, index) => (
              <Fragment key={index}>
                {(link as any).sublinks?.length ? <Dropdown {...(link as any)} /> : <Link {...(link as LinkType)} />}
              </Fragment>
            ))}
          </div>
          <Button
            onClick={() => navigate('/auth/become-a-member')}
            variant="outline"
            className="text-xl font-medium leading-7.5 px-5 py-2.5 border border-blue rounded-lg w-max"
          >
            {t('home.navbar.become_a_member')}
          </Button>
        </div>
      </div>
    </>
  )
}
