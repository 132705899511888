import {configureStore} from '@reduxjs/toolkit'
import {baseApi} from '../features'
import chatReducer from './slices/chat/chat'
import selectedUniversityReducer from './slices/university/university'
import dashboardSearchReducer from './slices/dashboard-search/dashboard-search'

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    chat: chatReducer,
    university: selectedUniversityReducer,
    dashboardSearch: dashboardSearchReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApi.middleware),
})

export type TRootState = ReturnType<typeof store.getState>
export type TDispatch = typeof store.dispatch
