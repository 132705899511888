import {Button, Loader} from '@/components/common'
import {VacancyCard} from './components'
import {useNavigate} from 'react-router-dom'
import {Job} from '@/types/job'
import {useGetHomePageQuery} from '@/features/home'
import {useTranslation} from 'react-i18next'

export const Vacancies = () => {
  const navigate = useNavigate()
  const {data: {data: homePage = []} = {}, isLoading} = useGetHomePageQuery('')
  const {t} = useTranslation()

  if (isLoading) {
    return <Loader />
  }

  if (!homePage?.vacancies?.length) {
    return <></>
  }

  return (
    <section id="vacancies" className="px-4 md:container mx-auto mb-20">
      <h2 data-aos="fade-up" className="text-4xl md:text-[50px] leading-9 md:leading-17 font-bold text-center">
        {t('home.vacancies_of_the_day.title')}
      </h2>
      <p
        data-aos="fade-up"
        className="text-sm md:text-2xl leading-6 md:leading-8.5 text-blue-1 text-center mt-2 md:mt-3 pb-7 md:pb-14"
      >
        {t('home.vacancies_of_the_day.description')}
      </p>
      <div data-aos="fade-up">
        <div className="w-full mb-[50px]">
          <div className="items-center max-w-full scrollbar-hide overflow-x-auto lg:overflow-x-visible flex gap-6 mb-6">
            {homePage.vacancies
              ?.slice(0, 4)
              ?.map((vacancy: Job) => <VacancyCard key={vacancy._id} vacancy={vacancy} />)}
          </div>
        </div>
        <Button
          onClick={() => navigate('/jobs')}
          variant="outline"
          className="hidden md:block mx-auto text-2xl leading-8.5 px-14 py-3.5 border-2 border-blue bg-transparent"
        >
          {t('home.buttons.view_more')}
        </Button>
      </div>
    </section>
  )
}
