import {lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {NotFoundPage} from './components/pages'
import {HomePage} from './components/pages/public'
// import VacancyExams from './components/pages/private/vacancy-exams'

//  LAZY IMPORTS
const AboutPage = lazy(() => import('./components/pages/public/about'))
const BlogPage = lazy(() => import('./components/pages/public/blog'))
const BlogsPage = lazy(() => import('./components/pages/public/blogs'))
const CVBuilderPage = lazy(() => import('./components/pages/public/cv-builder'))
const CompanyPage = lazy(() => import('./components/pages/public/company'))
const ContactPage = lazy(() => import('./components/pages/public/contact'))
const JobPage = lazy(() => import('./components/pages/public/job'))
const JobsPage = lazy(() => import('./components/pages/public/jobs'))
const RankingsPage = lazy(() => import('./components/pages/public/rankings'))
const UniversityPage = lazy(() => import('./components/pages/public/university'))
const UniversitiesAndProgramsPage = lazy(() => import('./components/pages/public/universities-and-programs'))
const ProgramPage = lazy(() => import('./components/pages/public/program'))
const PrivacyPolicyPage = lazy(() => import('./components/pages/public/privacy-policy'))
const SkillPage = lazy(() => import('./components/pages/public/skill/skill'))
const SkillsPage = lazy(() => import('./components/pages/public/skill/skills'))

// AUTH PAGES
const LoginPage = lazy(() => import('./components/pages/auth/login'))
const Logout = lazy(() => import('./components/pages/auth/logout'))
const SignUpPage = lazy(() => import('./components/pages/auth/sign-up'))
const ForgotPasswordPage = lazy(() => import('./components/pages/auth/forgot-password'))
const BecomeAMemberPage = lazy(() => import('./components/pages/auth/become-a-member'))
const EmailVerificationPage = lazy(() => import('./components/pages/auth/email-verification'))
const ResetPasswordPage = lazy(() => import('./components/pages/auth/reset-password'))
const UniversitySignUpPage = lazy(() => import('./components/pages/auth/become-a-member/pages/university'))
const CompanySignUpPage = lazy(() => import('./components/pages/auth/become-a-member/pages/company'))
const ConsultingSignUpPage = lazy(() => import('./components/pages/auth/become-a-member/pages/consulting'))

// DASHBOARD PAGES
const DashboardPage = lazy(() => import('./components/pages/private/dashboard'))
const ChatPage = lazy(() => import('./components/pages/private/chat'))
const HomeEditPage = lazy(() => import('./components/pages/private/home-edit'))
const DashboardBlogsPage = lazy(() => import('./components/pages/private/blogs/blogs'))
const CreateBlogPage = lazy(() => import('./components/pages/private/blogs/create-blog'))
const DashboardUsersPage = lazy(() => import('./components/pages/private/users/users'))
const DashboardUserPage = lazy(() => import('./components/pages/private/users/user'))
const AppliedUsersPage = lazy(() => import('./components/pages/private/applied-users/applied-users'))
const AppliedUserPage = lazy(() => import('./components/pages/private/applied-users/applied-user'))
const ManagedUsersPage = lazy(() => import('./components/pages/private/managed-users/managed-users'))
const ManagedUserPage = lazy(() => import('./components/pages/private/managed-users/managed-user'))
const SavedUsersPage = lazy(() => import('./components/pages/private/saved-users/saved-users'))
const SavedUserPage = lazy(() => import('./components/pages/private/saved-users/saved-user'))
const DashboardUniversitiesPage = lazy(() => import('./components/pages/private/universities/universities'))
const DashboardUniversityPage = lazy(() => import('./components/pages/private/universities/university'))
const ConsultingsPage = lazy(() => import('./components/pages/private/consulting/consultings'))
const ConsultingPage = lazy(() => import('./components/pages/private/consulting/consulting'))
const DashboardVacanciesPage = lazy(() => import('./components/pages/private/vacancies/vacancies'))
const DashboardVacancyPage = lazy(() => import('./components/pages/private/vacancies/vacancy'))
const DashboardSkillsPage = lazy(() => import('./components/pages/private/skills/skills'))
const DashboardSkillPage = lazy(() => import('./components/pages/private/skills/skill'))
const DashboardCompaniesPage = lazy(() => import('./components/pages/private/companies/companies'))
const DashboardCompanyPage = lazy(() => import('./components/pages/private/companies/company'))
const DashboardProgramsPage = lazy(() => import('./components/pages/private/programs/programs'))
const DashboardProgramPage = lazy(() => import('./components/pages/private/programs/program'))
const ProfilePage = lazy(() => import('./components/pages/private/profile'))
const ConsultingUniversityProfilePage = lazy(() => import('./components/pages/private/consulting-university-profile'))
const SettingsPage = lazy(() => import('./components/pages/private/settings'))
const AdminsPermissionPage = lazy(() => import('./components/pages/private/permissions/admins'))
const CollegesPermissionPage = lazy(() => import('./components/pages/private/permissions/colleges'))
const CompaniesPage = lazy(() => import('./components/pages/private/permissions/companies'))
const SettingsConsultingPage = lazy(() => import('./components/pages/private/permissions/consulting'))
const Notifications = lazy(() => import('./components/pages/private/notifications'))
const ExamsPage = lazy(() => import('./components/pages/private/exams'))

// Vacancy
const VacancyExams = lazy(() => import('./components/pages/private/vacancy-exams'))
const DashboardVacnacyExamResultPage = lazy(() => import('./components/pages/private/vacancies/vacancy-exam-result'))

export const AppRouter = () => (
  <Routes>
    {/* PUBLIC PAGES */}
    <Route path="/" Component={HomePage} />
    <Route path="/about" Component={AboutPage} />
    <Route path="/contact" Component={ContactPage} />
    <Route path="/rankings" Component={RankingsPage} />
    <Route path="/cv-builder" Component={CVBuilderPage} />
    <Route path="/company/:id" Component={CompanyPage} />
    <Route path="/jobs">
      <Route path="" Component={JobsPage} />
      <Route path=":id" Component={JobPage} />
    </Route>
    <Route path="/universities-programs">
      <Route path="" Component={UniversitiesAndProgramsPage} />
      <Route path=":id" Component={UniversityPage} />
    </Route>
    <Route path="/universities">
      <Route path="" element={<Navigate to="universities-programs?tabs=universities" />} />
      <Route path=":id" Component={UniversityPage} />
    </Route>
    <Route path="/programs">
      <Route path="" element={<Navigate to="universities-programs?tabs=programs" />} />
      <Route path=":id" Component={ProgramPage} />
    </Route>
    <Route path="/blogs">
      <Route path="" Component={BlogsPage} />
      <Route path=":id" Component={BlogPage} />
    </Route>
    <Route path="/skills">
      <Route path="" Component={SkillsPage} />
      <Route path=":id" Component={SkillPage} />
    </Route>

    {/* AUTH PAGES */}
    <Route path="/auth">
      <Route path="login" Component={LoginPage} />
      <Route path="sign-up" Component={SignUpPage} />
      <Route path="forgot-password" Component={ForgotPasswordPage} />
      <Route path="become-a-member">
        <Route path="" Component={BecomeAMemberPage} />
        <Route path="university" Component={UniversitySignUpPage} />
        <Route path="company" Component={CompanySignUpPage} />
        <Route path="consulting" Component={ConsultingSignUpPage} />
      </Route>
      <Route path="verify" Component={EmailVerificationPage} />
      <Route path="logout" Component={Logout} />
    </Route>
    <Route path="reset-password" Component={ResetPasswordPage} />

    {/* PRIVATE PAGES */}
    <Route path="/dashboard">
      <Route path="" Component={DashboardPage} />
      <Route path="chat" Component={ChatPage} />
      <Route path="home-edit" Component={HomeEditPage} />
      <Route path="blogs">
        <Route path="" Component={DashboardBlogsPage} />
        <Route path="new" Component={CreateBlogPage} />
      </Route>
      <Route path="users">
        <Route path="" Component={DashboardUsersPage} />
        <Route path=":id" Component={DashboardUserPage} />
      </Route>
      <Route path="applied-users">
        <Route path="" Component={AppliedUsersPage} />
        <Route path=":id" Component={AppliedUserPage} />
      </Route>
      <Route path="managed-users">
        <Route path="" Component={ManagedUsersPage} />
        <Route path=":id" Component={ManagedUserPage} />
      </Route>
      <Route path="saved-users">
        <Route path="" Component={SavedUsersPage} />
        <Route path=":id" Component={SavedUserPage} />
      </Route>
      <Route path="universities">
        <Route path="" Component={DashboardUniversitiesPage} />
        <Route path=":id" Component={DashboardUniversityPage} />
      </Route>
      <Route path="consultings">
        <Route path="" Component={ConsultingsPage} />
        <Route path=":id" Component={ConsultingPage} />
      </Route>
      <Route path="vacancies">
        <Route path="" Component={DashboardVacanciesPage} />
        <Route path=":id">
          <Route index Component={DashboardVacancyPage} />
          <Route path="results/:examID" Component={DashboardVacnacyExamResultPage} />
        </Route>
      </Route>
      <Route path="skills">
        <Route path="" Component={DashboardSkillsPage} />
        <Route path=":id" Component={DashboardSkillPage} />
      </Route>
      <Route path="companies">
        <Route path="" Component={DashboardCompaniesPage} />
        <Route path=":id" Component={DashboardCompanyPage} />
      </Route>
      <Route path="programs">
        <Route path="" Component={DashboardProgramsPage} />
        <Route path=":id" Component={DashboardProgramPage} />
      </Route>
      <Route path="profile">
        <Route path="" Component={ProfilePage} />
        <Route path="university" Component={ConsultingUniversityProfilePage} />
      </Route>
      <Route path="settings">
        <Route path="" Component={SettingsPage} />
        <Route path="admins" Component={AdminsPermissionPage} />
        <Route path="colleges" Component={CollegesPermissionPage} />
        <Route path="companies" Component={CompaniesPage} />
        <Route path="consulting" Component={SettingsConsultingPage} />
      </Route>
      <Route path="notifications" Component={Notifications} />
    </Route>

    <Route path="/exams/:id" Component={ExamsPage} />

    <Route path="/vacancy-exams/:id" Component={VacancyExams} />

    <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
    <Route path="*" Component={NotFoundPage} />
  </Routes>
)
