import {Props} from './types'
import {cn} from '@/lib/utils'
import {Switch as UISwitch} from '@/components/ui/switch'

export const Switch = ({onChange, className, value}: Props) => (
  <UISwitch
    checked={value}
    onCheckedChange={onChange}
    circleClassName="w-[35px] shadow-none h-[35px] bg-white data-[state=checked]:translate-x-[39px] data-[state=unchecked]:translate-x-[2px]"
    className={cn(
      'w-[80px] h-[40px]  rounded-full data-[state=checked]:bg-blue data-[state=unchecked]:bg-blue-5',
      className,
    )}
  />
)
