import {Field} from '@/components/common'
import {Props} from './types'
import {useValidationOptions} from '@/hooks/use-validation-options'
import {COUNTRIES} from '@/constants/countries'
import {STUDY_LEVELS} from '@/constants/study-levels'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {cn} from '@/lib/utils'
import {CheckIcon, XIcon} from '@/icons'

export const Overview = ({form}: Props) => {
  const getValidationOptions = useValidationOptions()
  const {t} = useTranslation()
  const validatePassword = (password: string) => {
    const lengthValid = password?.length >= 10 && password?.length <= 32
    const uppercaseValid = /[A-Z]/.test(password)
    const lowercaseValid = /[a-z]/.test(password)
    const numberValid = /\d/.test(password)
    const specialValid = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)

    return {
      length: lengthValid,
      uppercase: uppercaseValid,
      lowercase: lowercaseValid,
      number: numberValid,
      special: specialValid,
    }
  }

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: true,
  })

  const [showValidation, setShowValidation] = useState(false)

  useEffect(() => {
    const password = form.watch('password')
    if (password) {
      setShowValidation(true)
    }
    const validation = validatePassword(password)
    setPasswordValidation(validation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('password')])
  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-x-6 gap-y-10">
      <Field
        name="title"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.university_name'),
          placeholder: t('dashboard.universities.active_universities_page.hult_international_univer'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="study_level"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.study_level'),
          required: true,
          options: STUDY_LEVELS,
        }}
        control={form.control}
        type="checkboxes-dropdown"
        rules={getValidationOptions(true)}
      />
      <Field
        name="country"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.country'),
          required: true,
          options: COUNTRIES.map(country => ({label: country.name, value: country.name})),
        }}
        control={form.control}
        type="select"
        rules={getValidationOptions(true)}
      />
      <Field
        name="city"
        componentProps={{
          label: 'State',
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="contact_person"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.contact_person_name'),
          placeholder: t('dashboard.universities.active_universities_page.contact_person_name'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="contact_role"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.contact_person_position'),
          placeholder: t('dashboard.universities.active_universities_page.contact_person_position'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="programs_count"
        componentProps={{
          type: 'number',
          label: t('dashboard.universities.active_universities_page.programs'),
          placeholder: t('dashboard.universities.active_universities_page.programs_count'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="rating"
        componentProps={{
          type: 'number',
          label: t('dashboard.universities.active_universities_page.rating'),
          placeholder: t('dashboard.universities.active_universities_page.university_rating'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="tuition_fee"
        type="second-input"
        componentProps={{
          type: 'price',
          label: t('dashboard.universities.active_universities_page.tuition_fee_usd'),
          placeholder: t('dashboard.universities.active_universities_page.tuition_fee'),
          required: true,
        }}
        control={form.control}
        rules={getValidationOptions(true)}
      />
      <Field
        name="phone_number"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.telephone_number'),
          required: true,
        }}
        control={form.control}
        type="tel-input"
        rules={getValidationOptions(true)}
      />
      <Field
        name="email"
        componentProps={{
          label: t('dashboard.universities.active_universities_page.university_email'),
          placeholder: t('dashboard.universities.active_universities_page.university_email'),
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true, 3, 100, {
          validate: (email: string) =>
            !/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) ? t('common.please_enter_correct_email') : undefined,
        })}
      />
      <Field
        name="password"
        componentProps={{
          type: 'password',
          label: t('dashboard.universities.active_universities_page.password'),
          placeholder: '******',
          required: true,
        }}
        control={form.control}
        type="second-input"
        rules={getValidationOptions(true, 1, 100, {
          validate: (password: string) =>
            !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{10,32}$/.test(password)
              ? t('common.please_enter_correct_password')
              : undefined,
        })}
      />
      <div className="lg:block hidden"></div>
      {showValidation ? (
        <div>
          <div className={cn('flex items-center gap-1', passwordValidation.length ? 'text-green-500' : 'text-red-500')}>
            {passwordValidation.length ? <CheckIcon className="w-3 h-3 text-green-2" /> : <XIcon className="w-4 h-4" />}
            <p>{t('validation.10_32_characters')}</p>
          </div>
          <div
            className={cn('flex items-center gap-1', passwordValidation.uppercase ? 'text-green-500' : 'text-red-500')}
          >
            {passwordValidation.uppercase ? (
              <CheckIcon className="w-3 h-3 text-green-2" />
            ) : (
              <XIcon className="w-4 h-4" />
            )}
            <p>{t('validation.at_least_one_uppercase')}</p>
          </div>
          <div
            className={cn('flex items-center gap-1', passwordValidation.lowercase ? 'text-green-500' : 'text-red-500')}
          >
            {passwordValidation.lowercase ? (
              <CheckIcon className="w-3 h-3 text-green-2" />
            ) : (
              <XIcon className="w-4 h-4" />
            )}
            <p>{t('validation.at_least_one_lowercase')}</p>
          </div>
          <div className={cn('flex items-center gap-1', passwordValidation.number ? 'text-green-500' : 'text-red-500')}>
            {passwordValidation.number ? <CheckIcon className="w-3 h-3 text-green-2" /> : <XIcon className="w-4 h-4" />}
            <p>{t('validation.at_least_one_number')}</p>
          </div>
          <div
            className={cn('flex items-center gap-1', passwordValidation.special ? 'text-green-500' : 'text-red-500')}
          >
            {passwordValidation.special ? (
              <CheckIcon className="w-3 h-3 text-green-2" />
            ) : (
              <XIcon className="w-4 h-4" />
            )}
            <p>{t('validation.at_least_one_special_character')}</p>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
