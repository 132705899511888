export const useGetMonth = () => [
  {label: 'january', value: '01'},
  {label: 'february', value: '02'},
  {label: 'march', value: '03'},
  {label: 'april', value: '04'},
  {label: 'may', value: '05'},
  {label: 'june', value: '06'},
  {label: 'july', value: '07'},
  {label: 'august', value: '08'},
  {label: 'september', value: '09'},
  {label: 'october', value: '10'},
  {label: 'november', value: '11'},
  {label: 'december', value: '12'},
]
