import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const DashboardHomeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.77739 8.20173C3.16227 8.21991 3.45942 8.54424 3.44109 8.92612L3.25583 12.7863C3.25584 14.1023 3.2577 15.0216 3.35178 15.7159C3.44355 16.3931 3.61141 16.7518 3.86886 17.0072C4.12631 17.2627 4.48777 17.4292 5.17035 17.5203C5.873 17.614 6.80427 17.6155 8.13953 17.6155H11.8605C13.1957 17.6155 14.127 17.614 14.8296 17.5203C15.5122 17.4292 15.8737 17.2627 16.1311 17.0072C16.3886 16.7518 16.5564 16.3931 16.6482 15.7159C16.7427 15.0187 16.7442 14.0947 16.7442 12.7698V9.21379C16.7442 8.83148 17.0565 8.52155 17.4418 8.52155C17.8271 8.52155 18.1395 8.83148 18.1395 9.21379V12.8204C18.1395 14.0827 18.1395 15.1002 18.0311 15.9004C17.9185 16.7312 17.6777 17.4307 17.1178 17.9862C16.5579 18.5418 15.8529 18.7808 15.0156 18.8925C14.2091 19 13.1837 19 11.9115 19H8.08848C6.81631 19 5.7909 19 4.98443 18.8925C4.14712 18.7808 3.44212 18.5418 2.8822 17.9862C2.32228 17.4307 2.08145 16.7312 1.96888 15.9004C1.86045 15.1002 1.86046 14.0827 1.86048 12.8204L1.86048 12.7698C1.86048 12.7588 1.86075 12.7478 1.86127 12.7369L2.04733 8.86026C2.06565 8.47838 2.39252 8.18354 2.77739 8.20173Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90095 2.7647C8.41197 3.05851 7.83067 3.50805 6.99179 4.15887L1.12745 8.70861C0.823908 8.94411 0.385438 8.89086 0.148097 8.58968C-0.0892439 8.28851 -0.03558 7.85345 0.267959 7.61795L6.16662 3.04159C6.96292 2.42377 7.60944 1.92216 8.17817 1.58043C8.77111 1.22416 9.34468 1 10 1C10.6553 1 11.2289 1.22416 11.8218 1.58043C12.3906 1.92216 13.0371 2.42376 13.8334 3.04158L19.732 7.61795C20.0356 7.85345 20.0892 8.28851 19.8519 8.58968C19.6146 8.89086 19.1761 8.94411 18.8726 8.70861L13.0082 4.15887C12.1693 3.50805 11.588 3.05852 11.099 2.7647C10.6261 2.48055 10.3062 2.38449 10 2.38449C9.6938 2.38449 9.37387 2.48055 8.90095 2.7647Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.51163 13.6928C6.51163 13.3105 6.82399 13.0005 7.20931 13.0005H12.7907C13.176 13.0005 13.4884 13.3105 13.4884 13.6928C13.4884 14.0751 13.176 14.385 12.7907 14.385H7.20931C6.82399 14.385 6.51163 14.0751 6.51163 13.6928Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.51163 10.0008C6.51163 9.6185 6.82399 9.30857 7.20931 9.30857H12.7907C13.176 9.30857 13.4884 9.6185 13.4884 10.0008C13.4884 10.3831 13.176 10.6931 12.7907 10.6931H7.20931C6.82399 10.6931 6.51163 10.3831 6.51163 10.0008Z"
      fill="currentColor"
    />
  </Icon>
)
