import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const DashboardIcon = ({...props}: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_951_8923)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 12.5H2.5C1.80964 12.5 1.25 13.0596 1.25 13.75V17.5C1.25 18.1904 1.80964 18.75 2.5 18.75H6.25C6.94036 18.75 7.5 18.1904 7.5 17.5V13.75C7.5 13.0596 6.94036 12.5 6.25 12.5ZM2.5 11.25C1.11929 11.25 0 12.3693 0 13.75V17.5C0 18.8807 1.11929 20 2.5 20H6.25C7.63071 20 8.75 18.8807 8.75 17.5V13.75C8.75 12.3693 7.63071 11.25 6.25 11.25H2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 1.25H2.5C1.80964 1.25 1.25 1.80964 1.25 2.5V6.25C1.25 6.94036 1.80964 7.5 2.5 7.5H6.25C6.94036 7.5 7.5 6.94036 7.5 6.25V2.5C7.5 1.80964 6.94036 1.25 6.25 1.25ZM2.5 0C1.11929 0 0 1.11929 0 2.5V6.25C0 7.63071 1.11929 8.75 2.5 8.75H6.25C7.63071 8.75 8.75 7.63071 8.75 6.25V2.5C8.75 1.11929 7.63071 0 6.25 0H2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 12.5H13.75C13.0596 12.5 12.5 13.0596 12.5 13.75V17.5C12.5 18.1904 13.0596 18.75 13.75 18.75H17.5C18.1904 18.75 18.75 18.1904 18.75 17.5V13.75C18.75 13.0596 18.1904 12.5 17.5 12.5ZM13.75 11.25C12.3693 11.25 11.25 12.3693 11.25 13.75V17.5C11.25 18.8807 12.3693 20 13.75 20H17.5C18.8807 20 20 18.8807 20 17.5V13.75C20 12.3693 18.8807 11.25 17.5 11.25H13.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 1.25H13.75C13.0596 1.25 12.5 1.80964 12.5 2.5V6.25C12.5 6.94036 13.0596 7.5 13.75 7.5H17.5C18.1904 7.5 18.75 6.94036 18.75 6.25V2.5C18.75 1.80964 18.1904 1.25 17.5 1.25ZM13.75 0C12.3693 0 11.25 1.11929 11.25 2.5V6.25C11.25 7.63071 12.3693 8.75 13.75 8.75H17.5C18.8807 8.75 20 7.63071 20 6.25V2.5C20 1.11929 18.8807 0 17.5 0H13.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_951_8923">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
