import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const NaturalScienceIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M48.7992 52.1V28.1C48.7992 24.6 45.999 21.8 42.4998 21.8C40.7996 21.8 39.1995 22.5 38.0995 23.6C37.0004 24.7 36.3003 26.3 36.3003 28V52C31.8 54.3 28.7998 58.9 28.7998 64.3C28.7998 71.9 34.8002 78 42.3997 78C50.0003 78.2 56.2997 72 56.2997 64.4C56.2987 59 53.1985 54.4 48.7992 52.1Z"
      fill="transparent"
    />
    <path
      d="M26.8992 34.4001C27.9487 34.4001 28.7983 35.2511 28.7983 36.3001C28.7983 37.3501 27.9487 38.2001 26.8992 38.2001C25.8486 38.2001 24.999 37.3491 24.999 36.3001C24.999 35.2511 25.8486 34.4001 26.8992 34.4001Z"
      fill="transparent"
    />
    <path
      d="M68.798 69.3999C70.1793 69.3999 71.2982 70.5199 71.2982 71.8999C71.2982 73.2809 70.1783 74.3999 68.798 74.3999C67.4177 74.3999 66.2988 73.2809 66.2988 71.8999C66.2988 70.5199 67.4177 69.3999 68.798 69.3999Z"
      fill="transparent"
    />
    <path
      d="M64.7988 38.3999C67.499 38.3999 69.6981 40.5999 69.6981 43.2999C69.6981 45.9999 67.498 48.1999 64.7988 48.1999C62.0976 48.1999 59.8984 45.9999 59.8984 43.2999C59.8984 40.5999 62.0986 38.3999 64.7988 38.3999Z"
      fill="transparent"
    />
    <path
      d="M75.8994 61.0001C76.0994 61.2001 76.2994 61.5001 76.2994 61.9001C76.2994 62.3001 76.1984 62.6001 75.8994 62.8001C75.5993 63.0001 75.3993 63.2001 74.9993 63.2001C74.2993 63.2001 73.7002 62.6001 73.7002 61.9001C73.7002 61.6001 73.8002 61.2001 74.0992 61.0001C74.4003 60.8001 74.5993 60.6001 74.9993 60.6001C75.3993 60.6001 75.6994 60.8001 75.8994 61.0001Z"
      fill="currentColor"
    />
    <path
      d="M73.4004 22.2C73.5994 22.4 73.7994 22.7 73.7994 23.1C73.7994 23.5 73.6994 23.8 73.4004 24C73.0994 24.2 72.8994 24.4 72.5003 24.4C71.8003 24.4 71.1992 23.8 71.1992 23.1C71.1992 22.8 71.3002 22.4 71.5993 22.2C71.7993 22 72.0993 21.8 72.4993 21.8C72.8994 21.8 73.2004 22 73.4004 22.2Z"
      fill="currentColor"
    />
    <path
      d="M60.8984 68.5001C61.0984 68.7001 61.2984 69.0001 61.2984 69.4001C61.2984 69.7001 61.1984 70.1001 60.8984 70.3001C60.5984 70.5001 60.3984 70.7001 59.9993 70.7001C59.2993 70.7001 58.6982 70.1001 58.6982 69.4001C58.6982 69.1001 58.7992 68.7001 59.0983 68.5001C59.2983 68.3001 59.5983 68.1001 59.9984 68.1001C60.3984 68.1001 60.6984 68.3001 60.8984 68.5001Z"
      fill="currentColor"
    />
    <path
      d="M57.0996 27.2C57.2986 27.4 57.4986 27.7 57.4986 28.1C57.4986 28.4 57.3986 28.8 57.0996 29C56.7986 29.2 56.5986 29.4 56.1995 29.4C55.4995 29.4 54.8984 28.8 54.8984 28.1C54.8984 27.8 54.9994 27.4 55.2985 27.2C55.5985 27 55.7985 26.8 56.1985 26.8C56.5985 26.8 56.8996 27 57.0996 27.2Z"
      fill="currentColor"
    />
    <path
      d="M30.8984 23.5001C31.0984 23.7001 31.2984 24.0001 31.2984 24.4001C31.2984 24.8001 31.1974 25.1001 30.8984 25.3001C30.5984 25.5001 30.3984 25.7001 29.9983 25.7001C29.2983 25.7001 28.6992 25.1001 28.6992 24.4001C28.6992 24.1001 28.7992 23.7001 29.0992 23.5001C29.2983 23.3001 29.5993 23.1001 29.9983 23.1001C30.3984 23.1001 30.6984 23.3001 30.8984 23.5001Z"
      fill="currentColor"
    />
    <path
      d="M30.8984 44.7C31.0984 44.9 31.2984 45.2 31.2984 45.6C31.2984 46 31.1974 46.3 30.8984 46.5C30.5984 46.7 30.3984 46.9 29.9983 46.9C29.2983 46.9 28.6992 46.3 28.6992 45.6C28.6992 45.3 28.7992 44.9 29.0992 44.7C29.2983 44.5 29.5993 44.3 29.9983 44.3C30.3984 44.3 30.6984 44.5 30.8984 44.7Z"
      fill="currentColor"
    />
    <path
      d="M25.8984 71.0001C26.0984 71.2001 26.2984 71.5001 26.2984 71.9001C26.2984 72.3001 26.1984 72.6001 25.8984 72.8001C25.5984 73.0001 25.3984 73.2001 24.9993 73.2001C24.2993 73.2001 23.6982 72.6001 23.6982 71.9001C23.6982 71.6001 23.7992 71.2001 24.0983 71.0001C24.2983 70.8001 24.5983 70.6001 24.9983 70.6001C25.3983 70.6001 25.6994 70.8001 25.8984 71.0001Z"
      fill="currentColor"
    />
    <path
      d="M42.5001 79.4001C42.3991 79.4001 42.3991 79.4001 42.3001 79.4001C38.2998 79.3001 34.6005 77.8001 31.8003 75.0001C29.0001 72.2001 27.5 68.4001 27.5 64.4001C27.5 59.0001 30.4002 54.1001 34.9995 51.4001V28.1001C34.9995 26.1001 35.8006 24.2001 37.1997 22.8001C38.5998 21.4001 40.4999 20.6001 42.501 20.6001C46.6013 20.6001 50.0006 24.0001 50.0006 28.1001V51.4001C54.5999 54.1001 57.5001 59.0001 57.5001 64.4001C57.5001 68.4001 55.901 72.2001 53.1008 75.0001C50.2996 77.8001 46.4994 79.4001 42.5001 79.4001ZM42.5001 23.1001C41.2 23.1001 39.8999 23.6001 38.9998 24.6001C38.0998 25.5001 37.4997 26.8001 37.4997 28.1001V52.1001C37.4997 52.6001 37.1987 53.0001 36.7997 53.2001C32.5994 55.3001 29.9992 59.6001 29.9992 64.3001C29.9992 71.2001 35.3996 76.7001 42.3001 76.8001C42.4001 76.8001 42.4001 76.8001 42.5001 76.8001C45.7993 76.8001 48.9995 75.5001 51.2997 73.1001C53.6999 70.7001 54.9999 67.6001 54.9999 64.3001C54.9999 59.6001 52.3997 55.3001 48.1995 53.2001C47.8004 53.0001 47.4994 52.6001 47.4994 52.1001V28.1001C47.4994 25.4001 45.2993 23.1001 42.5001 23.1001Z"
      fill="currentColor"
    />
    <path
      d="M42.4998 73.1C37.6995 73.1 33.6992 69.2 33.6992 64.3C33.6992 59.4 37.5985 55.5 42.4998 55.5C47.3992 55.5 51.2994 59.4 51.2994 64.3C51.2985 69.2 47.2992 73.1 42.4998 73.1ZM42.4998 58.1C39.0986 58.1 36.1994 60.9 36.1994 64.4C36.1994 67.9 38.9996 70.7 42.4998 70.7C45.9991 70.7 48.7993 67.9 48.7993 64.4C48.7993 60.9 45.8991 58.1 42.4998 58.1Z"
      fill="currentColor"
    />
    <path
      d="M42.4995 68.1C40.3993 68.1 38.6992 66.4 38.6992 64.3C38.6992 63.6 39.2983 63 39.9983 63C40.6984 63 41.2984 63.6 41.2984 64.3C41.2984 65 41.8975 65.6 42.5975 65.6C43.2976 65.6 43.8966 66.2 43.8966 66.9C43.7986 67.6 43.1995 68.1 42.4995 68.1Z"
      fill="currentColor"
    />
    <path
      d="M42.4993 58.1001C41.7993 58.1001 41.1992 57.5001 41.1992 56.8001V29.4001C41.1992 28.7001 41.7993 28.1001 42.4993 28.1001C43.1994 28.1001 43.7984 28.7001 43.7984 29.4001V56.9001C43.7984 57.6001 43.1994 58.1001 42.4993 58.1001Z"
      fill="currentColor"
    />
    <path
      d="M64.7987 49.4C61.3975 49.4 58.6982 46.7 58.6982 43.3C58.6982 39.9 61.3984 37.2 64.7987 37.2C68.1989 37.2 70.8981 39.9 70.8981 43.3C70.8981 46.7 68.1989 49.4 64.7987 49.4ZM64.7987 39.7C62.7975 39.7 61.1984 41.3 61.1984 43.3C61.1984 45.3 62.7975 46.9 64.7987 46.9C66.7988 46.9 68.3989 45.3 68.3989 43.3C68.3989 41.3 66.7988 39.7 64.7987 39.7Z"
      fill="currentColor"
    />
    <path
      d="M64.7991 36C64.0991 36 63.499 35.4 63.499 34.7V31.8C63.499 31.1 64.0991 30.5 64.7991 30.5C65.4992 30.5 66.0982 31.1 66.0982 31.8V34.7C66.0992 35.5 65.4992 36 64.7991 36Z"
      fill="currentColor"
    />
    <path
      d="M69.1 37.1999C68.9 37.1999 68.7 37.0999 68.5 36.9999C67.9009 36.6999 67.6999 35.8999 67.9999 35.2999L69.5001 32.7999C69.8001 32.1999 70.6001 31.9999 71.2002 32.2999C71.8012 32.5999 72.0012 33.3999 71.7002 33.9999L70.2001 36.4999C69.9001 36.9999 69.4991 37.1999 69.1 37.1999Z"
      fill="currentColor"
    />
    <path
      d="M72.1998 40.2999C71.7998 40.2999 71.2998 40.0999 71.0998 39.6999C70.7997 39.0999 70.9997 38.2999 71.5998 37.9999L74.099 36.4999C74.7 36.1999 75.4991 36.3999 75.7991 36.9999C76.1001 37.5999 75.9001 38.3999 75.2991 38.6999L72.7999 40.1999C72.5989 40.1999 72.3989 40.2999 72.1998 40.2999Z"
      fill="currentColor"
    />
    <path
      d="M76.2989 44.6H73.3987C72.6987 44.6 72.0977 44 72.0977 43.3C72.0977 42.6 72.6977 42 73.3987 42H76.2989C76.999 42 77.599 42.6 77.599 43.3C77.499 44 76.899 44.6 76.2989 44.6Z"
      fill="currentColor"
    />
    <path
      d="M74.6988 50.3C74.4988 50.3 74.2988 50.2 74.0978 50.1L71.5986 48.6C70.9996 48.3 70.7986 47.5 71.0986 46.9C71.3986 46.3 72.1987 46.1 72.7987 46.4L75.2979 47.9C75.8979 48.2 76.0989 49 75.7979 49.6C75.5989 50 75.0998 50.3 74.6988 50.3Z"
      fill="currentColor"
    />
    <path
      d="M70.4983 54.5C70.0993 54.5 69.5993 54.3 69.3993 53.9L67.9992 51.3C67.6991 50.7 67.8992 49.9 68.4992 49.6C69.0992 49.3 69.8993 49.5 70.1993 50.1L71.6994 52.6C72.0004 53.2 71.8004 54 71.1994 54.3C70.8984 54.4 70.6983 54.5 70.4983 54.5Z"
      fill="currentColor"
    />
    <path
      d="M64.7991 56C64.0991 56 63.499 55.4 63.499 54.7V51.8C63.499 51.1 64.0991 50.5 64.7991 50.5C65.4992 50.5 66.0982 51.1 66.0982 51.8V54.7C66.0992 55.4 65.4992 56 64.7991 56Z"
      fill="currentColor"
    />
    <path
      d="M59.0991 54.5C58.899 54.5 58.699 54.4 58.498 54.3C57.899 54 57.698 53.2 57.998 52.6L59.4981 50.1C59.7991 49.5 60.5982 49.3 61.1992 49.6C61.7992 49.9 61.9993 50.7 61.6992 51.3L60.1982 53.8C59.8991 54.2 59.4991 54.5 59.0991 54.5Z"
      fill="currentColor"
    />
    <path
      d="M54.8987 50.3001C54.4997 50.3001 53.9996 50.1001 53.7996 49.7001C53.4986 49.1001 53.6986 48.3001 54.2996 48.0001L56.7988 46.5001C57.3998 46.2001 58.1989 46.4001 58.4989 47.0001C58.8 47.6001 58.5999 48.4001 57.9989 48.7001L55.4987 50.2001C55.2987 50.2001 55.0987 50.3001 54.8987 50.3001Z"
      fill="currentColor"
    />
    <path
      d="M56.2989 44.6H53.3987C52.6987 44.6 52.0996 44 52.0996 43.3C52.0996 42.6 52.6987 42 53.3987 42H56.2989C56.999 42 57.599 42.6 57.599 43.3C57.499 44 56.999 44.6 56.2989 44.6Z"
      fill="currentColor"
    />
    <path
      d="M57.3997 40.3C57.1997 40.3 56.9996 40.2 56.7986 40.1L54.2994 38.6C53.6994 38.3 53.4984 37.5 53.7994 36.9C54.0994 36.3 54.8985 36.1 55.4995 36.4L57.9997 37.9C58.5997 38.2 58.8008 39 58.4997 39.6C58.2997 40.1 57.8987 40.3 57.3997 40.3Z"
      fill="currentColor"
    />
    <path
      d="M60.5994 37.1999C60.1993 37.1999 59.6993 36.9999 59.4993 36.5999L57.9992 33.9999C57.6991 33.3999 57.8992 32.5999 58.4992 32.2999C59.1002 31.9999 59.8993 32.1999 60.1993 32.7999L61.7004 35.2999C62.0004 35.8999 61.8004 36.6999 61.2004 36.9999C60.9994 37.0999 60.7994 37.1999 60.5994 37.1999Z"
      fill="currentColor"
    />
    <path
      d="M44.9985 35.6H42.4993C41.7993 35.6 41.1992 35 41.1992 34.3C41.1992 33.6 41.7993 33 42.4993 33H44.9985C45.6985 33 46.2976 33.6 46.2976 34.3C46.2986 35.1 45.6985 35.6 44.9985 35.6Z"
      fill="currentColor"
    />
    <path
      d="M44.9985 39.4H42.4993C41.7993 39.4 41.1992 38.8 41.1992 38.1C41.1992 37.4 41.7993 36.8 42.4993 36.8H44.9985C45.6985 36.8 46.2976 37.4 46.2976 38.1C46.2986 38.8 45.6985 39.4 44.9985 39.4Z"
      fill="currentColor"
    />
    <path
      d="M44.9985 43.1H42.4993C41.7993 43.1 41.1992 42.5 41.1992 41.8C41.1992 41.1 41.7993 40.5 42.4993 40.5H44.9985C45.6985 40.5 46.2976 41.1 46.2976 41.8C46.2986 42.6 45.6985 43.1 44.9985 43.1Z"
      fill="currentColor"
    />
    <path
      d="M44.9985 46.9H42.4993C41.7993 46.9 41.1992 46.3 41.1992 45.6C41.1992 44.9 41.7993 44.3 42.4993 44.3H44.9985C45.6985 44.3 46.2976 44.9 46.2976 45.6C46.2986 46.3 45.6985 46.9 44.9985 46.9Z"
      fill="currentColor"
    />
    <path
      d="M44.9985 50.6H42.4993C41.7993 50.6 41.1992 50 41.1992 49.3C41.1992 48.6 41.7993 48 42.4993 48H44.9985C45.6985 48 46.2976 48.6 46.2976 49.3C46.2986 50.1 45.6985 50.6 44.9985 50.6Z"
      fill="currentColor"
    />
    <path
      d="M26.8991 39.4C25.1979 39.4 23.7988 38 23.7988 36.3C23.7988 34.6 25.1979 33.2 26.8991 33.2C28.5992 33.2 29.9983 34.6 29.9983 36.3C29.9983 38 28.5992 39.4 26.8991 39.4ZM26.8991 35.6C26.598 35.6 26.299 35.9 26.299 36.2C26.299 36.5 26.599 36.8 26.8991 36.8C27.1991 36.8 27.4981 36.5 27.4981 36.2C27.4981 35.9 27.1991 35.6 26.8991 35.6Z"
      fill="currentColor"
    />
    <path
      d="M68.7983 75.6C66.6982 75.6 64.998 73.9 64.998 71.8C64.998 69.7 66.6982 68 68.7983 68C70.8985 68 72.5986 69.7 72.5986 71.8C72.5986 73.9 70.7995 75.6 68.7983 75.6ZM68.7983 70.6C68.0983 70.6 67.4992 71.2 67.4992 71.9C67.4992 72.6 68.0983 73.2 68.7983 73.2C69.4984 73.2 70.0994 72.6 70.0994 71.9C69.9984 71.2 69.3994 70.6 68.7983 70.6Z"
      fill="currentColor"
    />
  </Icon>
)
