import React, {ChangeEvent, useState} from 'react'
import {Props} from './types'

export const TimeInput: React.FC<Props> = ({value, onChange, disabled}) => {
  const [time, setTime] = useState(value || '')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '') // Remove non-numeric characters
    let hours = input.slice(0, 2)
    let minutes = input.slice(2, 4)
    let seconds = input.slice(4, 6)

    // Ensure hours, minutes, and seconds are within limits
    if (parseInt(hours) > 23) {
      hours = '23'
    }
    if (parseInt(minutes) > 59) {
      minutes = '59'
    }
    if (parseInt(seconds) > 59) {
      seconds = '59'
    }

    // Add ":" automatically
    const formattedTime = hours + (minutes ? ':' + minutes : '') + (seconds ? ':' + seconds : '')

    setTime(formattedTime)
    onChange?.(formattedTime)
  }

  return (
    <input
      type="text"
      value={time}
      onChange={handleInputChange}
      placeholder="hh:mm:ss"
      disabled={disabled}
      className="border border-blue-4 px-3 py-[5px] text-blue placeholder:text-blue-21 font-semibold text-sm leading-7.5 rounded-lg w-[112px] font-public-sans disabled:opacity-60"
    />
  )
}
