import {Image} from '@/components/common'
import {Props} from './types'
import {Tag} from '@/components/common/tag'
import {Link} from 'react-router-dom'
import {getImageURL} from '@/utils/get-image'
import {calculateEstimatedReadingTime} from '@/utils/time'
import formatDistance from 'date-fns/formatDistance'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'

export const BlogCard = ({blog}: Props) => {
  const {i18n, t} = useTranslation()
  const blogTitleMultipleLanguage = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case 'en':
        return blog?.title_en
      case 'uz':
        return blog?.title_uz
      case 'ru':
        return blog?.title_ru
      default:
        return blog?.title_en
    }
  }, [i18n.resolvedLanguage, blog])

  const blogDescriptionLanguage = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case 'en':
        return blog?.text_en
      case 'uz':
        return blog?.text_uz
      case 'ru':
        return blog?.text_ru
      default:
        return blog?.text_en
    }
  }, [i18n.resolvedLanguage, blog])

  return (
    <Link
      to={`/blogs/${blog._id}`}
      className="bg-white inline-block h-min border border-blue-4 rounded-lg overflow-hidden duration-150 hover:shadow-lg"
    >
      <Image
        src={getImageURL(blog.image)}
        alt={blog[`title_${i18n.language}`]}
        className="min-w-full"
        imageClassName="w-full h-48"
      />
      <div className="px-5 pt-4 pb-5">
        <div className="flex flex-wrap gap-2.5">
          {blog.tags.slice(0, 2).map((tag: string, index: number) => (
            <Tag
              key={index}
              title={tag}
              className="bg-blue-5 px-2.5 py-1 h-7 text-sm leading-4.5 font-normal text-blue"
            />
          ))}
          <h3 className="mt-2 text-xl font-bold leading-7.5 line-clamp-2 min-w-full">{blogTitleMultipleLanguage}</h3>
          <p className="text-blue-2 text-sm leading-6 line-clamp-2">
            {blogDescriptionLanguage?.replace(/<[^>]*>?/gm, '')}
          </p>
          <div className="flex h-full items-center justify-between w-full mt-7">
            <div className="flex items-center gap-2.5 text-5 text-blue-2 leading-[30px]">
              <p>
                {formatDistance(new Date(blog.created_at), new Date(), {addSuffix: false} || Date)?.replace(
                  'about',
                  '',
                )}{' '}
                {t('blog.blog_card.ago')}
              </p>
              <div className="w-1 h-1 bg-blue-2" />
              <p>
                {calculateEstimatedReadingTime(blogDescriptionLanguage)} {t('blog.blog_card.read')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
