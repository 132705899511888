export const GUIDANCE = [
  {
    imageURL: '/images/pages/home/how-it-works/guidance/1.webp',
    name: 'home.guidance_cards.first.title',
    description: 'home.guidance_cards.first.description',
    buttonName: 'home.guidance_cards.first.button',
    path: '/rankings',
  },
  {
    imageURL: '/images/pages/home/how-it-works/guidance/2.webp',
    name: 'home.guidance_cards.second.title',
    description: 'home.guidance_cards.second.description',
    buttonName: 'home.guidance_cards.second.button',
    path: '/rankings',
  },
  {
    imageURL: '/images/pages/home/how-it-works/guidance/3.webp',
    name: 'home.guidance_cards.third.title',
    description: 'home.guidance_cards.third.description',
    buttonName: 'home.guidance_cards.third.button',
    path: '/cv-builder',
  },
  {
    imageURL: '/images/pages/home/how-it-works/guidance/4.webp',
    name: 'home.guidance_cards.fourth.title',
    description: 'home.guidance_cards.fourth.description',
    buttonName: 'home.guidance_cards.fourth.button',
    path: '/cv-builder',
  },
]
