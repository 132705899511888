/* eslint-disable react-hooks/exhaustive-deps */
import {useHandleError} from '@/hooks/use-handle-error'
import {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react'
import {Props} from './types'
import {acceptedImageFormats} from '@/constants/accepted-image-formats'
import {Button, Image} from '..'
import {getImageURL} from '@/utils/get-image'
import {useTranslation} from 'react-i18next'
import {Cropper} from './components'
import {base64ToBlob} from '@/utils/base64-to-blob'

export const SingleImageUploader = ({value, onChange, type = 'circle'}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [placeholderImage, setPlaceholderImage] = useState('')
  const [cropImage, setCropImage] = useState()
  const handleError = useHandleError()
  const {t} = useTranslation()

  const onFileChange = async (event: ChangeEvent<HTMLInputElement> | string | any) => {
    if (typeof event === 'string' && onChange) {
      const blob = await base64ToBlob(event)
      const file = new File([blob as Blob], 'filename.png', {type: 'image/png'})
      onChange(file)
      setCropImage(undefined)
    } else {
      const file = event.target.files?.[0]
      if (file && acceptedImageFormats.includes(file.type)) {
        setPlaceholderImage(URL.createObjectURL(file))

        if (onChange) {
          onChange(file)
          setCropImage(file)
        }
      } else {
        handleError(t('toast.only_png_and_jpeg_formats_are_allowed'))
        event.target.value = ''
      }
    }
  }

  const singleImageClassName = useMemo(() => {
    switch (type) {
      case 'circle':
        return 'w-[120px] h-[120px] border border-blue-4 rounded-full'
      case 'rectangle':
        return 'w-[207px] h-[120px] border border-blue-4'
    }
  }, [type])

  const onRemoveFile = (event: any) => {
    if (event.preventDefault) {
      event.preventDefault()
    }

    if (inputRef.current) {
      inputRef.current.value = ''
    }

    if (onChange) {
      onChange(undefined)
    }

    setPlaceholderImage('')
  }

  const onCancel = () => {
    setCropImage(undefined)
    setPlaceholderImage(URL.createObjectURL(value as File))
  }

  useEffect(
    () => () => {
      if (placeholderImage) {
        URL.revokeObjectURL(placeholderImage)
      }
    },
    [placeholderImage],
  )

  useEffect(() => {
    if (value) {
      setPlaceholderImage(typeof value === 'string' ? getImageURL(value) : URL.createObjectURL(value))
    }
  }, [cropImage, value])

  return (
    <div className="flex flex-col lg:flex-row items-center gap-5">
      {!cropImage ? (
        <>
          <Image
            src={placeholderImage || '/images/placeholder.webp'}
            alt="Uploaded Picture"
            imageClassName={singleImageClassName}
          />
          <div className="flex mt-2 lg:mt-0 items-center gap-3">
            <label className="bg-transparent border border-blue hover:bg-blue hover:text-white px-8 py-2 text-sm font-bold leading-5 rounded duration-default cursor-pointer">
              {t('common.upload')}
              <input type="file" accept={acceptedImageFormats.join(', ')} onChange={onFileChange} hidden />
            </label>
            <Button
              variant="outline"
              theme="orange"
              className="px-8 py-2 text-sm font-bold leading-5 rounded"
              onClick={onRemoveFile}
            >
              {t('common.delete')}
            </Button>
          </div>
        </>
      ) : (
        <Cropper onChange={imageFile => onFileChange(imageFile)} onCancel={onCancel} src={cropImage} />
      )}
    </div>
  )
}
