import {Field} from '@/components/common'
import {Props} from './types'
import {useTranslation} from 'react-i18next'

export const About = ({form}: Props) => {
  const {t} = useTranslation()

  return (
    <div className="flex flex-col gap-6">
      <div>
        <p className="text-blue-1 font-bold leading-6 mb-2.5">
          {t('dashboard.universities.active_universities_page.about')}
        </p>
        <Field
          control={form.control}
          name="description_en"
          type="cv-builder-md-editor"
          componentProps={{
            id: 'create-university-about-editor-en',
          }}
        />
      </div>
    </div>
  )
}
