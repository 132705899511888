import {Field} from '@/components/common'
import {Props} from './types'
import {useTranslation} from 'react-i18next'
import {useValidationOptions} from '@/hooks/use-validation-options'
import {useURLValidation} from '@/hooks/use-url-validation'

export const Profile = ({form}: Props) => {
  const {t} = useTranslation()
  const getValidationOptions = useValidationOptions()
  const urlValidation = useURLValidation()

  return (
    <div>
      <Field name="logo" control={form.control} type="single-image-upload" />
      <h5 className="text-blue text-xl font-bold leading-8 mt-10 mb-7">
        {t('dashboard.universities.active_universities_page.social_profiles')}
      </h5>
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-x-6 gap-y-7">
        <Field
          name="telegram"
          componentProps={{
            label: t('dashboard.universities.active_universities_page.telegram'),
          }}
          control={form.control}
          type="second-input"
          rules={getValidationOptions(false, undefined, false, urlValidation)}
        />
        <Field
          name="instagram"
          componentProps={{
            label: t('dashboard.universities.active_universities_page.instagram'),
          }}
          control={form.control}
          type="second-input"
          rules={getValidationOptions(false, undefined, false, urlValidation)}
        />
        <Field
          name="linkedin"
          componentProps={{
            label: t('dashboard.universities.active_universities_page.linkedin'),
          }}
          control={form.control}
          type="second-input"
          rules={getValidationOptions(false, undefined, false, urlValidation)}
        />
        <Field
          name="facebook"
          componentProps={{
            label: t('dashboard.universities.active_universities_page.facebook'),
          }}
          control={form.control}
          type="second-input"
          rules={getValidationOptions(false, undefined, false, urlValidation)}
        />
      </div>
    </div>
  )
}
