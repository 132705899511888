import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const CloseIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 28 28">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.13128 8.13128C8.47299 7.78957 9.02701 7.78957 9.36872 8.13128L14 12.7626L18.6313 8.13128C18.973 7.78957 19.527 7.78957 19.8687 8.13128C20.2104 8.47299 20.2104 9.02701 19.8687 9.36872L15.2374 14L19.8687 18.6313C20.2104 18.973 20.2104 19.527 19.8687 19.8687C19.527 20.2104 18.973 20.2104 18.6313 19.8687L14 15.2374L9.36872 19.8687C9.02701 20.2104 8.47299 20.2104 8.13128 19.8687C7.78957 19.527 7.78957 18.973 8.13128 18.6313L12.7626 14L8.13128 9.36872C7.78957 9.02701 7.78957 8.47299 8.13128 8.13128Z"
      fill="currentColor"
    />
  </Icon>
)
