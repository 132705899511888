import {ChatSocketKeys} from '@/constants/chat-socket-keys/chat-socket-keys'
import {SERVER_URL} from '@/constants/server-url'
import {useStorage} from '@/utils/storage'
import {io} from 'socket.io-client'

export const socket = io(SERVER_URL, {
  auth: {
    token: useStorage.getTokens().accessToken?.split(' ')[1] as string,
  },
})

// GET CHATS
export const emitUserChats = (body?: any) => {
  socket.emit(ChatSocketKeys.GET_USER_CHATS, body)
}
export const onUserChats = (handle: any) => {
  socket.on(ChatSocketKeys.USER_CHATS, handle)
}
export const offUserChats = () => {
  socket.off(ChatSocketKeys.USER_CHATS)
}

export const emitUniversityChats = (body?: any) => {
  socket.emit(ChatSocketKeys.GET_UNIVERSITY_CHATS, body)
}
export const onUniversityChats = (handle: any) => {
  socket.on(ChatSocketKeys.UNIVERSITY_CHATS, handle)
}
export const offUniversityChats = () => {
  socket.off(ChatSocketKeys.UNIVERSITY_CHATS)
}

export const emitCompanyChats = (body?: any) => {
  socket.emit(ChatSocketKeys.GET_COMPANY_CHATS, body)
}
export const onCompanyChats = (handle: any) => {
  socket.on(ChatSocketKeys.COMPANY_CHATS, handle)
}
export const offCompanyChats = () => {
  socket.off(ChatSocketKeys.COMPANY_CHATS)
}

// DELETE CHAT
export const deleteChat = (body: any) => {
  socket.emit(ChatSocketKeys.DELETE_CHAT, body)
}

// GET MESSAGES
export const emitMessages = (body: any) => {
  socket.emit(ChatSocketKeys.GET_MESSAGES, body)
}

export const onUserMessages = (handle: any) => {
  socket.on(ChatSocketKeys.APPLICANT_MESSAGES, handle)
}
export const offUserMessages = () => {
  socket.off(ChatSocketKeys.APPLICANT_MESSAGES)
}

export const onUniversityMessages = (handle: any) => {
  socket.on(ChatSocketKeys.UNIVERSITY_MESSAGES, handle)
}
export const offUniversityMessages = () => {
  socket.off(ChatSocketKeys.UNIVERSITY_MESSAGES)
}

export const onCompanyMessages = (handle: any) => {
  socket.on(ChatSocketKeys.COMPANY_MESSAGES, handle)
}
export const offCompanyMessages = () => {
  socket.off(ChatSocketKeys.COMPANY_MESSAGES)
}

// SEND MESSAGE
export const sendMessage = (body: any) => {
  socket.emit(ChatSocketKeys.SEND_MESSAGE, body)
}
export const onNewMessage = (onChange: any) => {
  socket.on(ChatSocketKeys.NEW_MESSAGE, onChange)
}
export const offNewMessage = () => {
  socket.off(ChatSocketKeys.NEW_MESSAGE)
}

// START CHAT
export const startChat = (body: any) => {
  socket.emit(ChatSocketKeys.START_CHAT, body)
}
export const onNewChat = (handle: any) => {
  socket.on(ChatSocketKeys.CHAT, handle)
}
export const offNewChat = () => {
  socket.off(ChatSocketKeys.CHAT)
}

// ONLINE AVAILIBILITY
export const onUserOnline = (handle: any) => {
  socket.on(ChatSocketKeys.USER_ONLINE, handle)
}
export const offUserOnline = () => {
  socket.off(ChatSocketKeys.USER_ONLINE)
}
export const onUserOffline = (handle: any) => {
  socket.on(ChatSocketKeys.USER_OFFLINE, handle)
}
export const offUserOffline = () => {
  socket.off(ChatSocketKeys.USER_OFFLINE)
}

// FAVORITE
export const favoriteChat = (body: any) => {
  socket.emit(ChatSocketKeys.SET_FAVORITE, body)
}

// TYPING
export const emitStartTpying = (body: any) => {
  socket.emit(ChatSocketKeys.TYPING, body)
}
export const onTyping = (handle: any) => {
  socket.on(ChatSocketKeys.TYPING, handle)
}
export const offTyping = () => {
  socket.off(ChatSocketKeys.TYPING)
}

// SEEN/UNSEEN MESSAGE
export const onMessageRead = (handle: any) => {
  socket.on(ChatSocketKeys.MESSAGES_READ, handle)
}
export const offMessageRead = () => {
  socket.off(ChatSocketKeys.MESSAGES_READ)
}

// ADMIN CHAT
export const emitAdminChat = () => {
  socket.emit(ChatSocketKeys.GET_ADMIN_CHAT)
}
export const onAdminChat = (handle: any) => {
  socket.on(ChatSocketKeys.ADMIN_CHAT, handle)
}
export const offAdminChat = () => {
  socket.off(ChatSocketKeys.ADMIN_CHAT)
}
