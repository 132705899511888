import {Image} from '@/components/common'
import {GuideCard} from './components/guide-card'
import {GUIDANCE} from './constants/guidance'
import {VideoPlayIcon} from '@/icons'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

export const HowItWorks = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const {t} = useTranslation()

  return (
    <section id="how-it-works" className="px-4 md:container md:py-24 py-14">
      <div data-aos="fade-up" className="w-full">
        <div className="max-w-full overflow-x-auto scrollbar-hide flex gap-[25px]">
          {GUIDANCE.map((guidance, index) => (
            <GuideCard key={index} guidance={guidance} />
          ))}
        </div>
      </div>
      <div className="mt-12 md:mt-20">
        <h2 data-aos="fade-up" className="text-4xl md:text-[50px] font-bold leading-9 md:leading-17 text-center">
          {t('home.guidance_cards.how_it_works')}
        </h2>
        <div data-aos="fade-up" className="md:mx-24">
          <div className="relative overflow-hidden mt-8 md:mt-14 rounded md:rounded-2xl w-full h-[200px] md:h-[300px] lg:h-[681px]">
            {isPlaying ? (
              <iframe
                className="absolute top-0 left-0 min-w-full min-h-full object-cover"
                src="https://www.youtube.com/embed/xMgcuFEN2IQ?autoplay=1&loop=1"
                allow="accelerometer; autoplay *; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              />
            ) : (
              // <iframe width="560" height="315" src="https://www.youtube.com/embed/?si=vCUIGZid7hbTWSPU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <Image
                src="/images/pages/home/how-it-works/video-thumbnail.webp"
                alt="Video Thumnail"
                className="absolute top-0 left-0 min-w-full min-h-full"
                imageClassName="absolute w-full h-full"
              />
            )}
            {!isPlaying && (
              <button
                onClick={() => setIsPlaying(true)}
                aria-label="Play video"
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-3 md:p-6 border-2 border-white bg-white/50 rounded-full text-white"
              >
                <VideoPlayIcon className="w-6 h-6 md:w-12 md:h-12" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
