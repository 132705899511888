import {Button, Image} from '@/components/common'
import {MenuLink} from './components'
import {
  AppliedUsersIcon,
  ChatIcon,
  CollegeIcon,
  CompaniesIcon,
  DashboardHomeIcon,
  DashboardIcon,
  DoubleBookmarkIcon,
  EditHomeIcon,
  InBoxIcon,
  ManagedUsersIcon,
  MenuIcon,
  NotificationIcon,
  PlusIcon,
  ProfileIcon,
  ProgramsIcon,
  SettingIcon,
  ToolsIcon,
  UserAvatar,
  UsersIcon,
  VacanciesIcon,
} from '@/icons'
import {Props} from './types'
import {Link} from 'react-router-dom'
import {useMemo, useState} from 'react'
import {useUserRole} from '@/hooks/use-user-role'
import {Role} from '@/constants/roles'
import {useTranslation} from 'react-i18next'
import {CreateForm} from '@/components/pages/private/universities/universities/components/admin-universities/components/create-form'
import {useAppSelector} from '@/store/hooks'
import {useUserData} from '@/hooks/use-user-data'

export const SideBar = ({menu, setMenu}: Props) => {
  const {t} = useTranslation()
  const userRole = useUserRole()
  const user = useUserData()

  const [open, setOpen] = useState(false)
  const {selectedUniversity} = useAppSelector(state => state.university)

  const menuLinks = useMemo(() => {
    const links = [
      {
        label: t('private_layout.sidebar.home'),
        icon: <DashboardHomeIcon />,
        path: '/',
      },
      {
        label: t('private_layout.sidebar.dashboard'),
        icon: <DashboardIcon />,
        path: '/dashboard',
      },
    ]

    if (!userRole) {
      return []
    }

    if (userRole === Role.CONSULTING && selectedUniversity) {
      links.push({
        label: t('private_layout.sidebar.inbox'),
        icon: <InBoxIcon />,
        path: '/dashboard/chat',
      })
    } else if (userRole !== Role.CONSULTING && userRole !== Role.ADMIN) {
      links.push({
        label: t('private_layout.sidebar.inbox'),
        icon: <InBoxIcon />,
        path: '/dashboard/chat',
      })
    }

    if ([Role.SUPER_ADMIN, Role.APPLICANT].includes(userRole)) {
      links.push(
        {
          label: t('private_layout.sidebar.colleges'),
          icon: <CollegeIcon />,
          path: '/dashboard/universities',
        },
        {
          label: t('private_layout.sidebar.companies'),
          icon: <CompaniesIcon />,
          path: '/dashboard/companies',
        },
      )
    }

    if ([Role.SUPER_ADMIN, Role.APPLICANT, Role.COMPANY].includes(userRole)) {
      links.push({
        label: t('private_layout.sidebar.vacancies'),
        icon: <VacanciesIcon />,
        path: '/dashboard/vacancies',
      })
    }

    if ([Role.SUPER_ADMIN, Role.APPLICANT].includes(userRole)) {
      links.push({
        label: t('private_layout.sidebar.skills'),
        icon: <ToolsIcon />,
        path: '/dashboard/skills',
      })
    }

    if ([Role.SUPER_ADMIN].includes(userRole)) {
      links.push(
        {
          label: t('private_layout.sidebar.blog'),
          icon: <ChatIcon />,
          path: '/dashboard/blogs',
        },
        {
          label: t('private_layout.sidebar.users'),
          icon: <UsersIcon />,
          path: '/dashboard/users',
        },
        {
          label: t('private_layout.sidebar.consulting'),
          icon: <ProfileIcon />,
          path: '/dashboard/consultings',
        },
        {
          label: t('private_layout.sidebar.edit_home_page'),
          icon: <EditHomeIcon className="h-5 w-4" />,
          path: '/dashboard/home-edit',
        },
      )
    }

    if ([Role.COMPANY, Role.UNIVERSITY].includes(userRole) || (userRole === Role.CONSULTING && selectedUniversity)) {
      links.push(
        {
          label: t('private_layout.sidebar.managed_users'),
          icon: <ManagedUsersIcon />,
          path: '/dashboard/managed-users',
        },
        {
          label: t('private_layout.sidebar.applied_users'),
          icon: <AppliedUsersIcon />,
          path: '/dashboard/applied-users',
        },
        {
          label: t('private_layout.sidebar.saved_users'),
          icon: <DoubleBookmarkIcon />,
          path: '/dashboard/saved-users',
        },
      )
    }

    if (userRole === Role.CONSULTING && selectedUniversity) {
      links.push({
        label: 'College profile',
        icon: <CollegeIcon />,
        path: '/dashboard/profile/university',
      })
    }

    if (![Role.SUPER_ADMIN, Role.ADMIN, Role.CONSULTING].includes(userRole)) {
      links.push({
        label: t('private_layout.sidebar.profile'),
        icon: <UserAvatar className="w-6 h-6" />,
        path: '/dashboard/profile',
      })
    }

    if ([Role.UNIVERSITY].includes(userRole) || (userRole === Role.CONSULTING && selectedUniversity)) {
      links.push({
        label: t('private_layout.sidebar.programs'),
        icon: <ProgramsIcon />,
        path: '/dashboard/programs',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.blog) {
      links.push({
        label: t('private_layout.sidebar.blog'),
        icon: <ChatIcon />,
        path: '/dashboard/blogs',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.applicant) {
      links.push({
        label: t('private_layout.sidebar.users'),
        icon: <UsersIcon />,
        path: '/dashboard/users',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.college) {
      links.push({
        label: 'College profile',
        icon: <CollegeIcon />,
        path: '/dashboard/profile/university',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.inbox) {
      links.push({
        label: t('private_layout.sidebar.inbox'),
        icon: <InBoxIcon />,
        path: '/dashboard/chat',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.homepage) {
      links.push({
        label: t('private_layout.sidebar.edit_home_page'),
        icon: <EditHomeIcon className="h-5 w-4" />,
        path: '/dashboard/home-edit',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.skill) {
      links.push({
        label: t('private_layout.sidebar.skills'),
        icon: <ToolsIcon />,
        path: '/dashboard/skills',
      })
    }

    if ([Role.ADMIN].includes(userRole) && user.admin.permissions.company) {
      links.push({
        label: t('private_layout.sidebar.companies'),
        icon: <CompaniesIcon />,
        path: '/dashboard/companies',
      })
    }

    return links
  }, [userRole, selectedUniversity, t, user])

  return (
    <>
      {menu && (
        <div
          onClick={() => setMenu(false)}
          className="fixed block md:hidden top-0 left-0 w-full min-h-screen backdrop-blur-sm z-20"
        ></div>
      )}
      <aside
        className={`fixed top-0 z-50 overflow-x-hidden min-h-screen h-screen bg-white w-[250px] lg:left-0 ${
          menu ? 'left-0 lg:w-[67px]' : '-left-[250px]'
        } border-r border-blue-4 duration-300`}
      >
        <div className={`flex flex-col justify-end items-center h-[100px] relative ${menu ? 'px-1' : 'px-5'}`}>
          <Link to="/dashboard" className={menu ? 'z-[40] mb-5' : 'z-[40] mb-3'}>
            <Image
              src="/images/logo.svg"
              alt="Student Hunter"
              imageClassName={menu ? 'w-14 h-14 lg:w-9 lg:h-9' : 'w-14 h-14'}
            />
          </Link>
          <span className="w-full border-b" />
          <button
            onClick={() => setMenu((prev: boolean) => !prev)}
            className="block lg:hidden absolute right-2 top-1/2 -translate-y-1/2 "
          >
            <MenuIcon className="w-10 h-10" />
          </button>
        </div>
        <div className={userRole === Role.CONSULTING ? 'pt-[30px]' : 'pt-14'}>
          {userRole === Role.CONSULTING && (
            <div className="flex items-center justify-center pb-[35px]">
              <Button
                onClick={() => setOpen(true)}
                theme="orange"
                icon={<PlusIcon className="w-3 h-3" />}
                className={` bg-orange-6 border-orange-6 hover:bg-orange-6/90 hover:border-orange-6/90 ${!menu ? 'px-6 py-2' : 'px-4'} rounded text-sm font-bold leading-6 gap-2`}
                noSpaceBetweenIcon
              >
                {!menu && (
                  <span className="w-full inline-block line-clamp-1">
                    {t('dashboard.universities.add_new_college')}
                  </span>
                )}
              </Button>
            </div>
          )}
          {menuLinks.map((link, index) => (
            <MenuLink key={index} isHiddenLabel={menu} path={link.path} icon={link.icon} label={link.label} />
          ))}
        </div>
        <div className="my-3 px-5">
          <hr />
        </div>
        {Role.CONSULTING === userRole && (
          <MenuLink
            icon={<ProfileIcon />}
            path="/dashboard/profile?tab=profile"
            isHiddenLabel={menu}
            label="My Profile"
          />
        )}
        <MenuLink
          icon={<SettingIcon />}
          path="/dashboard/settings"
          isHiddenLabel={menu}
          label={t('private_layout.sidebar.settings')}
        />
        {[Role.APPLICANT].includes(userRole as Role) && (
          <MenuLink
            path="/dashboard/notifications"
            icon={<NotificationIcon />}
            isHiddenLabel={menu}
            label={t('private_layout.sidebar.notifications')}
          />
        )}
      </aside>
      <CreateForm open={open} close={() => setOpen(false)} />
    </>
  )
}
