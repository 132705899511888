import {
  AgricultureIcon,
  ArchitectureIcon,
  ComputerScienceIcon,
  EarthScienceIcon,
  EducationIcon,
  EngineeringIcon,
  HospitalityIcon,
  HumanitiesIcon,
  JournalismIcon,
  LawIcon,
  ManagementIcon,
  MedicineIcon,
  NaturalScienceIcon,
  ScienceIcon,
  SocialScienceIcon,
} from '../../icons'

export const PROGRAMS = [
  {name: 'home.explore.agriculture_forestry', Icon: AgricultureIcon},
  {name: 'home.explore.applied_science_professions', Icon: ScienceIcon},
  {name: 'home.explore.art_design_architecture', Icon: ArchitectureIcon},
  {name: 'home.explore.business_management', Icon: ManagementIcon},
  {name: 'home.explore.computer_science_it', Icon: ComputerScienceIcon},
  {name: 'home.explore.education_training', Icon: EducationIcon},
  {name: 'home.explore.engineering_technology', Icon: EngineeringIcon},
  {name: 'home.explore.environmental_studies_earth_science', Icon: EarthScienceIcon},
  {name: 'home.explore.hospitality_leisure_sports', Icon: HospitalityIcon},
  {name: 'home.explore.humanities', Icon: HumanitiesIcon},
  {name: 'home.explore.journalism_media', Icon: JournalismIcon},
  {name: 'home.explore.law', Icon: LawIcon},
  {name: 'home.explore.medicine_health', Icon: MedicineIcon},
  {name: 'home.explore.natural_science_mathematics', Icon: NaturalScienceIcon},
  {name: 'home.explore.social_science', Icon: SocialScienceIcon},
]
