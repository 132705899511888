import {EndpointNameUniversity} from '@/constants/endpoints'
import {baseApi} from '..'
import {
  ChangeContractRequest,
  ChangeContractResponse,
  ChangeUniversityStatusRequest,
  ChangeUniversityStatusResponse,
  CreateUniversityRequest,
  CreateUniversityResponse,
  DeleteUniversityRequest,
  DeleteUniversityResponse,
  ExportUsersRequest,
  ExportUsersResponse,
  GetAdminDashboardUniversitiesRequest,
  GetAdminDashboardUniversitiesResponse,
  GetCountriesRequest,
  GetCountriesResponse,
  GetDownloadUniversityRequest,
  GetDownloadUniversityResponse,
  GetPublicUniversitiesRequset,
  GetPublicUniversitiesresponse,
  GetPublicUniversityRequset,
  GetPublicUniversityresponse,
  GetUniversitiesByRankingRequest,
  GetUniversitiesByRankingResponse,
  GetUniversityApplicantRequest,
  GetUniversityApplicantResponse,
  GetUniversityAppliedUsersRequest,
  GetUniversityAppliedUsersResponse,
  GetUniversityChartRequest,
  GetUniversityChartResponse,
  GetUniversityDashboardRequest,
  GetUniversityDashboardResponse,
  GetUniversityFacultyRequest,
  GetUniversityFacultyResponse,
  GetUniversitySavedUsersRequest,
  GetUniversitySavedUsersResponse,
  likeUniversityRequest,
  likeUniversityResponse,
  ManageApplicantRequest,
  ManageApplicantResponse,
  UpdateUniversityRequest,
  UpdateUniversityResponse,
} from './types'
import {RTKTagNames} from '@/constants/rtk-tag-names'

export const UniversityExtendedEndpoints = baseApi.injectEndpoints({
  endpoints: build => ({
    getAdminDashboardUniversities: build.query<
      GetAdminDashboardUniversitiesResponse,
      GetAdminDashboardUniversitiesRequest
    >({
      query: params => ({
        url: EndpointNameUniversity.GET_ADMIN_DASHBOARD_UNIVERSITIES,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    getPublicUniversities: build.query<GetPublicUniversitiesresponse, GetPublicUniversitiesRequset>({
      query: params => ({
        url: EndpointNameUniversity.GET_PUBLIC_UNIVERSITIES,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    getUniversity: build.query<GetPublicUniversityresponse, GetPublicUniversityRequset>({
      query: ({universityID}) => ({
        url: EndpointNameUniversity.GET_UNIVERSITY + universityID,
        method: 'GET',
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    getUniversityAppliedUsers: build.query<GetUniversityAppliedUsersResponse, GetUniversityAppliedUsersRequest>({
      query: ({universityID, params}) => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_APPLIED_USER + universityID,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    getUniversitySavedUsers: build.query<GetUniversitySavedUsersResponse, GetUniversitySavedUsersRequest>({
      query: ({universityID, params}) => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_SAVED_USER + universityID,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    createUniversity: build.mutation<CreateUniversityResponse, CreateUniversityRequest>({
      query: body => ({
        url: EndpointNameUniversity.CREATE_UNIVERSITY,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),

    changeUniversityStatus: build.mutation<ChangeUniversityStatusResponse, ChangeUniversityStatusRequest>({
      query: ({universityID, params}) => ({
        url: EndpointNameUniversity.CHANGE_UNIVERSITY_STATUS + universityID,
        method: 'PUT',
        params,
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),
    likeUniversity: build.mutation<likeUniversityResponse, likeUniversityRequest>({
      query: body => ({
        url: EndpointNameUniversity.LIKE_UNIVERSITY,
        method: 'POST',
        body,
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY, RTKTagNames.COMPANY],
    }),
    updateUniversity: build.mutation<UpdateUniversityResponse, UpdateUniversityRequest>({
      query: ({universityID, body}) => ({
        url: EndpointNameUniversity.UPDATE_UNIVERSITY + universityID,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),

    getUniversityFaculty: build.query<GetUniversityFacultyResponse, GetUniversityFacultyRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_FACULTY,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    manageApplicant: build.mutation<ManageApplicantResponse, ManageApplicantRequest>({
      query: body => ({
        url: EndpointNameUniversity.CHANGE_UNIVERSITY_MANAGED_USER_STATUS,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),
    getUniversityDashboard: build.query<GetUniversityDashboardResponse, GetUniversityDashboardRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_DASHBOARD,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),
    getUniversityChart: build.query<GetUniversityChartResponse, GetUniversityChartRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_CHART + params.universityID,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),
    getUniverApplicant: build.query<GetUniversityApplicantResponse, GetUniversityApplicantRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_UNIVERSITY_APPLICANT,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),

    deleteUniversity: build.mutation<DeleteUniversityResponse, DeleteUniversityRequest>({
      query: ({universityID}) => ({
        url: EndpointNameUniversity.DELETE_UNIVERSITY + universityID,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),

    getUniversitiesByRanking: build.query<GetUniversitiesByRankingResponse, GetUniversitiesByRankingRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_UNIVERSITIES_BY_RANKING,
        method: 'GET',
        params,
      }),
      providesTags: [RTKTagNames.UNIVERSITY],
    }),
    getCountries: build.query<GetCountriesResponse, GetCountriesRequest>({
      query: params => ({
        url: EndpointNameUniversity.GET_COUNTRIES,
        method: 'GET',
        params,
      }),
    }),
    getDownloadUniversity: build.query<GetDownloadUniversityResponse, GetDownloadUniversityRequest>({
      query: () => ({
        url: EndpointNameUniversity.GET_DOWNLOAD,
        method: 'GET',
      }),
    }),
    changeContract: build.mutation<ChangeContractResponse, ChangeContractRequest>({
      query: ({contract, universityID}) => ({
        url: EndpointNameUniversity.CHANGE_UNIVERSITY_CONTRACT + universityID,
        method: 'PUT',
        params: {contract},
      }),
      invalidatesTags: [RTKTagNames.UNIVERSITY],
    }),

    exportUsers: build.query<ExportUsersResponse, ExportUsersRequest>({
      query: ({params, universityID}) => ({
        url: EndpointNameUniversity.EXPORT_USERS + universityID,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useManageApplicantMutation,
  useLazyGetAdminDashboardUniversitiesQuery,
  useCreateUniversityMutation,
  useChangeUniversityStatusMutation,
  useLazyGetPublicUniversitiesQuery,
  useLazyGetUniversityAppliedUsersQuery,
  useLazyGetUniversitySavedUsersQuery,
  useUpdateUniversityMutation,
  useGetUniversityFacultyQuery,
  useGetUniversityQuery,
  useLazyGetUniversityQuery,
  useLikeUniversityMutation,
  useGetAdminDashboardUniversitiesQuery,
  useGetUniversityDashboardQuery,
  useLazyGetUniversityChartQuery,
  useGetUniverApplicantQuery,
  useDeleteUniversityMutation,
  useLazyGetUniversitiesByRankingQuery,
  useGetCountriesQuery,
  useLazyGetDownloadUniversityQuery,
  useChangeContractMutation,
  useLazyExportUsersQuery,
} = UniversityExtendedEndpoints
