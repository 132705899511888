import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const MenuIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8.75C5 8.05964 5.55964 7.5 6.25 7.5H33.75C34.4404 7.5 35 8.05964 35 8.75C35 9.44036 34.4404 10 33.75 10H6.25C5.55964 10 5 9.44036 5 8.75ZM31.6161 14.1161C32.1043 13.628 32.8957 13.628 33.3839 14.1161C33.872 14.6043 33.872 15.3957 33.3839 15.8839L29.2678 20L33.3839 24.1161C33.872 24.6043 33.872 25.3957 33.3839 25.8839C32.8957 26.372 32.1043 26.372 31.6161 25.8839L26.6161 20.8839C26.128 20.3957 26.128 19.6043 26.6161 19.1161L31.6161 14.1161ZM5 16.25C5 15.5596 5.55964 15 6.25 15H21.25C21.9404 15 22.5 15.5596 22.5 16.25C22.5 16.9404 21.9404 17.5 21.25 17.5H6.25C5.55964 17.5 5 16.9404 5 16.25ZM5 23.75C5 23.0596 5.55964 22.5 6.25 22.5H21.25C21.9404 22.5 22.5 23.0596 22.5 23.75C22.5 24.4404 21.9404 25 21.25 25H6.25C5.55964 25 5 24.4404 5 23.75ZM5 31.25C5 30.5596 5.55964 30 6.25 30H33.75C34.4404 30 35 30.5596 35 31.25C35 31.9404 34.4404 32.5 33.75 32.5H6.25C5.55964 32.5 5 31.9404 5 31.25Z"
      fill="#0F2C59"
    />
  </Icon>
)
