import {SVGSkeleton, Skeleton} from '@/components/common'

export const Loader = () => (
  <div>
    <div>
      <div className="relative mt-10 container mx-auto">
        <h1 className="leading-10 lg:leading-[60px] md:leading-17 w-auto md:w-[520px]">
          <Skeleton className="w-[328px] max-w-full" />
        </h1>
        <p className="leading-6 md:leading-7.5 w-auto md:w-[498px] mt-2.5 mb-10 md:mb-16">
          <Skeleton className="w-[1136px] max-w-full" />
        </p>
        <div className="flex flex-col lg:grid lg:grid-cols-2 md:flex items-center gap-2 md:gap-5">
          <div className="relative min-w-max tracking-wide flex items-center justify-center  hover:border-blue/0 px-10 py-2 md:px-16 md:py-3.5 border-2">
            <Skeleton className="w-[40px] max-w-full" />
          </div>
          <div className="relative min-w-max tracking-wide flex items-center justify-center hover:border-blue/0 px-10 py-2 md:px-16 md:py-3.5 border-2 ">
            <Skeleton className="w-[120px] max-w-full" />
          </div>
        </div>
      </div>
      <div className="container w-full overflow-auto">
        <div className="inline-flex  mt-20 flex-nowrap w-full items-center md:py-7 py-4 md:px-5 border-b  [mask-image:_linear-gradient(to_right,transparent_0,_black_20px,_black_calc(100%-20px),transparent_100%)]">
          <ul className="flex   items-center overflow-hidden [&amp;_li]:mx-4 [&amp;_img]:max-w-none">
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
          </ul>
          <ul className="flex items-center md:justify-start [&amp;_li]:mx-4 [&amp;_img]:max-w-none">
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
            <li>
              <span className="max-w-max max-h-max">
                <SVGSkeleton className="md:w-44 md:h-12 object-contain w-4 h-8" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section className="max-w-[1600px] mx-auto px-4 mb-10 md:mb-20">
      <div className="w-full flex justify-center flex-col items-center">
        <h2 className="leading-9 md:leading-17">
          <Skeleton className="w-[184px] max-w-full" />
        </h2>
        <p className="leading-6 md:leading-8.5 mt-3 pb-7 md:pb-14">
          <Skeleton className="w-[432px] max-w-full" />
        </p>
      </div>
    </section>
    <div className="flex items-center relative w-full">
      {new Array(10).fill(' ').map((_, index) => (
        <div key={index} className="px-5 min-w-[33.33333%]">
          <div className="h-[300px] lg:h-[380px] relative w-full">
            <span className="max-h-max max-w-full w-full h-full">
              <span className="!w-full h-full">
                <SVGSkeleton className="w-4 h-4" />
              </span>
            </span>
            <div className="absolute bottom-0 w-full z-50 h-56 flex flex-col justify-end items-center gap-x-4">
              <h2 className="leading-5 md:leading-10 px-2">
                <Skeleton className="w-[144px] max-w-full" />
              </h2>
              <a>
                <div className="relative min-w-max tracking-wide flex items-center justify-center hover:border-blue/0 border my-4 hover:border-1 md:px-5 md:py-[13px] px-2 py-1.5 mb-6 leading-4 md:leading-8.5">
                  <Skeleton className="w-[72px] max-w-full" />
                </div>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
    <section className="py-8 md:py-20">
      <div className="px-4 md:container mx-auto">
        <h2 className="leading-9 text-center  md:leading-17">
          <Skeleton className="w-[192px] inline-block  max-w-full" />
        </h2>
        <div className="grid place-items-stretch grid-cols-3 lg:grid-cols-5 gap-2.5 md:gap-6 mt-8 md:mt-14">
          {new Array(15).fill(' ').map((_, index) => (
            <a
              key={index}
              className="flex flex-col items-center shadow-box-sm py-[10px] px-[6px] lg:px-6 h-auto lg:h-[200px] lg:pt-2.5"
            >
              <SVGSkeleton className="md:w-24 md:h-24 w-10 h-10" />
              <p className="pt-1.5 leading-4 md:leading-7.5">
                <Skeleton className="w-[176px] max-w-full" />
              </p>
            </a>
          ))}
        </div>
      </div>
    </section>
    <section className="py-7 md:pt-14 md:pb-24">
      <div className="px-4 md:container mx-auto">
        <h2 className="leading-9 md:leading-17">
          <Skeleton className="w-[176px] max-w-full" />
        </h2>
        <div className="relative flex  w-max mt-5 md:mt-10 mb-7 md:mb-14 mx-auto border  p-1">
          <div className="relative py-1 md:py-2.5 px-4 md:px-16">
            <Skeleton className="w-[96px] max-w-full" />
          </div>
          <div className="relative py-1 md:py-2.5 px-4 md:px-16">
            <Skeleton className="w-[96px] max-w-full" />
          </div>
          <div className="relative py-1 md:py-2.5 px-4 md:px-16">
            <Skeleton className="w-[96px] max-w-full" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-14">
          {new Array(3).fill('').map((_, index) => (
            <div key={index} className="flex flex-col justify-between border p-4 md:p-10">
              <div>
                <div className="hidden lg:inline-block">
                  <div className="relative w-10 h-10 lg:w-[80px] lg:h-[80px]">
                    <SVGSkeleton className="block object-cover rounded-full lg:w-[80px] lg:h-[80px] w-10 h-10" />
                  </div>
                </div>
                <div className="flex items-center gap-2.5"></div>
                <p className="mt-0 mb-6 md:mt-6 md:mb-10 leading-4.5 md:leading-6">
                  <Skeleton className="w-[2512px] max-w-full" />
                </p>
              </div>
              <div className="flex gap-2.5 items-center">
                <div className="h-full inline-flex items-center lg:hidden lg:pb-0">
                  <div className="h-12 relative w-10 lg:w-[80px] lg:h-[80px]">
                    <SVGSkeleton className="block object-cover rounded-full lg:w-[80px] lg:h-[80px] w-10 h-10" />
                  </div>
                </div>
                <div>
                  <h6 className="leading-4 md:leading-7.5">
                    <Skeleton className="w-[176px] max-w-full" />
                  </h6>
                  <span className="leading-4.5 md:leading-6">
                    <Skeleton className="w-[168px] max-w-full" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section>
      <div className="px-4 md:container mx-auto pt-14 pb-5 md:pt-20 md:pb-10">
        <div className="flex justify-center w-full">
          <h2 className="leading-9 md:leading-17">
            <Skeleton className="inline-block text-center w-[164px] max-w-full" />
          </h2>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-start gap-5 md:gap-24 mt-[] md:mt-14">
          <div className="w-full lg:w-[58%]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              {new Array(2).fill('').map((_, index) => (
                <a key={index} className="inline-block h-min border border-blue-4 hover:shadow-lg">
                  <span className="max-w-max max-h-max min-w-full">
                    <SVGSkeleton className="object-cover w-full h-48" />
                  </span>
                  <div className="px-5 pt-4 pb-5">
                    <div className="flex flex-wrap gap-2.5">
                      <h3 className="mt-2 leading-7.5 line-clamp-2">
                        <Skeleton className="w-[288px] max-w-full" />
                      </h3>
                      <p className="leading-6 line-clamp-2">
                        <Skeleton className="w-[66792px] max-w-full" />
                      </p>
                      <div className="flex h-full items-center justify-between w-full mt-7">
                        <div className="flex items-center gap-2.5 leading-[30px]">
                          <p>
                            <Skeleton className="w-[104px] max-w-full" />
                          </p>
                          <div className="w-1 h-1"></div>
                          <p>
                            <Skeleton className="w-[80px] max-w-full" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="container mx-auto pb-20">
      <div className="h-32 w-full"></div>
      <h2 className="w-full text-center leading-9 md:leading-16">
        <Skeleton className="w-[208px] text-center inline-block" />
      </h2>
      <p className="leading-5 text-center md:leading-8.5 mt-2 md:mt-4 mb-7 md:mb-20">
        <Skeleton className="w-[504px] inline-block max-w-full" />
      </p>
      <div>
        <div className="flex flex-col md:gap-4 max-w-[952px] mx-auto">
          {new Array(5).fill('').map((_, index) => (
            <div key={index} className="border-0 px-0 py-2 md:p-6">
              <h3 className="flex">
                <div className="flex-1 items-center py-4 flex px-4 lg:p-0 md:items-center justify-between mb-4">
                  <span className="leading-5 md:leading-8.5">
                    <Skeleton className="w-[532px] max-w-full" />
                  </span>
                  <span>
                    <SVGSkeleton className="md:w-8 md:h-8 w-5 h-5" />
                  </span>
                  <SVGSkeleton className="shrink-0 hidden w-[15px] h-[15px]" />
                </div>
              </h3>
              <div className="px-4 lg:p-0 leading-4.5 md:leading-7.5"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
)
