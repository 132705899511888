import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const GbIcon = (props: IconProps) => (
  <Icon viewBox="0 0 513 343" {...props}>
    <g clipPath="url(#clip0_301_1200)">
      <path d="M0 0.926514H513V342.93H0V0.926514Z" fill="white" />
      <path
        d="M288.562 0.923584H224.438V139.86H0V203.985H224.438V342.922H288.562V203.985H513V139.86H288.562V0.923584Z"
        fill="#D80027"
      />
      <path
        d="M394.554 231.401L513 297.205V231.401H394.554ZM312.261 231.401L513 342.922V311.387L369.026 231.401H312.261ZM459.53 342.922L312.261 261.099V342.922H459.53Z"
        fill="#0052B4"
      />
      <path d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z" fill="white" />
      <path d="M312.261 231.401L513 342.922V311.387L369.026 231.401H312.261Z" fill="#D80027" />
      <path
        d="M90.5174 231.399L0 281.687V231.399H90.5174ZM200.739 245.58V342.921H25.5408L200.739 245.58Z"
        fill="#0052B4"
      />
      <path d="M143.974 231.401L0 311.387V342.922L200.739 231.401H143.974Z" fill="#D80027" />
      <path
        d="M118.446 112.445L0 46.6407V112.445H118.446ZM200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739ZM53.4702 0.923584L200.739 82.7471V0.923584H53.4702Z"
        fill="#0052B4"
      />
      <path d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z" fill="white" />
      <path d="M200.739 112.445L0 0.923584V32.4591L143.974 112.445H200.739Z" fill="#D80027" />
      <path
        d="M422.483 112.447L513 62.1589V112.447H422.483ZM312.261 98.2653V0.924561H487.459L312.261 98.2653Z"
        fill="#0052B4"
      />
      <path d="M369.026 112.445L513 32.4591V0.923584L312.261 112.445H369.026Z" fill="#D80027" />
    </g>
    <defs>
      <clipPath id="clip0_301_1200">
        <rect width="513" height="342" fill="white" transform="translate(0 0.926514)" />
      </clipPath>
    </defs>
  </Icon>
)
