import {createSlice} from '@reduxjs/toolkit'

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    users: [],
    universities: [],
    companies: [],
  },
  reducers: {
    receiveUsersChat: (state, action: any) => {
      state.users.push(...((action as any).payload as []))
    },
  },
})

export const {receiveUsersChat} = chatSlice.actions
export default chatSlice.reducer
