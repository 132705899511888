import {createSlice} from '@reduxjs/toolkit'

const dashboardSearchSlice = createSlice({
  name: 'dashboard-search',
  initialState: {
    searchQuery: '',
  },
  reducers: {
    changeSearchQuery: (state, action: any) => {
      state.searchQuery = action?.payload
    },
  },
})

export const {changeSearchQuery} = dashboardSearchSlice.actions
export default dashboardSearchSlice.reducer
