import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const UniversityAvatarIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 36 36" fill="none">
    <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9356 11.8993L11.9403 14.2974C11.9404 14.2974 11.9381 14.2983 11.9337 14.3011C11.9293 14.3038 11.9234 14.308 11.9164 14.314C11.902 14.3264 11.8861 14.3438 11.8717 14.3652C11.8573 14.3866 11.8471 14.4078 11.841 14.4259C11.8352 14.4431 11.8347 14.4524 11.8346 14.4532C11.8346 14.4532 11.8346 14.4532 11.8346 14.4532L11.8346 16.6665C11.8346 16.7571 11.9108 16.8332 12.0013 16.8332H24.0013C24.0918 16.8332 24.168 16.7571 24.168 16.6665V14.4532C24.168 14.4532 24.168 14.4532 24.168 14.4532C24.1679 14.4525 24.1674 14.4431 24.1616 14.4259C24.1555 14.4078 24.1453 14.3866 24.1309 14.3652C24.1165 14.3438 24.1006 14.3264 24.0862 14.314C24.0792 14.308 24.0733 14.3038 24.0689 14.3011C24.0676 14.3002 24.0664 14.2996 24.0655 14.2991C24.0633 14.2979 24.0622 14.2974 24.0623 14.2974L18.067 11.8993C18.0651 11.8989 18.0612 11.898 18.0551 11.8971C18.0411 11.8948 18.0224 11.8932 18.0013 11.8932C17.9802 11.8932 17.9615 11.8948 17.9475 11.8971C17.9414 11.898 17.9375 11.8989 17.9356 11.8993ZM18.0013 10.8932C18.1305 10.8932 18.2888 10.9111 18.4337 10.969L24.4337 13.369C24.6623 13.4604 24.8408 13.6292 24.9601 13.8062C25.0792 13.983 25.168 14.2103 25.168 14.4532V16.6665C25.168 17.3093 24.6441 17.8332 24.0013 17.8332H12.0013C11.3585 17.8332 10.8346 17.3093 10.8346 16.6665V14.4532C10.8346 14.2103 10.9234 13.983 11.0425 13.8062C11.1618 13.6292 11.3403 13.4604 11.5689 13.369L17.5689 10.969C17.7138 10.9111 17.8721 10.8932 18.0013 10.8932Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0013 22.5C11.9108 22.5 11.8346 22.5761 11.8346 22.6667V24.1667H24.168V22.6667C24.168 22.5761 24.0918 22.5 24.0013 22.5H12.0013ZM10.8346 22.6667C10.8346 22.0238 11.3585 21.5 12.0013 21.5H24.0013C24.6441 21.5 25.168 22.0238 25.168 22.6667V24.6667C25.168 24.9428 24.9441 25.1667 24.668 25.1667H11.3346C11.0585 25.1667 10.8346 24.9428 10.8346 24.6667V22.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.668 16.8333C12.9441 16.8333 13.168 17.0572 13.168 17.3333V22C13.168 22.2761 12.9441 22.5 12.668 22.5C12.3918 22.5 12.168 22.2761 12.168 22V17.3333C12.168 17.0572 12.3918 16.8333 12.668 16.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3346 16.8333C15.6108 16.8333 15.8346 17.0572 15.8346 17.3333V22C15.8346 22.2761 15.6108 22.5 15.3346 22.5C15.0585 22.5 14.8346 22.2761 14.8346 22V17.3333C14.8346 17.0572 15.0585 16.8333 15.3346 16.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0013 16.8333C18.2774 16.8333 18.5013 17.0572 18.5013 17.3333V22C18.5013 22.2761 18.2774 22.5 18.0013 22.5C17.7252 22.5 17.5013 22.2761 17.5013 22V17.3333C17.5013 17.0572 17.7252 16.8333 18.0013 16.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.668 16.8333C20.9441 16.8333 21.168 17.0572 21.168 17.3333V22C21.168 22.2761 20.9441 22.5 20.668 22.5C20.3918 22.5 20.168 22.2761 20.168 22V17.3333C20.168 17.0572 20.3918 16.8333 20.668 16.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3346 16.8333C23.6108 16.8333 23.8346 17.0572 23.8346 17.3333V22C23.8346 22.2761 23.6108 22.5 23.3346 22.5C23.0585 22.5 22.8346 22.2761 22.8346 22V17.3333C22.8346 17.0572 23.0585 16.8333 23.3346 16.8333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.168 24.6667C10.168 24.3905 10.3918 24.1667 10.668 24.1667H25.3346C25.6108 24.1667 25.8346 24.3905 25.8346 24.6667C25.8346 24.9428 25.6108 25.1667 25.3346 25.1667H10.668C10.3918 25.1667 10.168 24.9428 10.168 24.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0013 14.1667C17.7252 14.1667 17.5013 14.3905 17.5013 14.6667C17.5013 14.9428 17.7252 15.1667 18.0013 15.1667C18.2774 15.1667 18.5013 14.9428 18.5013 14.6667C18.5013 14.3905 18.2774 14.1667 18.0013 14.1667ZM16.5013 14.6667C16.5013 13.8382 17.1729 13.1667 18.0013 13.1667C18.8297 13.1667 19.5013 13.8382 19.5013 14.6667C19.5013 15.4951 18.8297 16.1667 18.0013 16.1667C17.1729 16.1667 16.5013 15.4951 16.5013 14.6667Z"
      fill="currentColor"
    />
  </Icon>
)
