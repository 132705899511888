import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const EarthScienceIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M58.3674 69.6467H61.1724C61.1724 71.2179 60.4989 72.565 59.488 73.5747C58.4783 74.5844 57.0192 75.259 55.5601 75.259H52.7539C52.7539 73.6879 53.4274 72.3408 54.4372 71.33C55.448 70.3203 56.7951 69.6467 58.3674 69.6467Z"
      fill="transparent"
    />
    <path
      d="M52.7544 75.259H49.9494C48.3771 75.259 47.03 74.5844 46.0203 73.5747C45.0106 72.565 44.3359 71.1047 44.3359 69.6467H47.1421C48.7133 69.6467 50.0603 70.3191 51.0712 71.33C52.0809 72.3408 52.7544 73.6879 52.7544 75.259Z"
      fill="transparent"
    />
    <path
      d="M66.7857 35.0752L68.9183 38.3296L65.6639 41.5852L62.4083 39.3405L61.3975 35.0752L64.653 29.687L66.7857 35.0752Z"
      fill="transparent"
    />
    <path
      d="M75.3153 36.7592C75.5394 38.2172 75.7647 39.6774 75.7647 41.1366C75.7647 54.0445 65.2125 64.4835 52.4177 64.4835C48.4886 64.4835 44.6727 63.4727 41.4172 61.7895L44.4475 52.9216L47.4789 50.1165L42.0918 48.208L39.0604 44.8404L33.8975 43.156V38.6666L40.1833 32.0434L43.1015 31.7061L42.9884 28.9011L44.3354 27.4419L44.2233 24.861L46.8043 24.0743L47.9272 26.4322L53.7648 21.9428L52.6418 17.6775V17.5654C56.4577 17.6775 60.0495 18.6873 63.3039 20.3716L58.8145 23.0657L58.3662 26.4333L54.9986 26.6574L52.866 30.5854L55.6721 31.1469L51.4057 37.2086L56.4577 43.7186L61.9569 46.0754L61.2834 52.4744L64.763 55.5058L65.886 50.1187L71.0489 47.4235L70.8248 44.057L73.6298 40.6894C74.3056 39.6763 75.2032 36.8702 75.3153 36.7592Z"
      fill="transparent"
    />
    <path
      d="M28.96 41.1367C28.96 28.2276 39.5111 17.7886 52.3069 17.7886C52.4201 17.7886 52.6442 17.7886 52.7552 17.7886V17.9006L53.8781 22.1659L48.0417 26.6553L46.9187 24.2986L44.3367 25.0841L44.4499 27.6651L43.1028 29.1242L43.2138 31.9292L40.2956 32.2666L34.0097 38.8897V43.3791L39.1726 45.0635L42.2041 48.4311L47.5911 50.3396L44.5619 53.1447L41.5305 62.0126C33.8987 57.8605 28.96 50.0034 28.96 41.1367Z"
      fill="transparent"
    />
    <path
      d="M75.3163 36.7593C75.3163 36.8714 74.3066 39.6775 73.4078 40.8004L70.6028 44.1669L70.8269 47.5346L65.6639 50.2287L64.541 55.6158L61.0614 52.5854L61.7338 46.1875L56.2335 43.8308L51.1826 37.2076L55.4479 31.1459L52.6429 30.5844L54.7755 26.6565L58.3672 26.4323L58.8155 23.2888L63.3049 20.5947C69.4798 23.8503 73.9693 29.7988 75.3163 36.7593ZM68.9184 38.3294L66.7858 35.0738L64.652 29.6867L61.3964 35.0738L62.5194 39.3391L65.7749 41.4718L68.9184 38.3294Z"
      fill="transparent"
    />
    <path
      d="M52.3062 65.9418C48.2651 65.9418 44.2251 64.9321 40.6322 63.0236C32.551 58.6462 27.499 50.3398 27.499 41.1357C27.499 27.4411 38.6116 16.3296 52.3062 16.3296C52.4194 16.3296 52.6435 16.3296 52.7545 16.3296C56.6836 16.4417 60.6115 17.4525 63.9791 19.2478C67.2347 20.9322 70.0409 23.401 72.1735 26.3193C74.4182 29.3496 75.8774 32.8293 76.5509 36.421C76.8871 37.8801 76.9991 39.4513 76.9991 41.0225C77.1123 54.8303 65.9986 65.9418 52.3062 65.9418ZM52.3062 19.1357C40.1839 19.1357 30.3063 29.0134 30.3063 41.1368C30.3063 49.218 34.7957 56.7388 41.9803 60.5547C45.1227 62.2391 48.7145 63.1367 52.3073 63.1367C64.4297 63.1367 74.3073 53.2591 74.3073 41.1368C74.3073 39.7897 74.1941 38.3306 73.9699 36.9836C72.7361 30.4736 68.6949 24.8612 62.7453 21.7178C59.6029 20.0334 56.2353 19.1357 52.7556 19.1357C52.5304 19.1357 52.4183 19.1357 52.3062 19.1357Z"
      fill="currentColor"
    />
    <path
      d="M41.3064 63.1365C41.1932 63.1365 41.0811 63.1366 40.857 63.0234C40.0714 62.7992 39.7341 62.0136 39.9582 61.228L42.9885 52.3602C43.1017 52.136 43.2127 51.9119 43.3259 51.7987L44.6729 50.5648L41.4174 49.4419C41.1932 49.3287 40.9691 49.2178 40.8559 49.1046L38.1618 46.0743L33.3351 44.5031C32.7736 44.279 32.3242 43.8296 32.3242 43.1561V38.6666C32.3242 38.3293 32.4374 37.9931 32.6615 37.6558L38.9474 31.0337C39.1715 30.8096 39.5088 30.5844 39.8462 30.5844L41.4174 30.4734V29.0143C41.4174 28.677 41.5305 28.3419 41.7547 28.0046L42.6534 26.9948L42.5403 25.0863C42.5403 24.4128 42.8776 23.8514 43.55 23.6272L46.132 22.8416C46.8044 22.6175 47.4791 22.9537 47.8164 23.5151L48.1526 24.3007L51.9696 21.3825L51.0719 18.0149C50.8467 17.2293 51.2961 16.5558 52.0817 16.3316C52.8673 16.1075 53.5408 16.5558 53.766 17.3414L54.8878 21.6066C55.001 22.1681 54.7757 22.7295 54.3275 23.0657L48.4899 27.5563C48.1526 27.7804 47.7043 27.8925 47.367 27.7804C46.9187 27.6672 46.5814 27.4431 46.4682 26.9948L46.0199 25.984L45.5717 26.0972L45.6837 27.5563C45.6837 27.8925 45.5717 28.3419 45.3475 28.566L44.4488 29.5757V31.7084C44.4488 32.494 43.8873 33.0554 43.2138 33.1675L40.857 33.3916L35.3567 39.2292V42.1475L39.5099 43.4945C39.7341 43.6066 39.9582 43.7186 40.1846 43.9428L42.8787 47.0863L47.9296 48.8816C48.3778 48.9948 48.7151 49.443 48.8283 49.8913C48.9404 50.3407 48.7151 50.9022 48.3789 51.2384L45.5739 53.8204L42.6557 62.2389C42.4271 62.7992 41.8656 63.1365 41.3064 63.1365Z"
      fill="currentColor"
    />
    <path
      d="M64.6525 56.963C64.3163 56.963 63.9801 56.8509 63.7548 56.6268L60.2752 53.5954C59.9379 53.2581 59.7137 52.8098 59.8258 52.3604L60.3872 46.9733L55.8978 45.0648C55.6737 44.9516 55.4484 44.8407 55.3363 44.6165L50.2844 38.1066C49.9482 37.6572 49.9482 36.9836 50.2844 36.4222L53.3147 32.0448L52.5291 31.9317C52.0808 31.8196 51.7435 31.5954 51.5193 31.1461C51.2952 30.6978 51.2952 30.2484 51.5193 29.9122L53.652 25.9831C53.8761 25.5348 54.3266 25.3096 54.7749 25.3096L57.0196 25.1964L57.3558 23.1758C57.469 22.7276 57.6931 22.3902 58.0304 22.1661L62.5199 19.472C63.1934 19.0226 63.979 19.2468 64.4284 19.9203C64.8777 20.5938 64.6525 21.4926 64.0922 21.8288L60.1631 24.1867L59.8258 26.6567C59.7137 27.3291 59.1533 27.7796 58.4787 27.8905L55.7846 28.1157L54.999 29.5738L56.0088 29.7979C56.4582 29.9111 56.7943 30.2473 57.0196 30.6967C57.2437 31.145 57.1317 31.5954 56.9064 32.0437L53.2026 37.3198L57.3547 42.819L62.4067 44.9516C62.9681 45.1769 63.3043 45.7383 63.3043 46.4107L62.7429 52.1351L63.7537 53.0339L64.3163 50.0036C64.4284 49.5553 64.6525 49.218 64.9887 48.9939L69.3661 46.7492L69.2551 44.2792C69.2551 43.9419 69.3661 43.6057 69.5913 43.2695L72.3975 39.9019C72.9589 39.2295 73.7445 37.2078 73.9687 36.3101C74.1928 35.7487 74.6422 35.4125 75.3157 35.4125C76.1013 35.4125 76.7748 36.0849 76.7748 36.8705C76.7748 36.9836 76.7748 37.2078 76.6617 37.3198C76.5507 37.656 75.6519 40.4622 74.529 41.8092L72.0591 44.7275L72.1722 47.5336C72.1722 48.0951 71.9481 48.5434 71.3867 48.8807L66.8961 51.2375L65.9973 55.9521C65.8853 56.4004 65.5491 56.8509 65.0997 56.9619C64.8778 56.963 64.7646 56.963 64.6525 56.963Z"
      fill="currentColor"
    />
    <path
      d="M65.664 42.932C65.4399 42.932 65.1026 42.8188 64.8784 42.7078L61.6229 40.5752C61.2856 40.3499 61.1735 40.1258 61.0614 39.7896L59.9385 35.5232C59.8253 35.1859 59.9385 34.7376 60.0506 34.5135L63.3061 29.1264C63.5314 28.677 64.0929 28.4529 64.6532 28.4529C65.2147 28.4529 65.664 28.7902 65.8882 29.3505L68.0208 34.6267L70.1535 37.769C70.4908 38.3305 70.4908 39.1161 69.9293 39.5644L66.6738 42.8199C66.4496 42.8188 65.9991 42.932 65.664 42.932ZM63.6423 38.5546L65.4388 39.7896L67.01 38.2173L65.4388 35.9737C65.4388 35.8605 65.3256 35.8605 65.3256 35.7485L64.2027 32.9434L62.8556 35.3002L63.6423 38.5546Z"
      fill="currentColor"
    />
    <path
      d="M52.754 83.6776C51.9684 83.6776 51.2949 83.004 51.2949 82.2184V64.4838C51.2949 63.6982 51.9684 63.0247 52.754 63.0247C53.5396 63.0247 54.2132 63.6982 54.2132 64.4838V82.2173C54.2132 83.0029 53.5396 83.6776 52.754 83.6776Z"
      fill="currentColor"
    />
    <path
      d="M55.5602 76.7182H52.754C51.9684 76.7182 51.2949 76.0446 51.2949 75.259C51.2949 73.3505 52.0805 71.6673 53.3144 70.3203C54.6614 68.9732 56.4579 68.3008 58.2532 68.3008H61.0594C61.845 68.3008 62.5185 68.9732 62.5185 69.7588C62.5185 71.6673 61.7329 73.3506 60.499 74.6976C59.1531 75.9315 57.4687 76.7182 55.5602 76.7182ZM54.4373 73.912H55.5602C56.6831 73.912 57.6929 73.4637 58.5905 72.677C59.0388 72.2287 59.3761 71.6673 59.6003 71.1059H58.4774C57.3544 71.1059 56.3447 71.5552 55.447 72.3408C54.8867 72.7891 54.5505 73.2385 54.4373 73.912Z"
      fill="currentColor"
    />
    <path
      d="M52.7547 76.7182H49.9496C48.0411 76.7182 46.3578 75.9315 45.0108 74.6976C43.6637 73.3506 42.9902 71.5552 42.9902 69.7588C42.9902 68.9732 43.6638 68.3008 44.4494 68.3008H47.1435C49.052 68.3008 50.7352 69.0864 52.0823 70.3203C53.4293 71.6673 54.1017 73.4637 54.1017 75.259C54.2138 76.0446 53.5402 76.7182 52.7547 76.7182ZM46.0205 71.1059C46.2447 71.6673 46.582 72.2287 47.0303 72.677C47.817 73.4637 48.9388 73.912 50.0606 73.912H51.1835C50.9593 73.3506 50.622 72.7891 50.1737 72.3408C49.3882 71.5552 48.2653 71.1059 47.1435 71.1059H46.0205Z"
      fill="currentColor"
    />
    <path
      d="M62.6334 83.6774H42.9902C42.2046 83.6774 41.5322 83.0038 41.5322 82.2183C41.5322 81.4327 42.2057 80.7603 42.9902 80.7603H62.6334C63.419 80.7603 64.0925 81.4327 64.0925 82.2183C63.9804 83.0027 63.3069 83.6774 62.6334 83.6774Z"
      fill="currentColor"
    />
  </Icon>
)
