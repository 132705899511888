import {Avatar} from '@/components/common'
import {Props} from './types'
import {getImageURL} from '@/utils/get-image'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

export const VacancyCard = ({vacancy}: Props) => {
  const {t} = useTranslation()

  return (
    <div className="rounded-2xl min-h-[160px] min-w-[240px]  lg:w-[332px] p-6 border border-blue-4">
      <div className="w-full flex justify-start">
        <div>
          <Avatar
            className="text-blue-4 "
            avatarClassName="w-10 h-10"
            type="vacancy"
            src={getImageURL(vacancy.company?.logo)}
            fullName={vacancy.company?.title || '---'}
          />
        </div>
      </div>
      <h4 className="mt-4 mb-0 lg:mb-1 text-base lg:text-2xl font-bold leading-8.5 line-clamp-1">
        {vacancy.job_title}
      </h4>
      <div className="flex items-center gap-2 lg:gap-2.5 mb-4 lg:mb-6 text-blue-1 text-xs lg:text-sm leading-5">
        <p>{vacancy.country}</p>
        <p className="text-xs">&#9632;</p>
        <p>{vacancy.company?.title || '---'}</p>
      </div>
      <Link to={`/jobs/${vacancy._id}`} className="text-xs lg:text-sm leading-5 font-semibold border-b border-blue">
        {t('home.buttons.see_more')}
      </Link>
    </div>
  )
}
