import {useTranslation} from 'react-i18next'
import {ProgramCard} from './components'
import {PROGRAMS} from './constants/programs'

export const Programs = () => {
  const {t} = useTranslation()
  return (
    <section id="programs" className="bg-blue-5 py-8 md:py-20">
      <div className="px-4 md:container mx-auto">
        <h2 data-aos="fade-up" className="text-4xl md:text-6xl leading-9 md:leading-17 font-bold text-center">
          {t('home.explore.title')}
        </h2>
        <div
          data-aos="fade-up"
          className="grid place-items-stretch grid-cols-3 lg:grid-cols-5 gap-2.5 md:gap-6 mt-8 md:mt-14"
        >
          {PROGRAMS.map((program, index) => (
            <ProgramCard key={index} program={program} />
          ))}
        </div>
      </div>
    </section>
  )
}
