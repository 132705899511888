import {socket} from '@/services/socket'
import {createSlice} from '@reduxjs/toolkit'

const selectedUniversity = createSlice({
  name: 'selected-university',
  initialState: {
    selectedUniversity: '',
  },
  reducers: {
    selectUniversity: (state, action: any) => {
      state.selectedUniversity = action?.payload
      socket.disconnect()
      ;(socket.auth as any).university = action?.payload as any
      socket.connect()
    },
  },
})

export const {selectUniversity} = selectedUniversity.actions
export default selectedUniversity.reducer
