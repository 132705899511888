import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const EyeHidden2Icon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 28 24">
    <path
      d="M16.1855 19.3666L13.7642 16.9453C13.2129 17.142 12.6189 17.2491 12 17.2491C9.1005 17.2491 6.75 14.8986 6.75 11.9991C6.75 11.3801 6.85711 10.7862 7.05379 10.2348L3.96168 7.14271C1.40807 9.41761 0 11.9991 0 11.9991C0 11.9991 4.5 20.2491 12 20.2491C13.5231 20.2491 14.9224 19.9088 16.1855 19.3666Z"
      fill="currentColor"
    />
    <path
      d="M7.81451 4.63158C9.07758 4.08931 10.4769 3.74908 12 3.74908C19.5 3.74908 24 11.9991 24 11.9991C24 11.9991 22.5919 14.5805 20.0383 16.8554L16.9462 13.7633C17.1429 13.2119 17.25 12.618 17.25 11.9991C17.25 9.09958 14.8995 6.74908 12 6.74908C11.3811 6.74908 10.7872 6.85618 10.2358 7.05286L7.81451 4.63158Z"
      fill="currentColor"
    />
    <path
      d="M8.28727 11.4683C8.26271 11.6417 8.25 11.8189 8.25 11.9991C8.25 14.0701 9.92893 15.7491 12 15.7491C12.1802 15.7491 12.3574 15.7364 12.5308 15.7118L8.28727 11.4683Z"
      fill="currentColor"
    />
    <path
      d="M15.7127 12.5298L11.4693 8.28635C11.6426 8.26178 11.8198 8.24908 12 8.24908C14.0711 8.24908 15.75 9.92801 15.75 11.9991C15.75 12.1792 15.7373 12.3564 15.7127 12.5298Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4697 21.5294L2.46967 3.52941L3.53033 2.46875L21.5303 20.4688L20.4697 21.5294Z"
      fill="currentColor"
    />
  </Icon>
)
