import {Icon} from '@/components/common/icon'
import {IconProps} from '@/components/common/icon/types'

export const ImageIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path
      d="M4.9865 35C4.07358 35 3.3335 34.2585 3.3335 33.3443V6.65567C3.3335 5.74127 4.09235 5 4.9865 5H35.0138C35.9268 5 36.6668 5.74158 36.6668 6.65567V33.3443C36.6668 34.2587 35.908 35 35.0138 35H4.9865ZM33.3335 25V8.33333H6.66683V31.6667L23.3335 15L33.3335 25ZM33.3335 29.714L23.3335 19.714L11.3809 31.6667H33.3335V29.714ZM13.3335 18.3333C11.4925 18.3333 10.0002 16.841 10.0002 15C10.0002 13.159 11.4925 11.6667 13.3335 11.6667C15.1744 11.6667 16.6668 13.159 16.6668 15C16.6668 16.841 15.1744 18.3333 13.3335 18.3333Z"
      fill="currentColor"
    />
  </Icon>
)
