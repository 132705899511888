import {Button} from '@/components/common'
import {Props} from './types'
import CropperUI, {ReactCropperElement} from 'react-cropper'
import {useMemo, useRef} from 'react'
import 'cropperjs/dist/cropper.css'
import {useTranslation} from 'react-i18next'

export const Cropper = ({src, onCancel, onChange}: Props) => {
  const cropperRef = useRef<ReactCropperElement>(null)
  const {t} = useTranslation()

  const cropData = async () => {
    if (cropperRef.current?.cropper && onChange) {
      onChange(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
    }
  }

  const srcURL = useMemo(() => {
    if (typeof src === 'string') {
      return src
    } else {
      return URL.createObjectURL(src)
    }
  }, [src])

  return (
    <div className="w-full relative grid gap-x-2 grid-cols-3">
      <div className="col-span-2">
        <div>
          <CropperUI
            ref={cropperRef}
            rotateTo={0}
            style={{height: '100%', width: '100%'}}
            zoomTo={0}
            initialAspectRatio={1}
            preview=".img-preview"
            src={srcURL}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
            className="!object-contain"
          />
        </div>
      </div>
      <div>
        <Button onClick={onCancel} className="w-full">
          {t('common.buttons.cancel')}
        </Button>
        <Button onClick={cropData} className="w-full mt-2">
          OK
        </Button>
      </div>
    </div>
  )
}
