import {Icon} from '@/components/common'
import {IconProps} from '@/components/common/icon/types'

export const ImageCircleIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.502 7.5H7.50195C7.08774 7.5 6.75195 7.83579 6.75195 8.25V15L8.73679 13.2348C8.86319 13.1084 9.06123 13.0888 9.20997 13.188L11.2042 14.5175L13.9868 11.7348C14.1009 11.6207 14.2753 11.5924 14.4197 11.6646L17.252 13.125V8.25C17.252 7.83579 16.9162 7.5 16.502 7.5ZM7.50195 6.75C6.67353 6.75 6.00195 7.42157 6.00195 8.25V15.75C6.00195 16.5784 6.67353 17.25 7.50195 17.25H16.502C17.3304 17.25 18.002 16.5784 18.002 15.75V8.25C18.002 7.42157 17.3304 6.75 16.502 6.75H7.50195ZM10.502 10.125C10.502 10.7463 9.99827 11.25 9.37695 11.25C8.75563 11.25 8.25195 10.7463 8.25195 10.125C8.25195 9.50368 8.75563 9 9.37695 9C9.99827 9 10.502 9.50368 10.502 10.125Z"
      fill="currentColor"
    />
    <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
  </Icon>
)
