import {Button, Image} from '@/components/common'
import {Props} from './types'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export const ImageCard = ({src, title, id}: Props) => {
  const {t} = useTranslation()

  return (
    <div className="bg-white h-[300px] lg:h-[380px] overflow-hidden relative rounded-2xl w-full">
      <Image src={src} alt="Student Hunter" imageClassName="!w-full h-full" className="max-w-full w-full h-full " />
      <div className="absolute bottom-0 w-full z-50 card_Slider h-56 text-white flex flex-col justify-end items-center gap-x-4">
        <h2 className="text-sm text-center font-bold leading-5 md:leading-10 md:text-2xl px-2">{title}</h2>
        <Link to={`/universities/${id}`}>
          <Button
            variant="outline"
            className="bg-transparent text-white border my-4 hover:border-1 hover:bg-white hover:text-blue font-normal md:px-5 md:py-[13px] px-2 py-1.5 rounded-md lg:rounded-[12px] mb-6 text-xs leading-4 md:text-2xl md:leading-8.5"
          >
            {t('home.buttons.view_more')}
          </Button>
        </Link>
      </div>
    </div>
  )
}
