import {useEffect, useMemo, useState} from 'react'
import {ArrowLeft} from '@/icons'
import {cn} from '@/lib/utils'
import {useWindowSize} from '@uidotdev/usehooks'

const generateSlides = (slides: any[]) => {
  let finalSlides = slides

  if (slides.length === 1) {
    finalSlides = [slides[0], slides[0], slides[0], slides[0], slides[0]]
  } else if (slides.length === 2) {
    finalSlides = [slides[0], slides[1], slides[0], slides[1], slides[0]]
  } else if (slides.length === 3) {
    finalSlides = [slides[0], slides[1], slides[2], slides[0], slides[1]]
  } else if (slides.length === 4) {
    finalSlides = [slides[0], slides[1], slides[2], slides[3], slides[0], slides[1], slides[2], slides[3]]
  }

  finalSlides = finalSlides.map(slide => ({...slide, id: Math.ceil(Math.random() * 10000000)}))

  return {
    multipliedSlides: [
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
      ...finalSlides,
    ],
    slides: finalSlides,
  }
}

export const SliderV3 = ({cards: cardsData}: any) => {
  const cards = useMemo(() => [...(cardsData || [])] || [], [cardsData])
  const [slides, setSlides] = useState(generateSlides(cards) || {multipliedSlides: [], slides: []})
  const [curr, setCurr] = useState(Math.floor(slides.multipliedSlides?.length / 2) - 1)
  const [, setAnimatable] = useState(false)
  const size = useWindowSize()

  const activeDotIdx = useMemo(() => {
    const index = slides.slides?.findIndex(slide => slide.id === slides.multipliedSlides?.[curr]?.id)

    return index !== -1 ? index : 0
  }, [slides, curr])

  const onSlide = (newCurr: number) => {
    setAnimatable(true)

    setTimeout(() => {
      setAnimatable(false)
    }, 150)
    if (slides.multipliedSlides.length - 4 === newCurr) {
      setCurr(Math.floor(slides.multipliedSlides?.length / 2) - 1)
    } else if (4 === newCurr) {
      setCurr(Math.floor(slides.multipliedSlides?.length / 2) - 1)
    } else {
      setCurr(newCurr)
    }
  }

  useEffect(() => {
    if (cardsData) {
      setSlides(generateSlides([...(cardsData || [])] || []))
    }
  }, [cardsData])

  useEffect(() => {
    setCurr(Math.floor(slides.multipliedSlides?.length / 2) - 1)
  }, [slides])

  return (
    <div className="w-full">
      <div
        id="slider"
        className="flex items-center relative w-full"
        style={{
          transitionDuration: '300ms',
          transform: `translateX(${curr * ((size.width || 0) > 1200 ? -33.333 : -100)}%)`,
        }}
      >
        {slides.multipliedSlides?.map((slide: any, index: number) => (
          <div
            key={index}
            className={cn('slide px-5 ease-out', (size.width || 0) > 1200 ? 'min-w-[33.33333%]' : 'min-w-[100%]')}
            style={
              ((size.width || 0) > 1200 ? curr + 1 : curr) === index
                ? {transform: 'scale(1.1)', padding: (size.width || 0) > 1200 ? '0 25px' : '0 20px'}
                : ((size.width || 0) > 1200 ? curr : curr - 1) === index
                  ? {paddingRight: (size.width || 0) > 1200 ? '50px' : '20px'}
                  : ((size.width || 0) > 1200 ? curr + 2 : curr + 1) === index
                    ? {paddingLeft: (size.width || 0) > 1200 ? '50px' : '20px'}
                    : {transform: 'scale(0.9)'}
            }
          >
            {slide.card}
          </div>
        ))}
      </div>
      <div className="container flex items-center justify-between pt-[40px] md:pt-[60px]">
        <button
          type="button"
          aria-label="Previous slide"
          onClick={() => onSlide(curr - 1)}
          className="w-[60px] h-[60px] rounded-full border border-blue grid place-content-center hover:text-white hover:bg-blue duration-100"
        >
          <ArrowLeft className="w-7 h-[18px]" />
        </button>
        <div className="relative">
          <div
            className="relative hidden md:flex items-center gap-2 p-5 bg-white"
            style={{filter: 'blur(2px) contrast(4)'}}
          >
            <div
              className={cn(`absolute z-20 top-1/2 -translate-y-1/2 w-20 bg-[#102c7e] rounded-full h-5 `)}
              style={{left: `${activeDotIdx * 28 + 20}px`, transitionDuration: '400ms'}}
            />
            {slides.slides.map((slide, indexSlide) => (
              <button
                onClick={() =>
                  onSlide(
                    slides.multipliedSlides.findIndex(
                      (slideItem, index) =>
                        slideItem.id === slide.id &&
                        curr - Math.ceil(slides.slides.length / 2) < index &&
                        curr + Math.ceil(slides.slides.length / 2) > index,
                    ) as number,
                  )
                }
                key={slide.id}
                aria-label="Slide dot"
                type="button"
                style={{width: `${activeDotIdx === indexSlide ? '80' : '20'}px`, transitionDuration: '400ms'}}
                className={`relative z-10 h-5 rounded-full border-[3px] border-[#000026] bg-blue-6`}
              />
            ))}
          </div>
        </div>
        <button
          type="button"
          aria-label="Next slide"
          onClick={() => onSlide(curr + 1)}
          className="w-[60px] h-[60px] rounded-full border border-blue grid place-content-center hover:text-white hover:bg-blue duration-100"
        >
          <ArrowLeft className="rotate-180 w-7 h-[18px]" />
        </button>
      </div>
    </div>
  )
}
