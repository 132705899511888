import {Icon} from '@/components/common/icon/icon'
import {IconProps} from '@/components/common/icon/types'

export const ArchitectureIcon = (props: IconProps) => (
  <Icon {...props} viewBox="0 0 100 100">
    <path d="M100 0H0V100H100V0Z" fill="transparent" />
    <path
      d="M72.5018 66.2998V74.0998C72.5018 75.2998 71.5017 76.2998 70.3016 76.2998H65.9013C64.7012 76.2998 63.7012 75.2998 63.7012 74.0998V62.1998C63.7012 60.9998 64.7012 59.9998 65.9013 59.9998H70.3016C71.5017 59.9998 72.5018 60.9998 72.5018 62.1998V66.2998Z"
      fill="transparent"
    />
    <path d="M33.8003 37.4998V42.4998H30.0001H26.2998V37.4998H33.8003Z" fill="transparent" />
    <path d="M33.8003 34.9998V37.4998H26.2998V34.9998L30.0001 24.9998L33.8003 34.9998Z" fill="transparent" />
    <path
      d="M33.8007 71.2998V73.7998C33.8007 75.8998 32.1006 77.5998 30.0005 77.5998C29.0004 77.5998 28.0003 77.1998 27.3002 76.4998C26.6002 75.7998 26.2012 74.8998 26.2012 73.7998V42.4998H30.0005H33.8007V71.2998Z"
      fill="transparent"
    />
    <path
      d="M38.8002 59.9998V54.9998V49.9998V44.9998V39.9998V34.9998V29.9998V25.0998C38.8002 23.6998 39.9003 22.4998 41.4004 22.4998H56.3004C57.7005 22.4998 58.9006 23.5998 58.9006 25.0998V73.6998C58.9006 75.0998 57.8005 76.2998 56.3004 76.2998H41.3014C39.9013 76.2998 38.7012 75.1998 38.7012 73.6998V59.9998H38.8002ZM51.3001 68.7998C51.3001 67.3998 50.201 66.2998 48.8009 66.2998C47.4008 66.2998 46.3007 67.3998 46.3007 68.7998C46.3007 70.1998 47.4008 71.2998 48.8009 71.2998C50.201 71.2998 51.3001 70.0998 51.3001 68.7998Z"
      fill="transparent"
    />
    <path
      d="M56.2013 77.5001H41.3013C39.2011 77.5001 37.501 75.8 37.501 73.7001V25.1C37.501 23 39.2011 21.3 41.3013 21.3H56.2013C58.3014 21.3 60.0015 23 60.0015 25.1V73.7001C60.0015 75.8 58.3014 77.5001 56.2013 77.5001ZM41.3013 23.8C40.6012 23.8 40.0012 24.4 40.0012 25.1V73.7001C40.0012 74.4 40.6012 75.0001 41.3013 75.0001H56.2013C56.9014 75.0001 57.5004 74.4 57.5004 73.7001V25.1C57.5004 24.4 56.9014 23.8 56.2013 23.8H41.3013Z"
      fill="currentColor"
    />
    <path
      d="M48.8008 31.3H38.8001C38.1 31.3 37.501 30.7 37.501 30C37.501 29.3 38.1 28.7 38.8001 28.7H48.8008C49.5008 28.7 50.0999 29.3 50.0999 30C50.0009 30.7 49.3998 31.3 48.8008 31.3Z"
      fill="currentColor"
    />
    <path
      d="M45.0009 36.3H38.7015C38.0014 36.3 37.4004 35.7 37.4004 35C37.4004 34.3 38.0004 33.7 38.7015 33.7H45.0009C45.701 33.7 46.3 34.3 46.3 35C46.3 35.7 45.701 36.3 45.0009 36.3Z"
      fill="currentColor"
    />
    <path
      d="M45.0009 46.2997H38.7015C38.0014 46.2997 37.4004 45.6997 37.4004 44.9997C37.4004 44.2997 38.0004 43.6997 38.7015 43.6997H45.0009C45.701 43.6997 46.3 44.2997 46.3 44.9997C46.3 45.6997 45.701 46.2997 45.0009 46.2997Z"
      fill="currentColor"
    />
    <path
      d="M45.0009 56.3H38.7015C38.0014 56.3 37.4004 55.7 37.4004 55C37.4004 54.3 38.0004 53.7 38.7015 53.7H45.0009C45.701 53.7 46.3 54.3 46.3 55C46.301 55.7 45.701 56.3 45.0009 56.3Z"
      fill="currentColor"
    />
    <path
      d="M48.8008 41.2997H38.8001C38.1 41.2997 37.501 40.6997 37.501 39.9997C37.501 39.2997 38.1 38.6997 38.8001 38.6997H48.8008C49.5008 38.6997 50.0999 39.2997 50.0999 39.9997C50.0009 40.6997 49.3998 41.2997 48.8008 41.2997Z"
      fill="currentColor"
    />
    <path
      d="M48.8008 51.2997H38.8001C38.1 51.2997 37.501 50.6997 37.501 49.9997C37.501 49.2997 38.1 48.6997 38.8001 48.6997H48.8008C49.5008 48.6997 50.0999 49.2997 50.0999 49.9997C50.0009 50.6997 49.3998 51.2997 48.8008 51.2997Z"
      fill="currentColor"
    />
    <path
      d="M48.8008 61.3H38.8001C38.1 61.3 37.501 60.7 37.501 60C37.501 59.3 38.1 58.7 38.8001 58.7H48.8008C49.5008 58.7 50.0999 59.3 50.0999 60C50.0999 60.7 49.3998 61.3 48.8008 61.3Z"
      fill="currentColor"
    />
    <path
      d="M48.8013 72.4999C46.7011 72.4999 45.001 70.7999 45.001 68.6999C45.001 66.5999 46.7011 64.8999 48.8013 64.8999C50.9014 64.8999 52.6015 66.5999 52.6015 68.6999C52.6015 70.7999 50.8014 72.4999 48.8013 72.4999ZM48.8013 67.4999C48.1012 67.4999 47.5011 68.0999 47.5011 68.7999C47.5011 69.4999 48.1012 70.0999 48.8013 70.0999C49.5013 70.0999 50.1003 69.4999 50.1003 68.7999C50.0013 68.0999 49.4003 67.4999 48.8013 67.4999Z"
      fill="currentColor"
    />
    <path
      d="M70.3005 77.4998H65.9002C64.0001 77.4998 62.5 75.9998 62.5 74.0998V62.1998C62.5 60.2998 64.0001 58.7998 65.9002 58.7998H70.3005C72.2007 58.7998 73.7008 60.2998 73.7008 62.1998V74.0998C73.7998 75.9998 72.2007 77.4998 70.3005 77.4998ZM65.9002 61.2998C65.4002 61.2998 65.0002 61.6998 65.0002 62.1998V74.0998C65.0002 74.5998 65.4002 74.9998 65.9002 74.9998H70.3005C70.8006 74.9998 71.1996 74.5998 71.1996 74.0998V62.1998C71.1996 61.6998 70.8006 61.2998 70.3005 61.2998H65.9002Z"
      fill="currentColor"
    />
    <path
      d="M30.0003 78.8C28.7012 78.8 27.4012 78.3 26.5011 77.3C25.601 76.4 25.001 75.1 25.001 73.8V37.5C25.001 36.8 25.6 36.2 26.3001 36.2C27.0001 36.2 27.5992 36.8 27.5992 37.5V73.8C27.5992 74.5 27.9002 75.1 28.2992 75.6C28.7993 76.1 29.3993 76.3 30.0994 76.3C31.4995 76.3 32.5985 75.2 32.5985 73.8V37.5C32.5985 36.8 33.1996 36.2 33.8996 36.2C34.5997 36.2 35.1987 36.8 35.1987 37.5V73.8C34.9997 76.5 32.8005 78.8 30.0003 78.8Z"
      fill="currentColor"
    />
    <path
      d="M33.8006 38.7998H26.301C25.601 38.7998 25.002 38.1998 25.002 37.4998V34.9998C25.002 34.8998 25.0019 34.6998 25.1019 34.5998L28.9022 24.5998C29.1022 24.0998 29.5013 23.7998 30.1023 23.7998C30.7014 23.7998 31.1024 24.0998 31.3024 24.5998L35.1026 34.5998C35.0016 34.6998 35.0017 34.8998 35.0017 34.9998V37.4998C34.9997 38.1998 34.4006 38.7998 33.8006 38.7998ZM27.5002 36.2998H32.5005V35.2998L30.0003 28.5998L27.5011 35.2998L27.5002 36.2998Z"
      fill="currentColor"
    />
    <path
      d="M33.8006 43.7997H26.301C25.601 43.7997 25.002 43.1997 25.002 42.4997C25.002 41.7997 25.601 41.1997 26.301 41.1997H33.8006C34.5006 41.1997 35.1016 41.7997 35.1016 42.4997C34.9997 43.1997 34.4006 43.7997 33.8006 43.7997Z"
      fill="currentColor"
    />
    <path
      d="M72.5006 67.4999H67.5003C66.8002 67.4999 66.2012 66.8999 66.2012 66.1999C66.2012 65.4999 66.8002 64.8999 67.5003 64.8999H72.5006C73.2007 64.8999 73.7997 65.4999 73.7997 66.1999C73.7997 66.8999 73.2007 67.4999 72.5006 67.4999Z"
      fill="currentColor"
    />
    <path
      d="M33.8005 72.4997H30.0003C29.3002 72.4997 28.7012 71.8997 28.7012 71.1997C28.7012 70.4997 29.3002 69.8997 30.0003 69.8997H33.8005C34.5006 69.8997 35.1016 70.4997 35.1016 71.1997C34.9996 71.8997 34.4006 72.4997 33.8005 72.4997Z"
      fill="currentColor"
    />
    <path
      d="M30.0003 64.9997C29.3002 64.9997 28.7012 64.3997 28.7012 63.6997V42.4997C28.7012 41.7997 29.3002 41.1997 30.0003 41.1997C30.7003 41.1997 31.3004 41.7997 31.3004 42.4997V63.7997C31.3004 64.3997 30.7003 64.9997 30.0003 64.9997Z"
      fill="currentColor"
    />
  </Icon>
)
